export default {
  '2FA_30_days_valid': 'Require 2FA once every 30 days on trusted devices.',
  '2FA_every_login': 'Require 2FA on every login.',
  '2FA_is_required_every_30_days': '2FA is required once every 30 days on trusted devices.',
  '2FA_is_required_every_login': '2FA is required every time a user logs in.',
  '30_days_valid': '30 days valid',
  'color_light-grey': 'Light grey',
  'delete_scale_sublabel_interview-guide': 'This level will no longer appear in interview templates.',
  'scale_disabled_interview-guide': 'This scale has been turned off and will no longer be included in interview templates.',
  'turn_off_scale_sublabel_interview-guide': 'The scale will no longer appear in any interview templates.',
  a_review_for_name: 'A review for {name}',
  a: 'A',
  accept_changes_continue_tracking: 'Accept changes and continue tracking',
  accept_changes_description: "This will accept all changes tracked so far. You can't undo this action.",
  accept_changes_stop_tracking: 'Accept changes and stop tracking',
  access_removed: 'Access removed',
  access_summary: 'Access summary',
  accesses: 'Access',
  account_defaults_sub: 'These defaults will be applied to the entire account.',
  account_settings: 'Account settings',
  account: 'Account',
  accounts: 'Account | Accounts',
  acknowledge_success_message: 'Assessment acknowledged successfully.',
  acknowledge: 'Acknowledge',
  acknowledged: 'Acknowledged',
  actions: 'Action | Actions',
  activate_users: 'Activate',
  activate: 'Activate',
  activated: 'Activated',
  active_candidates_only: 'Active candidates only',
  active_now: 'Active now',
  active_status: 'Active status',
  active_statuses: 'Active statuses',
  active: 'Active',
  actual_review: 'Actual review',
  add_a_comment: 'Add a comment',
  add_additional_information_group: 'Create group',
  add_additional_information: "Create @.lower:{'singular_additional_information'}",
  add_additional_position: 'Add additional position',
  add_all: 'Add all',
  add_candidate_statuses: 'Add candidate status',
  add_candidates_description: 'Select or add candidates to this interview round.',
  add_candidates: 'Add candidates',
  add_career_stream_competencies: "Add career stream @.lower:{'plural_competencies'}",
  add_certification_group: 'Create group',
  add_certifications: "Create @.lower:{'singular_certifications'}",
  add_comment: 'Add comment',
  add_comments: 'Add comments',
  add_competencies: 'Add competencies',
  add_core_competencies: "Add core @.lower:{'plural_competencies'}",
  add_department: 'Add department',
  add_details: 'Add details',
  add_direct_reports: 'Add direct reports',
  add_due_date: 'Add due date',
  add_edit_details: 'Add / Edit details',
  add_education_group: 'Create group',
  add_educations: "Create @.lower:{'singular_educations'}",
  add_employees: 'Add employees',
  add_experiences: "Create @.lower:{'singular_experiences'}",
  add_feedback: 'Add feedback',
  add_filters: 'Add filters',
  add_flags: 'Add flag',
  add_incumbent: 'Add incumbent',
  add_interview_rounds: 'Add interview round',
  add_interviewers: 'Add interviewer',
  add_item_to_library: 'Add {number} {library_type}',
  add_job_family_competencies: "Add job-family @.lower:{'plural_competencies'}",
  add_job_group_owners: 'Add job group owners',
  add_job_level_name_competencies: "Add {name} @.lower:{'plural_competencies'}",
  add_job_title_placeholder: 'Enter a job title (e.g. Sales Associate)',
  add_job: 'Add job',
  add_jobs: 'Add job',
  add_languages: "Create @.lower:{'singular_languages'}",
  add_level_after: 'Add level after',
  add_level_before: 'Add level before',
  add_level: 'Add level',
  add_levels: 'Add level | Add levels',
  add_library_content_to_survey: 'Add library content to your survey.',
  add_links: 'Add link',
  add_location_details: 'Add location details',
  add_location_name: 'Add location name',
  add_location: 'Add location',
  add_manager: 'Assign manager',
  add_members: 'Add member',
  add_min_max_item_to_library: 'Add {min} to {max} {library_type}',
  add_multiple_line: 'Add multiple items on separate lines.',
  add_multiple_question_line: 'Add multiple questions on separate lines.',
  add_new_candidates: 'Add new candidate | Add new candidates',
  add_notes: 'Add notes',
  add_options: 'Add options',
  add_past_positions: 'Add past positions',
  add_people_to_get_input: 'Add people to get their input.',
  add_position_id: 'Add position ID',
  add_position: 'Add position',
  add_ques_to_get_started: 'Add questions to get started',
  add_questions_from_interview_templates: 'Add questions from interview template',
  add_questions: 'Add questions',
  add_ranges: 'Add range',
  add_reply: 'Add reply',
  add_responsibility_group: 'Create group',
  add_responsibility: "Create @.lower:{'singular_responsibilities'}",
  add_skill_group: 'Create group',
  add_skill: "Create @.lower:{'singular_skills'}",
  add_stars: 'Add star',
  add_subheadings: 'Add subheading',
  add_subjects: 'Add subjects',
  add_text: 'Add text',
  add_to_guide: 'Add to template',
  add_to_library: 'Add to library',
  add_to_site: 'Add to site',
  add_types: 'Add type',
  add_users: 'Add user | Add users',
  add_working_conditions: "Create @.lower:{'singular_working_conditions'}",
  add: 'Add',
  added_guide: 'Added guide',
  added_interview_guide_question: 'Added interview template question',
  added_owners: 'Added owners',
  added_reactions: 'Added reaction',
  added_survey_participant: 'Added survey participant',
  added_tags: 'Added tag',
  added_to_guides: 'Added to template',
  added_to_site: 'Added to site',
  added: 'Added',
  additional_department: 'Additional department',
  additional_feedback_unnecessary: 'You can no longer provide feedback. It has been indicated that additional feedback is not necessary.',
  additional_info: 'Additional information',
  additional_information_archive: "Archive: @:{'plural_additional_information'}",
  additional_information_archived: 'Item archived | Items archived',
  additional_information_created: 'Item created | Items created',
  additional_information_deleted: 'Item deleted | Items deleted',
  additional_information_groups: "Groups: @.capitalize:{'plural_additional_information'}",
  additional_information_moved: 'Item moved | Items moved',
  additional_information_published: 'Item published | Items published',
  additional_information_restored: 'Item restored | Items restored',
  additional_information_updated: "@.capitalize:{'singular_additional_information'} updated | @.capitalize:{'plural_additional_information'} updated",
  additional_information: "@:{'singular_additional_information'} | @:{'plural_additional_information'}",
  additional_position_text: 'Useful for employees assigned to multiple positions.\n Choose this option to associate the employee to an additional position.',
  additional_position: 'Additional position',
  additional_review_requested: 'Additional review requested',
  admin_content_defaults_interview_questions_description: 'Include interview questions when importing competencies from the warehouse.',
  admin_home: 'Admin home',
  admin_option_description: 'Includes permissions for full Quinto management and administrative functionality.',
  admin: 'Admin',
  administration: 'Administration',
  administrator: 'Administrator',
  advance_to_hire: 'Advance to hire',
  advanced_options: 'Advanced options',
  advanced_search: 'Content finder',
  ai_description: 'Define AI settings for jobs.',
  ai_server_error: 'Sorry! Something went wrong. Please try again later.',
  ai_suggestions_warning: 'AI suggestions can be inaccurate or misleading.',
  ai: 'AI',
  all_columns_must_have_headers: 'All columns must have headers',
  all_competencies: 'All competencies',
  all_completed_signoffs: 'All completed signoffs',
  all_content: 'All content',
  all_names_required: 'Please enter the name in all available languages',
  all_questions: 'All questions',
  all_responses: 'All responses',
  all_scorecards_submitted: 'All scorecards submitted',
  all_templates: 'All templates',
  all: 'All',
  alphabetical: 'Alphabetical',
  analytics: 'Analytics',
  analyzing_your_data: 'Analyzing your data',
  and: 'And',
  anonymize_candidate_dialog_msg: "You can't undo this action. We'll keep all of this candidate's ratings, but their name, email address, and personal links will no longer appear.",
  anonymize_candidate: 'Anonymize candidate',
  anonymize_msg: "You can't undo this action. We'll keep all of this user's contributions to surveys and activities belonging to other users, but their name will no longer appear anywhere.",
  anonymize_results: 'Anonymize results',
  anonymize_user: 'Anonymize user',
  anonymize_users_msg: 'When a user is deleted, their contribution to your site, for example in survey ratings - remains. You can anonymize a user to permanently remove all their personally identifiable information from our interface and database.',
  anonymize_users: 'Manage deleted users',
  anonymize: 'Anonymize',
  anonymized_results: 'Anonymized results',
  anonymized: 'Anonymized',
  anytime: 'Anytime',
  api_key_copymodal_message: 'Attention: This is your API token. Please make sure to save this token securely.',
  api_keys: 'API key | API keys',
  applications: 'Applications',
  apply_updates: 'Apply updates',
  apr: 'Apr',
  architecture_display_order: 'Architecture display order',
  architecture_layers: 'Architecture layers',
  architecture_options: 'Architecture options',
  architecture_updated: 'Architecture updated',
  architecture: 'Architecture',
  archive_additional_information_msg: 'This item will be archived. | These items will be archived.',
  archive_additional_information: "Archive @.lower:{'additional_information'}?",
  archive_certifications_confirmation: 'This item will be archived. | These items will be archived.',
  archive_certifications_msg: 'This item will be archived. | These items will be archived.',
  archive_certifications: "Archive @.lower:{'certifications'}?",
  archive_competencies_confirmation: 'This item will be archived. | These items will be archived.',
  archive_competencies_msg: 'This item will be archived. | These items will be archived.',
  archive_competencies: "Archive @.lower:{'competencies'}?",
  archive_education_confirmation: 'This item will be archived. | These items will be archived.',
  archive_educations_msg: 'This item will be archived. | These items will be archived.',
  archive_educations: "Archive @.lower:{'educations'}?",
  archive_experiences_msg: 'This item will be archived. | These items will be archived.',
  archive_experiences: "Archive @.lower:{'experiences'}?",
  archive_jobs_message: 'This job will be archived. | These jobs will be archived.',
  archive_jobs: 'Archive job | Archive jobs',
  archive_languages_msg: 'This item will be archived and removed from {count_published_jobs}. | These items will be archived and removed from {count_published_jobs}.',
  archive_languages: "Archive @.lower:{'languages'}?",
  archive_responsibilities_confirmation: 'This item will be archived. | These items will be archived.',
  archive_responsibilities: "Archive @.lower:{'responsibilities'}?",
  archive_responsibility_msg: 'This item will be archived. | These items will be archived.',
  archive_responsibility: "Archive @.lower:{'responsibilities'}?",
  archive_skill_confirmation: 'This item will be archived and removed from {count_published_jobs}. | These items will be archived and removed from {count_published_jobs}.',
  archive_skill_msg: 'This item will be archived and removed from {count_published_jobs}. | These items will be archived and removed from {count_published_jobs}.',
  archive_skill: "Archive @.lower:{'skills'}?",
  archive_working_conditions_msg: 'This item will be archived. | These items will be archived.',
  archive_working_conditions: "Archive @.lower:{'working_conditions'}?",
  archive: 'Archive',
  archived: 'Archived',
  are_you_still_there: 'Are you still there?',
  ask_ai: 'Ask AI',
  assessment_has_no_questions_tooltip: "{subjectName}'s assessment doesn't have any questions.",
  assessment_not_based_on_job_text: 'A selection of competencies and skills',
  assessment_questionnaire_reopen_msg: 'The assessment questionnaire has been reopened.',
  assessment_result: 'Assessment results',
  assessments_completed: 'Assessments completed',
  assessments_manage_alerts_text: 'Alerts are here...',
  assessments: 'Assessments',
  assign_department: 'Assign department',
  assign_levels: 'Assign level',
  assign_manager: 'Assign manager',
  assign_permission_sets: 'Assign permission sets',
  assign_position_children_new_parent: 'What would you like to do with direct reports?',
  assign_property: 'Assign {property}',
  assign_this_question_to: 'Assign this question to:',
  assign_types: 'Assign types',
  assign: 'Assign',
  assigned_licenses: 'Assigned license | Assigned licenses',
  assigned_manager: 'Assigned manager',
  assigned_permission_sets: 'Permission set assigned | Permission sets assigned',
  assigned: 'Assigned',
  associated_job_post_template_description: 'The associated template used to define the order and content of job posts.',
  associated_job_post_templates: 'Associated job post template',
  associated_post_template_tooltip: 'The associated template used to define the order and content of job posts.',
  associated_post_template: 'Associated job post template',
  at_least_8_characters_long: 'At least 8 characters long',
  at_or_above: 'at or above',
  ats_status_code: 'ATS status code',
  attributed_results: 'Attributed results',
  audit_logs_additional_information_groups: "@.capitalize:{'singular_additional_information'} groups",
  audit_logs_certification_groups: "@.capitalize:{'singular_certifications'} groups",
  audit_logs_competency_groups: "@.capitalize:{'singular_competencies'} groups",
  audit_logs_education_groups: "@.capitalize:{'singular_educations'} groups",
  audit_logs_general: 'Audit logs - General',
  audit_logs_interviews: 'Audit logs - Interviews',
  audit_logs_jobs: 'Audit logs - Jobs',
  audit_logs_responsibility_groups: "@.capitalize:{'singular_responsibilities'} groups",
  audit_logs_signoffs: 'Audit logs - Signoffs',
  audit_logs_skill_groups: "@.capitalize:{'singular_skills'} groups",
  audit_logs: 'Audit log | Audit logs',
  aug: 'Aug',
  authorize: 'Authorize',
  auto_reminders: 'Auto reminders',
  automated_reminders: 'Automated reminders',
  available_languages: 'Available languages',
  average_job_age_tooltip: 'The average number of days since each job was last published.',
  average_job_age: 'Avg. job age',
  average_job_consistency_by_month_tooltip: 'The monthly average consistency score (0 to 100) for published jobs.',
  average_job_consistency_by_month: 'Avg. job consistency',
  average_job_consistency_tooltip: 'The current average consistency score (0 to 100) for published jobs.',
  average_job_consistency: 'Avg. job consistency',
  average_of_scores: 'Average of scores submitted',
  average: 'Average',
  avg_score: 'Avg score',
  b: 'B',
  back_to_login: 'Back to login',
  back: 'Back',
  behavioral_indicators: 'Behavioral indicator',
  below_requirements: 'Below requirements',
  below: 'below',
  between_xy: 'between {x} and {y}',
  bulk_cancel_assessments_dialog_message: "You can't undo this action. Cancelling assessments will delete any submitted data. Affected participants will be notified.",
  bulk_sharing_results_dialog_message: 'Share results with subjects in the the selected assessments.',
  c: 'C',
  cancel_assessment_dialog_message: "You can't undo this action. Cancelling this assessment will delete any submitted data. Affected participants will be notified.",
  cancel_assessment: 'Cancel assessment',
  cancel_assessments: 'Cancel assessment | Cancel assessments',
  cancel_interviews_confirmation: "We'll let the interviewers know this interview has been cancelled.",
  cancel_interviews: 'Cancel interview',
  cancel_requests: 'Cancel request',
  cancel_signoff_message: 'A signoff cancellation will be sent to {name}. | A signoff cancellation will be sent to selected employees.',
  cancel_signoff_title: 'Cancel signoff?',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  candidacies: 'Candidacy',
  candidacy_comments: 'Candidacy comment',
  candidate_email_taken: 'The email address you entered is already in use.',
  candidate_info: 'Candidate info',
  candidate_moved: 'Candidate moved!',
  candidate_statuses_description: 'Define candidate statuses. Note: changing status names will update all candidates in all openings.',
  candidate_statuses: 'Candidate statuses',
  candidates: 'Candidate | Candidates',
  capability_comparison: 'Capability comparison',
  capability_match: 'Capability match',
  capability: 'Capability',
  career_stream_competencies_description: "These are added to jobs in each job group. To add career stream @.lower:{'plural_competencies'}, go to {jobGroups}.",
  career_stream_competencies: "Career stream @.lower:{'plural_competencies'}",
  career_stream: 'Career stream',
  category: 'Category',
  certification_groups: "Groups: @.capitalize:{'plural_certifications'}",
  certification_levels: 'Certification level',
  certifications_archive: "Archive: @:{'plural_certifications'}",
  certifications_archived: 'Item archived | Items archived',
  certifications_created: 'Item created | Items created',
  certifications_deleted: 'Item deleted | Items deleted',
  certifications_edited: 'Item edited | Items edited',
  certifications_groups: "Groups: @.capitalize:{'plural_certifications'}",
  certifications_guideline_description: 'Certifications are designated credentials issued by third-party, standard-setting organizations that verify an individual has met certain criteria related to a skill, competency, or job.<p class="mt-2"> There should be no more than 5 certifications in total.</p>',
  certifications_moved: 'Item moved | Items moved',
  certifications_published: 'Item published | Items published',
  certifications_restored: 'Item restored | Items restored',
  certifications_updated: "@.capitalize:{'singular_certifications'} updated | @.capitalize:{'plural_certifications'} updated",
  certifications: "@:{'singular_certifications'} | @:{'plural_certifications'}",
  change_color: 'Change color',
  change_department: 'Change department',
  change_filters: 'Try changing your filters',
  change_incumbent: 'Change incumbent',
  change_job: 'Change job',
  change_log: 'Change log',
  change_manager: 'Change manager',
  change_position_text: 'Useful for position changes that affect reporting relationships.\n Choose this option to move the employee to a position that already exists.',
  change_position: 'Change position',
  change_role: 'Change role',
  change_security_level: 'Settings',
  change_status: 'Change status',
  change_x_job_in_review: 'Change {name} job in this review.',
  change_x_manager_in_review: 'Change {name} manager in this review.',
  change: 'Change',
  changed_role: 'Changed role',
  changes_are_not_retroactive: 'Changes are not retroactive - they only affect jobs published in the future. These are defaults that you can modify on individual jobs.',
  changes_not_tracked: 'Changes are not being tracked.',
  chart: 'Chart',
  check_box_update_jobs: "Checking this box will flag updated jobs in content activities so that you can notify incumbent when you're ready.",
  check_for_updates: 'Check for updates',
  check_setup_modal_text: "We're getting things ready for you. This could take a while. We'll email you when it's done or you can watch progress here.",
  check_setup_modal_title: 'Just a moment...',
  check_setup: 'Check setup',
  check_the_setup: 'check the setup',
  check_updates_job_changes_were_found_alert_text_part_1: "We’ve changed {name}'s job",
  check_updates_job_changes_were_found_alert_text_part_2: 'in this review to {newJob}.',
  check_updates_job_changes_were_found_live_review_alert_text_1: 'Any submitted data will be permanently deleted.',
  check_updates_job_changes_were_found_live_review_alert_text_2: 'All participants assessing {subjectName} will receive a new invitation and task.',
  check_updates_job_changes_were_found_live_review_alert_text: "If you change {name}'s job in this review to {newJob}",
  check_updates_manager_changes_were_found_alert_text_part_1: "We’ve changed {name}'s manager",
  check_updates_manager_changes_were_found_alert_text_part_2: 'in this review to {newManager}.',
  check_updates_manager_changes_were_found_live_review_alert_text_1: 'Any data submitted by a manager that is removed from the review will be permanently deleted.',
  check_updates_manager_changes_were_found_live_review_alert_text_2: 'A new manager will receive an invitation and task.',
  check_updates_manager_were_found_live_review_alert_text: "If you change {name}'s manager in this review to {newManager}",
  check_updates_x_job_not_update: "{name}'s job has not been updated.",
  check_updates_x_job_updated_to_new_job: "{name}'s job has been updated to {newJob}.",
  check_updates_x_manager_not_update: "{name}'s manager has not been updated.",
  check_updates_x_manager_updated_to_new_manager: "{name}'s manager has been updated to {newManager}.",
  check_your_email: 'Check your email',
  choose_content_for_job_post_template: 'Choose content to display in job post',
  choose_security_level: 'Choose security level',
  clear: 'Clear',
  click_edit_click_hold_move: 'Click to edit. Click and hold to move.',
  click_here_to_add_one: 'Click here to add one.',
  click_here: 'Click here',
  click_to_add: 'Click to add',
  click_to_hide_asterisk_guide_unused_ques: 'An asterisk is displaying beside unused questions for this job. Click to hide them.',
  click_to_upload: 'Click to upload',
  close_feedback_message: 'Editors and reviewers will have read-only access.',
  close_feedback: 'Close feedback',
  close_job_input_description: 'Restricts editors and reviewers to read-only access to this job.',
  close_job_input_title: 'Close to editors and reviewers',
  close_opening_confirmation: 'You can reopen it later if you change your mind. Any scheduled interviews will be cancelled.',
  close_opening: 'Close this opening',
  close_reviews: 'Close review',
  close_survey_confirmation_message: "Participants will no longer be able to access the survey. We'll email anyone who hasn't yet provided input to let them know.",
  close_survey: 'Close survey',
  close: 'Close',
  closed_survey_message: 'This survey has been closed. {link}',
  closed: 'Closed',
  collaboration_description: 'Invite people to provide input on this job.',
  collaboration: 'Collaborators',
  collaborators: 'Collaborator | Collaborators',
  color_blue: 'Blue',
  color_brown: 'Brown',
  color_cyan: 'Cyan',
  color_green: 'Green',
  color_grey: 'Grey',
  color_magenta: 'Magenta',
  color_orange: 'Orange',
  color_pink: 'Pink',
  color_purple: 'Purple',
  color_red: 'Red',
  color_yellow: 'Yellow',
  colors: 'Color | Colors',
  commented: 'Commented',
  comments_are_x: 'Comments are {x}',
  comments: 'Comment | Comments',
  communicated_deadlines: 'Communicated deadline',
  communication_conclusion_description: 'This section appears at the end of the template (after the interview questions).',
  communication_introduction_description: 'The section appears at the start of the template (before the interview questions).',
  communications_desc: 'Customize email and introduction content.',
  communications_description: 'This survey has default communications.',
  communications_type_job_collaboration_invitation: 'Define the content for job collaboration invitations.',
  communications_type_job_collaboration_thankyou: 'Define the content for job collaboration thank-you emails.',
  communications_type_new_user_invitation: 'Define the content for new user invitations.',
  communications_type_review_invitation_manager: 'Define the default content for email invitations to review managers. You can customize the messaging in each review.',
  communications_type_review_invitation_subjects: 'Define the default content for email invitations sent to review subjects. You can customize the messaging in each review.',
  communications_type_review_onscreen_instruction_manager: 'Define the default introductory content that managers will see in a review. You can customize the messaging in each review.',
  communications_type_review_onscreen_instruction_subjects: 'Define the default introductory content that subjects will see in a review. You can customize the messaging in each review.',
  communications_type_review_sharing_acknowledgement_subjects: "Define the default content for emails sent to employees asking for confirmation that they've had a review discussion with their manager. You can customize the messaging in each review.",
  communications_type_review_sharing_invitation_manager: 'Define the default content for emails sent to managers to schedule review discussions. You can customize the messaging in each review.',
  communications_type_survey_introduction: 'Define the default content for survey welcome pages. You can add survey-specific messaging in each survey.',
  communications_type_survey_invitation: 'Define the default content for survey invitations. You can add survey-specific messaging in each survey.',
  communications_updated: 'Communications updated',
  communications: 'Communications',
  companies: 'Company',
  company_name: 'Company name',
  compare_job_versions: 'Compare job versions',
  compare_jobs: 'Compare jobs',
  compare_opening_name: 'Compare: {opening_name}',
  compare: 'Compare',
  competencies_archive: "Archive: @:{'plural_competencies'}",
  competencies_archived: 'Item archived | Items archived',
  competencies_deleted: 'Item deleted | Items deleted',
  competencies_duplicated: 'Item duplicated | Items duplicated',
  competencies_groups: "Groups: @.capitalize:{'plural_competencies'}",
  competencies_guideline_description: 'Competencies are the knowledge, skills, or abilities defined in terms of observable behaviors needed to perform a task or job.<p class="mt-2"> There should be 8 job-specific competencies in total.</p>',
  competencies_moved: 'Item moved | Items moved',
  competencies_published: 'Item published | Items published',
  competencies_restored: 'Item restored | Items restored',
  competencies_updated: "@.capitalize:{'singular_competencies'} updated | @.capitalize:{'plural_competencies'}  updated",
  competencies: "@:{'singular_competencies'} | @:{'plural_competencies'}",
  competency_already_exists: 'An item with that name already exists.',
  competency_based_questions_note_label: 'Notes: Situation, Action, Results.',
  competency_based_questions: 'Competency-based questions',
  competency_details: "@.capitalize:{'singular_competencies'} details",
  competency_groups: "Groups: @.capitalize:{'plural_competencies'}",
  competency_levels: 'Competency levels',
  competency_name_and_levels: "@.capitalize:{'singular_competencies'} names and levels only",
  competency_not_translated: 'Parts of this competency have not been translated.',
  competency_published_in_jobs: 'It will be updated on {count_published_jobs}.',
  competency_question_updated: "@.capitalize:{'singular_competencies'} question updated",
  competency_questions_deleted: "@.capitalize:{'singular_competencies'} question deleted | @.capitalize:{'singular_competencies'} questions deleted",
  competency_questions_dulicated: "@.capitalize:{'singular_competencies'} question duplicated | @.capitalize:{'singular_competencies'} questions duplicated",
  competency_questions: "@.capitalize:{'singular_competencies'} question | @.capitalize:{'singular_competencies'} questions",
  complete_setup_modal_text_1: 'Once you complete the setup this review will be scheduled and parts of the setup will not be editable. {learn_more_link}',
  complete_setup_modal_text_2: 'You can {check_the_setup} first to preview the assessments and check for any setup errors.',
  complete_setup: 'Complete setup',
  complete: 'Complete',
  completed_signoffs_by_date: 'Completed signoffs by date',
  completed_survey_message: 'You have already completed this survey. {link}',
  completed: 'Completed',
  completion_notifications: 'Completion notifications',
  completion_rate: 'Completion rate',
  conclusion: 'Conclusion',
  condensed_list_of_questions: 'Condensed list of questions (with no space for notes)',
  confirm_delete_permission_description: "You can't undo this action. This permission set will be permanently deleted.",
  confirm_permission_set_delete: 'Delete permission set?',
  confirm_tag_deletion: 'Are you sure you want to delete this tag?',
  confirm: 'Confirm',
  consistency_description: 'Define the ideal range of {library} for each job.',
  consistency_related_guidelines_message: 'To help create consistent job descriptions, please {message} {fromOrTo} this job.',
  consistency_saving_toast_message: 'Updates can take up to one hour to go live',
  consistency_scores_tooltip: 'Achieve more consistent job descriptions by defining ideal content ranges.',
  consistency_scores: 'Consistency score | Consistency scores',
  consistency_setting_dialog_description: 'Consistency scores will be turned off for jobs using this template.',
  consistency_setting_dialog_title: 'Turn off consistency?',
  consistency_settings_dialog_description: "Define the ideal range of {library} for each job. We'll nudge job collaborators to stay within this range to ensure consistency.",
  consistency_settings: 'Consistency settings',
  consistency_turn_off_message: 'Consistency will be turned off for {library_type}',
  consistency: 'Consistency',
  contact_us: 'Contact us',
  content_action_instruction: "Type '/' to see options",
  content_activities: 'Content activities',
  content_defaults_jobs: 'Content defaults - Jobs',
  content_defaults_libraries: 'Content defaults - Libraries',
  content_defaults_updated: 'Content defaults updated',
  content_defaults: 'Content defaults',
  content_finder_competency_ai_suggestions_description: "Suggestions are based on the job @.lower:{'summary'} and @.lower:{'plural_responsibilities'}.",
  content_finder: 'Content finder',
  content_language: 'Content language',
  content_type: 'Content type',
  content_types: 'Content types',
  content_update: 'Content update',
  content_usage: 'Content usage',
  content_warehouse: 'Content warehouse',
  content: 'Content',
  contents: 'Content',
  continue_editing: 'Continue editing',
  contributor_added_to_job: 'Contributor added to job',
  convert_to_plain_text: 'Convert to plain text',
  copied: 'Copied',
  copy_failed: 'Failed to copy',
  copy_post: 'Copy post',
  copy_scorecard_links: 'Copy scorecard link',
  copy_to_clipboard: 'Copy to clipboard',
  copy_url: 'Copy link to page',
  copy: 'Copy',
  core_competencies_description: "Core @.lower:{'plural_competencies'} are added to all the jobs in your organization.",
  core_competencies_homepage_description: "Communicate your core @.lower:{'plural_competencies'}.",
  core_competencies_will_appear_here: "Core @.lower:{'plural_competencies'} will appear here",
  core_competencies: "Core @.lower:{'competencies'}",
  core_competency_widget: "Core @.lower:{'singular_competencies'} widget",
  core: 'Core',
  count_added: '{count} added.',
  count_additional_participants: '{count} additional participants',
  count_days: '{count} day | {count} days',
  count_draft_jobs: '{count} draft job | {count} draft jobs',
  count_jobs: '{count} job | {count} jobs',
  count_minutes: '{count} minutes',
  count_openings: '{count} openings',
  count_participants: '{count} participant | {count} participants',
  count_published_jobs: '{count} published job | {count} published jobs',
  count_questions: '{count} question | {count} questions',
  count_responses: '{count} response | {count} responses',
  count_subject_added: '<span class="text-link cursor-pointer">{count} subjects</span> added.',
  count_subjects: '{count} subjects',
  count_to_do: '{count} to do',
  create_additional_information_group: 'Create group',
  create_certifications_group: 'Create group',
  create_certifications: "Create @.lower:{'singular_certifications'}",
  create_communications_materials: 'Create communications materials',
  create_competencies_group: 'Create group',
  create_competencies: "Create @.lower:{'singular_competencies'}",
  create_competency_questions: "Create @.lower:{'singular_competencies'} question",
  create_department: 'Create department',
  create_education: "Create @.lower:{'singular_educations'}",
  create_educations_group: 'Create group',
  create_group: 'Create group',
  create_guides: 'Create template | Create templates',
  create_interview_templates: 'Create interview template',
  create_job_property: 'Create job property',
  create_job_template: 'Create job template',
  create_job: 'Create job',
  create_library_group: 'Create group',
  create_new_templates: 'Create new template',
  create_openings_limit_link: 'Please contact {link} to increase your limit.',
  create_openings_limit: "You've hit your license limit for openings.",
  create_openings: 'Create opening',
  create_password: 'Create password',
  create_permission_set: 'Create permission set',
  create_position: 'Create position',
  create_responsibilities: "Create @.lower:{'singular_responsibilities'}",
  create_responsibility_group: 'Create group',
  create_reviews: 'Create review',
  create_skill_group: 'Create group',
  create_skill: "Create @.lower:{'singular_skills'}",
  create_survey: 'Create survey',
  create_templates: 'Create template',
  create_topic_question: 'Create topic question',
  create_topic: 'Create topic',
  create: 'Create',
  created_by_name: 'Created by {name}',
  created_by_system: 'Created by System',
  created_by: 'Created by',
  created_date: 'Created date: {date}',
  created_draft_copy: 'Created draft',
  created_on: 'Created on: {date}',
  created_with_date: 'Created: {date}',
  created: 'Created',
  creates_new_draft_versions_of_published_jobs_for_review: 'Creates new draft versions of published jobs for review.',
  creates_new_published_versions_of_published_jobs: 'Creates new published versions of published jobs.',
  current_description: 'The employee has signed off on the latest version of their job.',
  current_draft_version: 'Current draft version',
  current_due_date: 'Current due date',
  current_jobs: 'Current job',
  current_of_total: '{current} of {total}',
  current_published_version: 'Current published version',
  current_round: 'Current round',
  current: 'Current',
  custom: 'Custom',
  d: 'D',
  daily: 'Daily',
  dashboard: 'Dashboard',
  date_and_time: 'Date and time',
  date_format_international_dash: 'DD-MM-YYYY - International',
  date_format_international_slash: 'DD/MM/YYYY - International',
  date_format_iso_dash: 'YYYY-MM-DD - ISO Standard',
  date_format_iso_slash: 'YYYY/MM/DD - ISO Standard',
  date_format_us_standard_dash: 'MM-DD-YYYY - US Standard',
  date_format_us_standard_slash: 'MM/DD/YYYY - US Standard',
  date: 'Date',
  deactivate_users_selection: "This user will no longer have access to the software. We'll keep their contributions. | These users will no longer have access to the software. We'll keep their contributions.",
  deactivate_users: 'Deactivate',
  deactivate: 'Deactivate',
  deactivated_users: 'Deactivated user',
  deactivated: 'Deactivated',
  dec: 'Dec',
  decent: 'Decent',
  decide: 'Decide',
  default_collaborator_invite_date: 'Please provide your input by: {date}.',
  default_collaborator_invite: '{name} would like your input on the {job} job description.',
  default_due_date: 'Default due date',
  default_interview_rounds: 'Default interview round',
  default_language: 'Default language',
  default_location: 'Default location',
  default_permission_cannot_be_removed: "This permission set cannot be removed. It's mandatory to have at least one default permission set.",
  default_permission_sets: 'Default permission sets',
  default_template_can_not_delete: "This template can't be deleted because it is the default.",
  default: 'Default',
  define_archicture_order_description: "Show @.lower:{'plural_competencies'} in a specific order, according to architecture type. Note, this will only apply to published jobs.",
  define_archicture_order: 'Define architecture order',
  define_full_description: 'Grants access to Quinto as a full user.',
  define_full_option_description: 'Provides full access to Quinto management and administrative functionality. Users added to this permission set receive a full user license for Quinto.',
  define_full: 'Full',
  define_import_settings: 'Define your settings.',
  define_light_description: 'Provides limited access to Quinto functionality.',
  define_light_option_description: 'Provides limited access to Quinto features.',
  define_light: 'Light',
  definitions: 'Definition',
  delete_additional_information_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_additional_information_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_additional_information: "Delete @.lower:{'additional_information'}?",
  delete_api_key_confirmation: 'Are you sure you want to delete this key?',
  delete_api_key: 'Delete API key?',
  delete_assessment_modal_description: "You can't undo this action. This assessment will be permanently deleted.",
  delete_assessment: 'Delete assessment',
  delete_behavior_msg: "Delete the behavior if it's no longer needed.",
  delete_behavioral_indicator_msg: 'This behavior will be deleted.',
  delete_behavioral_indicator: 'Delete behavioral indicator?',
  delete_candidate_dialog_msg: "Are you sure? You can't undo this action. We'll delete this candidate and all associated data from any openings they are a part of.",
  delete_candidate: 'Delete candidate?',
  delete_certifications_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_certifications_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_certifications: "Delete @.lower:{'certifications'}?",
  delete_comment_confirmation: 'Are you sure you want to delete this comment?',
  delete_comments_confirmation_message: 'Are you sure you want to delete this comment?',
  delete_comments: 'Delete comment?',
  delete_communications_message: "You can't undo this action. These communications materials will be permanently deleted and removed from any templates they have been added to.",
  delete_communications: 'Delete communication? | Delete communications?',
  delete_competencies_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_competencies_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_competencies: "Delete @.lower:{'competencies'}?",
  delete_competency_questions_msg: "You can't undo this action. This question will be permanently deleted. | You can't undo this action. These questions will be permanently deleted.",
  delete_competency_questions: "Delete @.lower:{'singular_competencies'} question? | Delete @.lower:{'singular_competencies'} questions?",
  delete_department_confirmation: "You can't undo this action. This department will be permanently deleted. (Positions won't be affected.)",
  delete_department: 'Delete department?',
  delete_draft_copy_job_modal_mag: 'This action cannot be undone. This draft will be permanently deleted from your site.',
  delete_draft_copy_job_modal_title: 'Delete draft?',
  delete_draft_copy_msg: "You can't undo this action. This draft will be permanently deleted.",
  delete_draft_copy: 'Delete draft',
  delete_draft: 'Delete draft',
  delete_educations_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_educations_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_educations: "Delete @.lower:{'educations'}?",
  delete_experiences_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_experiences_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_experiences: "Delete @.lower:{'experiences'}?",
  delete_group_confirmation: "You can't undo this action. This group will be permanently deleted.",
  delete_group: 'Delete group | Delete groups',
  delete_job_family_competencies: "Delete job-family @.lower:{'plural_competencies'}?",
  delete_job: 'Delete job',
  delete_jobs_message: "You can't undo this action. This job will be permanently deleted from your site. | You can't undo this action. These jobs will be permanently deleted from your site.",
  delete_jobs_msg: "You can't undo this action. This job will be permanently deleted from your site.",
  delete_jobs: 'Delete job? | Delete jobs?',
  delete_languages_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_languages_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_languages: "Delete @.lower:{'languages'}?",
  delete_level_and_data: 'Delete level and data',
  delete_level_data: 'Delete level data',
  delete_level_from: 'Delete this level from:',
  delete_level_message: 'This level will be deleted.',
  delete_level_title: 'Delete level?',
  delete_level_update_property_msg: 'This level will no longer be available. Existing content and data will not be affected.',
  delete_level_update_property: 'Delete this level and update property',
  delete_level: 'Delete level',
  delete_library_group: 'Delete library group?',
  delete_library_item_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_link_message: "You can't undo this action. This useful link will be permanently deleted.",
  delete_links: 'Delete link',
  delete_location_msg: "You can't undo this action. This location will be permanently deleted from locations, users, and removed from {count_published_jobs}.",
  delete_location: 'Delete location?',
  delete_name: 'Delete {name}?',
  delete_openings_confirmation_message: "Are you sure? You can't undo this action. This opening will be permanently deleted from your site. | Are you sure? You can't undo this action. These openings will be permanently deleted from your site.",
  delete_openings_confirmation_title: 'Delete opening? | Delete openings?',
  delete_option_and_data: 'Delete option and data',
  delete_option_data_msg: 'Data will be deleted from applicable job descriptions. If installed, development activities may also be affected. Learn more.',
  delete_option_data: 'Delete option data',
  delete_option_update_property_msg: 'This option will no longer be available. Existing content and data will not be affected.',
  delete_option_update_property: 'Delete this option and update property',
  delete_option: 'Delete option',
  delete_position_msg: "You can't undo this action. This position will be permanently deleted.",
  delete_positions_selection: "You can't undo this action. This position will be permanently deleted. | You can't undo this action. These positions will be permanently deleted.",
  delete_positions: 'Delete position? | Delete positions?',
  delete_properties_confirmation_message: "You can't undo this action. This property will be permanently deleted. There aren't any published jobs that include this property.",
  delete_properties_with_jobs_confirmation_message: "You can't undo this action. This property will be permanently deleted from {count_published_jobs}.",
  delete_property_data_msg: 'Data will be deleted from applicable job descriptions. If installed, development activities my also be affected. Learn more.',
  delete_property_data: 'Delete property data',
  delete_property_levels_confirmation_message: "You can't undo this action. This level will be removed from the property. There aren't any jobs that include this level.",
  delete_property_levels_with_jobs_confirmation_message: "You can't undo this action. This level will be removed from the property. Level data and leadership competencies will be permanently deleted from {count_published_jobs}.",
  delete_property_options_confirmation_message: "You can't undo this action. This option will be removed from the property. There aren't any published jobs that include this property.",
  delete_property_options_with_jobs_confirmation_message: "You can't undo this action. This option will be removed from the property. Option data will be permanently deleted from {count_published_jobs}.",
  delete_range_and_data: 'Delete range and data',
  delete_range_data: 'Delete range data',
  delete_range_update_property_msg: 'This range will no longer be available. Existing content and data will not be affected.',
  delete_range_update_property: 'Delete this range and update property',
  delete_range: 'Delete range',
  delete_responsibility_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_responsibility_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_responsibility: "Delete @.lower:{'responsibilities'}?",
  delete_review_confirmation_message: "You can't undo this action. This review will be permanently deleted.",
  delete_reviews: 'Delete review',
  delete_rounds_confirmation_message: "You can't undo this action.  Existing rounds are not affected but new openings will not include this round.",
  delete_rounds_confirmation: 'Are you sure? This round - {name} - will be deleted. Any interviews and ratings in this round will be permanently deleted.',
  delete_rounds: 'Delete round?',
  delete_scale_sublabel_2: 'This level will no longer be available and related data will be deleted from draft and published jobs.',
  delete_scales_confirmation_message: "You can't undo this action. This level will be removed from the scale. There aren't any published jobs that include this level.",
  delete_scales_with_jobs_confirmation_message: "You can't undo this action. This level will be removed from the scale. Level data will be permanently deleted from {count_published_jobs}.",
  delete_skill_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_skill_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_skill: "Delete @.lower:{'skills'}?",
  delete_skills_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_skills: "Delete @.lower:{'skills'}?",
  delete_statuses_confirmation: 'Removing this status will prevent users from using it in future. It will not change the status of any existing candidates.',
  delete_statuses: 'Remove status?',
  delete_subheadings_confirmation_message: "You can't undo this action. This subheading will be permanently deleted. There aren't any published jobs that include this subheading.",
  delete_subheadings_with_jobs_confirmation_message: "You can't undo this action. This subheading will be permanently deleted from {count_published_jobs}.",
  delete_surveys_confirmation_message: "You can't undo this action. This survey will be permanently deleted. | You can't undo this action. These surveys will be permanently deleted.",
  delete_surveys: 'Delete survey? | Delete surveys?',
  delete_tag: 'Delete tag?',
  delete_templates_msg: "You can't undo this action. This template will be permanently deleted. | You can't undo this action. These templates will be permanently deleted.",
  delete_templates: 'Delete template? | Delete templates?',
  delete_topic_msg: 'This topic will be removed from the topic questions.',
  delete_topic_questions_msg: "You can't undo this action. This topic question will be permanently deleted. | You can't undo this action. These topic questions will be permanently deleted.",
  delete_topic_questions: 'Delete topic question? | Delete topic questions?',
  delete_users_selection: "This user will be removed from the software. We'll keep their contributions. | These users will be removed from the software. We'll keep their contributions.",
  delete_users: 'Delete user? | Delete users?',
  delete_working_conditions_confirmation: "You can't undo this action. This item will be permanently deleted. | You can't undo this action. These items will be permanently deleted.",
  delete_working_conditions_msg: "You can't undo this action. This item will be permanently deleted from the archive.",
  delete_working_conditions: "Delete @.lower:{'working_conditions'}?",
  delete: 'Delete',
  deleted_job: 'Deleted job',
  deleted_users: 'Deleted user | Deleted users',
  deleted: 'Deleted',
  demoted: 'Demoted',
  departed: 'Departed',
  department_assigned: 'Department assigned',
  departments_will_be_created: '{count} department will be created | {count} departments will be created',
  departments: 'Department | Departments',
  departure: 'Departure',
  deployed: 'Deployed',
  deprecated: 'Deprecated',
  description_communications: 'Define the opening and closing sections of interview templates.',
  description: 'Description',
  designated_users: 'Designated users',
  detailed_results: 'Detailed results',
  details: 'Detail | Details',
  development: 'Development',
  differences: 'Differences',
  direct_reports: 'Direct reports',
  direct: 'Direct',
  disable_review_cycles_message: 'Turning off review cycles will cancel any review tasks and delete any review dates. You cannot undo this action.',
  disable_review_cycles_title: 'Turn off review cycle?',
  disable: 'Disable',
  disabled: 'Disabled',
  discard_comment_confirmation: 'Are you sure you want to discard your comment?',
  discard_comment: 'Discard comment?',
  discard_edit_confirmation: 'Do you want to discard your edit?',
  discard_edit: 'Discard edit?',
  discard: 'Discard',
  disclaimer_section_remove_message: 'The section will be removed from all PDF / Word exports.',
  disclaimer_text: 'Disclaimer text',
  disclaimer: 'Disclaimer',
  discussion_and_sharing_communicated_deadline: 'This is the deadline given to managers to schedule discussions and share reviews. They can still perform these actions after this date.',
  discussion_and_sharing_description: 'Managers schedule a time to discuss and share the review results with their direct reports. You can leave this blank if HR is communicating results.',
  discussion_and_sharing_launch_date: 'Managers will receive a notification and email on this date prompting them to schedule a discussion with their direct reports to share results.',
  discussion_and_sharing_review_acknowledgements_description: "Require subjects to confirm they've received their assessment results.",
  discussion_and_sharing_subtitle: 'Discuss and share review',
  discussion_and_sharing: 'Discussion and sharing',
  dislikes: 'Dislike | Dislikes',
  dispatched: 'Dispatched',
  do_not_hire: 'Do not hire',
  document_title_my_reviews: 'Reviews - My reviews',
  document_title_warehouse_competencies: 'Warehouse: Competency',
  does_not_have_ai_suggestion: "We don't have any suggestions for you",
  does_not_provide_a_process_for_notifying_incumbents: "Doesn't provide a process for notifying incumbents.",
  done_editing: 'Done editing',
  done: 'Done',
  download_completed_signoffs: 'Download completed signoffs',
  download_excel: 'Download Excel',
  download_guide: 'Download guide',
  download_interview_guides: 'Download interview guide',
  download_interview_templates: 'Download interview template',
  download_job_description: 'Download job description',
  download_pdf: 'Download PDF',
  download_png: 'Download PNG',
  download_results: 'Download results',
  download_signoff_report_message: 'Download a report of completed signoffs',
  download_started: 'Download started',
  download_table: 'Download table',
  download_word: 'Download Word',
  download: 'Download',
  draft_copy_with_published: 'Draft + Published',
  draft_copy: 'Draft',
  draft_deleted: 'Draft deleted',
  draft_job_no_guides_sub_message: 'Publish this job to add interview templates.',
  draft_plus_published: 'Draft + Published',
  draft: 'Draft',
  drag_here: 'Drag here',
  due_date_included_in_survey_communications: "Due dates are included in survey communications. The survey will not automatically close on this date - you'll still need to manually close the survey.",
  due_date: 'Due date',
  due_x: 'Due: {date}',
  due: 'Due',
  duplicate_job: 'Duplicate job',
  duplicate: 'Duplicate',
  duplicated: 'Duplicated',
  duration: 'Duration',
  e: 'E',
  edit_behavioral_indicator: 'Edit behavioral indicator',
  edit_candidate_details: 'Edit candidate details',
  edit_candidate_statuses: 'Edit candidate status',
  edit_certification: "Edit @.lower:{'singular_certifications'}",
  edit_definitions: 'Edit definition',
  edit_department: 'Edit department',
  edit_details: 'Edit details',
  edit_due_date_reminders: 'Edit due date reminders',
  edit_education: "Edit @.lower:{'singular_educations'}",
  edit_email: 'Edit email',
  edit_follow_ups: 'Edit follow-ups',
  edit_group_name: 'Edit group name',
  edit_guideline_description: 'Provide job collaborators with custom guidelines to help them create high-quality job descriptions.',
  edit_guideline_dialog_title: '{library} guidelines',
  edit_guideline: 'Edit these guidelines',
  edit_instructions: 'Edit instructions',
  edit_interview_rounds: 'Edit interview round',
  edit_jobs: 'Edit job',
  edit_level_title: 'Edit level title',
  edit_levels: 'Edit level',
  edit_links: 'Edit link',
  edit_location_msg: 'This location will be updated in locations, users, and on {count_published_jobs}.',
  edit_location: 'Edit location',
  edit_name_1: 'Edit {name}',
  edit_name_and_definition: 'Edit name and definition',
  edit_name: 'Edit name',
  edit_openings: 'Edit opening',
  edit_options: 'Edit option',
  edit_position: 'Edit position',
  edit_questions: 'Edit questions',
  edit_ranges: 'Edit range',
  edit_responsibilities: "Edit @.lower:{'singular_responsibilities'}",
  edit_schedule: 'Edit schedule',
  edit_setup: 'Edit setup',
  edit_skill: "Edit @.lower:{'singular_skills'}",
  edit_summary: 'Edit summary',
  edit_tags: 'Edit tags',
  edit_templates: 'Edit template',
  edit_this_statement: 'Edit this statement',
  edit_title_and_communications: 'Edit title and communications',
  edit_title: 'Edit title',
  edit_topic: 'Edit topic',
  edit_types: 'Edit type',
  edit: 'Edit',
  edited_color: 'Edited color',
  edited_date: 'Edited: {date}',
  edited_interview_guide_question_positions: 'Edited interview template question positions',
  edited_job_competency_positions: "Edited job @.lower:{'singular_competencies'} positions",
  edited_job_responsibility_positions: "Edited job @.lower:{'singular_responsibilities'} positions",
  edited_job_skill_positions: "Edited job @.lower:{'singular_skills'} positions",
  edited_levels: 'Edited levels',
  edited_questions: 'Edited questions',
  edited: 'Edited',
  editors_description: 'Editors can do everything reviewers can do. They can also add, delete, and edit job content.',
  editors: 'Editor | Editors',
  education_archive: "Archive: @:{'plural_educations'}",
  education_edited: 'Item edited | Items edited',
  education_groups: "Groups: @.capitalize:{'plural_educations'}",
  education_scales: 'Education scale',
  education: 'Education',
  educations_archived: 'Item archived | Items archived',
  educations_created: 'Item created | Items created',
  educations_deleted: 'Item deleted | Items deleted',
  educations_guideline_description: 'Educational credentials are qualifications that individuals acquire through formal education and training. These credentials provide evidence, usually in the form of a diploma or degree, confirming the successful completion of an educational program in a specific field of study or subject.<p class="mt-2"> There should be no more than 4 education requirements in total.</p>',
  educations_moved: 'Item moved | Items moved',
  educations_published: 'Item published | Items published',
  educations_restored: 'Item restored | Items restored',
  educations_updated: "@.capitalize:{'singular_educations'} updated | @.capitalize:{'plural_educations'} updated",
  educations: "@:{'singular_educations'} | @:{'plural_educations'}",
  effective_dates: 'Effective date',
  email_content: 'Email content',
  email_notifications_comments_description: 'Get notified when someone comments on a comment you made.',
  email_notifications_description: "Manage your email notifications. Please note: You can't unsubscribe from some emails, like being invited to collaborate in a job or participate in a survey.",
  email_notifications_feedback_description: 'Get notified when feedback is left on a job you are collaborating on.',
  email_notifications_mentions_description: 'Get notified when someone mentions you in a job collaboration.',
  email_notifications_review_cycles_description: 'Get notifications for upcoming job reviews.',
  email_notifications_updates_description: 'Get notified when a job you are collaborating on is published.',
  email_notifications_weekly_job_summary_description: 'Get a weekly summary of job updates.',
  email_notifications: 'Email notification | Email notifications',
  email_subject: 'Email subject',
  email_taken: "The email address you entered is already in use. If you can't find it, check the manage deleted users page.",
  email: 'Email',
  employee_added_by: 'Employee added by {name}',
  employee_id: 'Employee ID',
  employee_profiles: 'Employee profiles',
  employees: 'Employee | Employees',
  empty_table: 'Nothing to see here',
  en: 'English',
  enable: 'Enable',
  enabled: 'Enabled',
  end_date: 'End date',
  end: 'End',
  enter_behavioral_indicator: 'Enter behavioral indicator',
  enter_definition: 'Enter definition',
  enter_id: 'Enter ID',
  enter_name: 'Enter name',
  enter_new_password: 'Enter new password',
  enter_position_id: 'Enter position ID',
  enter_subheading: 'Enter subheading',
  enter_summary: 'Enter summary',
  enter_url: 'Enter URL',
  equivalent_skills_tooltip: 'Jobs in the warehouse may contain equivalent skills and competencies. To include equivalent skills when importing content from the warehouse, check this box. We recommend leaving this unchecked to avoid duplicating competencies and skills.',
  equivalent_skills: 'Equivalent skills',
  evaluations: 'Evaluations',
  every_login: 'Every login',
  everything_looks_good: 'Everything looks good',
  exceed_licenses_1: "Unfortunately, you've run out of licenses. You can filter by license type or permission set to confirm users' access levels. Please contact customer success if you'd like additional licenses.",
  exceed_licenses_2: "Unfortunately, you've run out of licenses. Please remove licenses from other users or contact customer success for additional licenses.",
  exceed_licenses_3: "Unfortunately, you've run out of licenses and we can't perform this action. Please contact customer success if you'd like additional licenses.",
  exceed_requirements: 'Exceed requirements',
  excel: 'Excel',
  exceptions: 'Exceptions',
  exclude: 'Exclude',
  existing_job_descriptions: 'Existing job descriptions',
  exit_continue_later: 'Exit and continue later',
  exit: 'Exit',
  expand_all: 'Expand all',
  experience: "@:{'singular_experiences'} | @:{'plural_experiences'}",
  experiences_archive: "Archive: @:{'plural_experiences'}",
  experiences_archived: 'Item archived | Items archived',
  experiences_created: 'Item created | Items created',
  experiences_deleted: 'Item deleted | Items deleted',
  experiences_published: 'Item published | Items published',
  experiences_restored: 'Item restored | Items restored',
  experiences_updated: "@.capitalize:{'singular_experiences'} updated | @.capitalize:{'plural_experiences'} updated",
  experiences: "@:{'singular_experiences'} | @:{'plural_experiences'}",
  expired_description: 'The signoff is no longer valid. (This is based on job template settings.)',
  expired: 'Expired',
  expiry_date: 'Expiry date',
  expiry: 'Expiry',
  explanation_introduction_text: 'Explanation link text',
  explanation_message: 'Explanation message',
  export_candidate_guide_pdf: 'Export candidate template PDF',
  export_candidate_guide_word: 'Export candidate template Word',
  export_data: 'Export data',
  export_job: 'Export job',
  export_org_chart: 'Export org chart',
  export_pdf: 'Export PDF',
  export_ready_to_download: 'Your export is ready to download',
  export_to_pdf: 'Export to PDF',
  export_to_word: 'Export to Word',
  export_word: 'Export Word',
  export: 'Export',
  exports: 'Export | Exports',
  f: 'F',
  failed_login_attempts_msg: '{count} failed login attempts since login.',
  failed_to_create_job: 'Failed to create job',
  features: 'Feature',
  feb: 'Feb',
  feedback_deleted: 'Feedback deleted',
  feedback_description: 'Provide input on this job description.',
  feedback_post_template_tooltip: 'Allow job collaborators to provide high-level job description feedback.',
  feedback_provided: 'Feedback provided',
  feedback_updated: 'Feedback updated',
  feedback_visibility: 'Feedback visibility',
  feedback: 'Feedback',
  file_not_supported: "We don't support your file format.",
  file_same_columns_as_last_file: 'This file has the same columns as the last file imported by {name} on {date}',
  file_too_large_mb: 'Your file is too large. Please upload a file under {size} MB.',
  filter_interviewers: 'Filter interviewers',
  filter_results: 'Filter results',
  filter: 'Filter',
  filtered_by: 'Filtered by',
  filters: 'Filter | Filters',
  find_competencies: "Find @.lower:{'plural_competencies'}",
  find_departments: 'Find department',
  find_job: 'Find job',
  find_position_or_user: 'Find position or user',
  find_user_or_position: 'Find user or position',
  find_users: 'Find user | Find users',
  fired: 'Fired',
  first_name: 'First name',
  fit_for_role: 'Fit for the role',
  fit_for_the_role: 'Fit for the role',
  flag_jobs_for_later_notifications_msg: "Flagged jobs will appear in the content activities table so that you can notify incumbents of changes when you're ready.",
  flag_jobs_for_later_notifications: 'Flag jobs for later notifications',
  flagged_by: 'Flagged by {name}',
  flagged: 'Flagged',
  flip_toolbar: 'Flip toolbar',
  follow_ups: 'Follow-ups',
  for_for_role: 'Fit for role',
  forgot_your_password: 'Forgot your password?',
  format: 'Format',
  fr: 'French',
  from_date_to: '{from} to {to}',
  from_hiring_team: 'From hiring team',
  from_jobs: 'From jobs',
  from_name_2: 'From {name}',
  from_name: 'From: {name}',
  from_to: 'From {from} to {to}',
  from_warehouse: 'From warehouse',
  from: 'From',
  full_competencies: "Full @.lower:{'plural_competencies'}",
  full_screen: 'Full screen',
  general_account_details: 'General account details',
  general_administration: 'General administration',
  general_comments: 'General comments',
  general_error: 'Sorry, something went wrong',
  general_notes: 'General notes',
  general: 'General',
  give_feedback: 'Give feedback',
  go_live_date: 'Go-live date',
  go_live_time: 'Go-live time',
  go_live: 'Go live',
  go_to_home_page: 'Go to home page',
  go_to_homepage: 'Go to the homepage',
  goals: 'Goals',
  good_to_go: 'Good to go',
  google: 'Google',
  got_it: 'Got it',
  grant_signoff_message_1: '{name} will be automatically granted signoff on the latest version of their job. No signoff request will be sent. | Selected employees will be automatically granted signoff on the latest version of their job. No signoff request will be sent.',
  grant_signoff_message_2: 'You may choose to grant signoff if you have made trivial updates to a published job and do not require a new signoff from employees.',
  grant_signoff_title: 'Grant signoff?',
  grant_signoffs_on_version: 'Grant signoff on version {version}',
  grant_signoffs: 'Grant signoff',
  group_created: 'Group created',
  group_deleted: 'Group deleted',
  group_name_updated: 'Group name updated',
  groups: 'Group | Groups',
  grow_access: 'Grow',
  grow_description: 'Grants access to Grow.',
  grow_option_description: 'Provides access to development functionality included in Grow. Users added to this permission set are granted a Grow user license.',
  grow: 'Grow',
  guide_communications: 'Interview template communications',
  guide_job_related_questions_empty_msg: 'No job-related questions? <p class="mt-2">Make sure the job description contains competencies and proficiency levels.</p>',
  guide_not_translated: 'Parts of this template have not been translated.',
  guideline_description: 'Provide job collaborators with custom guidelines to help them create high-quality job descriptions.',
  guidelines: 'Guidelines',
  guides_deleted: 'Template deleted | Templates deleted',
  guides: 'Template | Templates',
  header_background: 'Site header background color',
  header_foreground: 'Site header foreground color',
  help_center: 'Help center',
  hi_name: 'Hi {name}',
  hidden_columns: 'Hidden columns',
  hide_all_details: 'Hide all details',
  hide_changes: 'Hide changes',
  hide_details: 'Hide details',
  hide_property_data_msg: 'Data will not be displayed on job descriptions.',
  hide_property_data: 'Hide property data',
  hide_toolbar: 'Hide toolbar',
  hide: 'Hide',
  highlight: 'Highlight',
  highlights: 'Highlight | Highlights',
  hire_date_formats: 'Hire date format',
  hire_date: 'Hire date',
  hiring_manager_warning: 'If you want the hiring manager to choose interview questions, contact a Quinto administrator and request this user is given permission to Access interview templates.',
  hiring_manager: 'Hiring manager: {name}',
  hiring_managers: 'Hiring manager',
  hiring_team_invitation_message: 'An invitation will be sent to new members.',
  hiring_team_members: 'Hiring team member',
  hiring_teams: 'Hiring team',
  history: 'History',
  hold_tight: 'Hold tight',
  home: 'Home',
  homepage_core_competencies: 'Homepage - Core competencies',
  homepage_description: 'Define the homepage information and experience for users.',
  homepage_job_analytics: 'Homepage - Job analytics',
  homepage_useful_links: 'Homepage - Useful links',
  homepage_welcome_message: 'Homepage - Welcome message',
  homepage: 'Homepage',
  hourly: 'Hourly',
  ideal_range: 'Ideal range',
  identified_users_in_file: "We've identified {number} users in your file",
  if_import_file: 'If you import your file...',
  im_done: "I'm done",
  im_still_here: "I'm still here",
  import_active_status_tooltip: 'Select the statuses in your import file that should be imported as active.',
  import_departments_tooltip_2: "We'll create new departments if we can't find departments that already exist.",
  import_departments_tooltip: "We'll place positions in departments based on this data.",
  import_frequency: 'Import frequency',
  import_job_title_tooltip_2: "New positions can still be created from titles in the position column, but they'll be associated to jobs that are an exact title match to the data in the job title column.",
  import_job_title_tooltip: 'If your import file has separate columns for job titles and position titles, use this field to match positions with the right jobs.',
  import_jobs: 'Import jobs',
  import_language_tooltip_2: 'Any users without a language specified in the file will be assigned to the default language.',
  import_language_tooltip_3: "If you don't select a column, the default language will be assigned to all users included in the import file.",
  import_language_tooltip: "We'll set the user's language based on this column if matching languages are turned on in your site.",
  import_languages: 'Import language',
  import_location_tooltip_2: 'Any users without a location specified in the file will be assigned to the default location if one has been set.',
  import_location_tooltip_3: 'To assign all users to a default location, leave this selection blank and make sure you have defined a default location in Account settings.',
  import_location_tooltip: 'Map a column to create and assign different locations to each user in your import file.',
  import_manager_email_tooltip_2: 'Note that you can specify managers who are not included in the import file as long as they already exist in Quinto.',
  import_manager_email_tooltip: "We'll assign managers based on this data to build your organization's hierarchy",
  import_permission_sets_tooltip_2: 'Any users without a permission set specified in the file will be assigned to the default permission set.',
  import_permission_sets_tooltip_3: "If you don't select a column, the default permission set will be assigned to all users included in the import file.",
  import_permission_sets_tooltip: "We'll assign users to corresponding permission sets if we find an exact text match in your site. New permission sets can't be created through the import.",
  import_position_id_tooltip: 'Use Position IDs for more reliable position updates. Without IDs we will use text matching to update positions.',
  import_position_tooltip_2: "We'll create any new positions that don't already exist in your site. Job descriptions can't be created through the import.",
  import_position_tooltip_3: "We'll associate positions to jobs in your site that have an exact title match. Alternatively, you can use the job title field (below) if you manage job titles and position titles separately.",
  import_position_tooltip: 'Positions are the organizational glue in Quinto. Map this field to link users to jobs or to build your organizational hierarchy.',
  import_started: 'Import started',
  import_status_tooltip_2: "If you don't select a column, all users included in the import file will be imported as active.",
  import_status_tooltip: 'To import some users as active and others as inactive, map a column from your file.',
  import_users: 'Import users',
  import: 'Import',
  in_depth: 'In-depth',
  in_person_discussion: 'In-person discussion',
  in_progress: 'In progress',
  inactive: 'Inactive',
  include_content_as_per_template_settings: 'Includes content as per template settings',
  include_reports: 'Include reports',
  include_subdepartments: 'Include sub-departments',
  include_subheading_job_post: 'Include subheadings in job posts.',
  include: 'Include',
  includes: 'Include | Includes',
  incomplete: 'Incomplete',
  incorrect_password_or_bad_account_msg: "Your password is incorrect or this account doesn't exist. Please try again or <span id=\"reset-password-link\" class=\"text-link cursor-pointer\">reset your password</span>.",
  incumbent_removed_position: '{name} will be removed from their current position.',
  incumbents: 'Incumbent',
  information_manage_properties: 'Information - manage properties',
  information_tooltray_description: 'Choose job properties to display in job posts.',
  information: 'Information',
  initialize: 'Initialize',
  initiated: 'Initiated',
  input_closed: 'Closed for feedback',
  input_open: 'Open for feedback',
  insert_alternative: 'Insert alternative',
  insert_summary: 'Insert summary',
  instruction_content: 'Instruction content',
  instruction_heading: 'Instruction heading',
  instructions: 'Instruction | Instructions',
  integration_ats_for_interview_title: 'ATS for Interviews',
  integration_ats_for_interview: 'Select the ATS to integrate.',
  integration_ats_for_jobs_title: 'ATS for Jobs',
  integration_ats_for_jobs: 'Select the ATS to integrate.',
  integration_Hris_tab_description: 'Select the HRIS to integrate.',
  integrations: 'Integration | Integrations',
  internal: 'Internal',
  interview_conducted: 'Interview conducted',
  interview_details: 'Interview details',
  interview_feedbacks: 'Interview feedback | Interview feedbacks',
  interview_guide_questions: 'Interview template question | Interview template questions',
  interview_guide_scales: 'Interview template scale',
  interview_guides: 'Interview template | Interview templates',
  interview_plans: 'Interview plan',
  interview_question_followups: 'Interview question follow-ups',
  interview_question_topics: 'Interview question topic',
  interview_questions_moved_to_library_message: 'Interview questions have moved to Libraries. {link}',
  interview_questions: 'Interview question | Interview questions',
  interview_rounds_description: 'Define default interview rounds. Changes only affect rounds created in the future. Interview rounds can still be edited on individual openings - these are defaults only.',
  interview_rounds: 'Interview round | Interview rounds',
  interview_team: 'Interview team',
  interview_template_communications: 'Interview template communication | Interview template communications',
  interview_template_groups: 'Interview template groups',
  interview_templates: 'Interview template | Interview templates',
  interviewer_assign_question_tooltip: 'This question is assigned to {name}',
  interviewers: 'Interviewer | Interviewers',
  interviews: 'Interview | Interviews',
  intro_page_details: 'Survey introduction details',
  intro_page: 'Survey introduction',
  introduction: 'Introduction',
  invitation_email_details: 'Invitation email details',
  invitation_email_to_managers: 'Invitation email to managers',
  invitation_email_to_subjects: 'Invitation email to subjects',
  invitation_email: 'Invitation email',
  invitation_message: 'Invitation message',
  invitations_resent: 'Invitation resent',
  invitations_sent_go_live_date: 'Invitations will be sent on the go-live date and time.',
  invitations_sent: 'Invitation sent',
  invitations: 'Invitations',
  invite_collaborators: 'Invite collaborators',
  invite_sent: 'Invite sent',
  invite_x_to_quinto: 'Invite {name} to Quinto.',
  invite: 'Invite',
  invited_contributor: 'Invited contributor',
  invited_interviewer: 'Invited interviewer',
  invited: 'Invited',
  invites_sent: 'Invite sent | Invites sent',
  ip_address: 'IP address',
  item_not_translated: 'This item has not been translated.',
  item_same_name_exists: 'An item with the same name already exists',
  item: 'Item',
  items_added_items_not_added: '{added} item added. {not_added} item could not be added. | {added} items added. {not_added} items could not be added.',
  items_added_to_site: 'Item has been added to your site | Items have been added to your site',
  items_added: '{count} item added. | {count} items added.',
  items_moved: 'Item moved | Items moved',
  items_not_added: '{count} item could not be added. | {count} items could not be added.',
  items_not_translated: 'There are items that have not been translated.',
  items: 'Item | Items',
  jan: 'Jan',
  job_additional_informations_changes_were_found_live_review_text_1: 'Any submitted data will be permanently deleted.',
  job_additional_informations: 'Job additional information',
  job_ai_recognition_sub: 'Use AI to identify library items that your organization adds directly to jobs.',
  job_ai_recognition: 'AI recognition',
  job_ai_suggestions_des: 'View AI suggested content when creating and editing jobs.',
  job_ai_suggestions_sub: 'View AI to identify content when creating and editing jobs.',
  job_ai_suggestions: 'AI suggestions',
  job_analytics_description: 'This widget only displays for users who have the Access analytics permission.',
  job_analytics: 'Job analytic | Job analytics',
  job_certifications: 'Job certification',
  job_closed_for_input: 'Job closed for input',
  job_codes: 'Job code',
  job_comments: 'Job comment',
  job_comparison_scoring_authority_x_1: 'This is based on your ratings.',
  job_comparison_scoring_authority_x_2: "This is based on {subjectName}'s ratings.",
  job_comparison_scoring_authority_x_3: "This is based on {managerName}'s ratings.",
  job_comparison_scoring_authority_x_4: "This is based on ratings provided by {subjectName}'s managers.",
  job_comparison_scoring_authority_x_5: "This is based on ratings provided by {subjectName}'s peers.",
  job_comparison: 'Job comparison',
  job_competencies_set_to_done: "Job @.lower:{'plural_competencies'} set to done",
  job_competencies: "Job @.lower:{'singular_competencies'} | Job @.lower:{'plural_competencies'}",
  job_contributors: 'Job contributor | Job contributors',
  job_coverage_by_month_tooltip: 'The monthly percentage of positions that have published jobs.',
  job_coverage_by_month: 'Job coverage',
  job_coverage_tooltip: 'The percentage of positions that currently have published jobs.',
  job_coverage: 'Job coverage',
  job_description_tab: 'Job description',
  job_description_template_change_warning: 'Job data may be lost if you change templates.',
  job_description: 'Job description',
  job_descriptions: 'Job description | Job descriptions',
  job_details: 'Job details',
  job_draft_version_msg: "There's a <span class=\"border-b border-dashed\">draft version</span> of the job description. Publish the job description to update this page.",
  job_duplicate_message: 'Please use a unique title - this one already exists.',
  job_education_items: 'Job education item',
  job_educations: 'Job education',
  job_experience_items: 'Job experience item',
  job_experiences: 'Job experience',
  job_families: 'Job family',
  job_family_competencies_description: "These are added to jobs in each job group. To add job-family @.lower:{'plural_competencies'}, go to {jobGroups}.",
  job_family_competencies: "Job-family @.lower:{'plural_competencies'}",
  job_family: 'Job-family',
  job_group_competencies_description: 'These will automatically be added to all jobs in this group.',
  job_group_competencies: "Job group @.lower:{'singular_competencies'}",
  job_group_owners_description: 'These users will be automatically added to all jobs in this group.',
  job_groups: 'Job group | Job groups',
  job_has_been_updated: 'This job has been updated.',
  job_health: 'Job health',
  job_information_set_to_done: 'Job information set to done',
  job_input_close_message: 'This job is currently closed for input',
  job_language_items: 'Job language item',
  job_languages: 'Job language',
  job_level: 'Job level',
  job_levels: 'Job levels',
  job_match_scoring_authority_x_1: 'The percentage is based on your input.',
  job_match_scoring_authority_x_2: "The percentage is based on {subjectName}'s input.",
  job_match_scoring_authority_x_3: "The percentage is based on {managerName}'s input.",
  job_match_scoring_authority_x_4: "The percentage is based on input provided by {subjectName}'s managers.",
  job_match: 'Job match',
  job_matching: 'Job matching',
  job_multiple_export: "Export started. We'll send you an email when it's done.",
  job_no_guides_message: "It's pretty quiet here.",
  job_no_guides_sub_message: 'Why not create an interview template for this job?',
  job_opened_for_input: 'Job opened for input',
  job_post_empty_msg_sub: 'Publish this job description to create a job post',
  job_post_empty_msg: "There's nothing to see here.",
  job_post_template_library_content_desc: 'Turn on this section for posts using this template.',
  job_post_template_summary_description: 'Turn on this section to include the job summary in posts using this template.',
  job_post_templates: 'Job post template',
  job_post_will_sent_to_ats: 'This job post will be sent to {ats}.',
  job_post: 'Job post',
  job_posts: 'Job posts',
  job_properties_description: 'Properties define form elements that can be added to jobs.',
  job_properties_exports_description: 'Properties included in PDF / Word exports.',
  job_properties_general_description: 'Properties included when displaying jobs outside the jobs module.',
  job_properties_jobs_description: 'Properties available when working with jobs in the jobs module.',
  job_properties_updated: 'Job properties updated',
  job_properties: 'Job property | Job properties',
  job_property_multi_select: 'This is a multi-select property. When editing a job, users can choose one or more of the options below.',
  job_property_options: 'Job property option',
  job_property_renamed: 'Job property renamed',
  job_property_select: 'This is a single-select property. When editing a job, users can choose one of the options below.',
  job_property_textbox: 'This is an alphanumeric property. When editing a job, users can enter any combination of number and letters. There are no options for this property.',
  job_publish_recommended_message: 'Before publishing, we recommend updating the job.',
  job_publish_requirement_message: 'Before publishing, please update the job.',
  job_published_by: 'Job published by {name}',
  job_ready_to_go: 'This job is ready to go',
  job_related_questions: 'Job-related questions',
  job_republished_by: 'Job republished by {name}',
  job_requirements: 'Job requirements',
  job_responsibilities: "Job @.lower:{'singular_responsibilities'} | Job @.lower:{'plural_responsibilities'}",
  job_setting: 'Job setting | Job settings',
  job_sign_off: 'Job signoff',
  job_signoff_instructions: 'Please read your job description carefully and click submit at the bottom of the page to complete the signoff.',
  job_signoffs_description: 'Manage job description signoffs.',
  job_signoffs: 'Job signoff | Job signoffs',
  job_skill_items: 'Job skill item',
  job_skill: "Job @.lower:{'plural_skills'}",
  job_skills: 'Job skill',
  job_specific: 'Job-specific',
  job_subheadings: 'Job subheading | Job subheadings',
  job_summary_set_to_done: 'Job summary set to done',
  job_summary: 'Job summary',
  job_tab_employee_empty_msg: 'Why not add employees to this job?',
  job_template_content_guidelines: 'Job template content guideline',
  job_template_contents: 'Job template contents',
  job_template_properties: 'Job template property',
  job_template_turn_on_disabled_message: "This template can't be turned on because it doesn't have an associated job post template.",
  job_template_type: 'Type',
  job_template_updated: 'Job template updated',
  job_templates_subheadings: 'Job template subheading',
  job_templates: 'Job template | Job templates',
  job_title: 'Job title',
  job_types: 'Job types',
  job_update_controlled_subtext: 'Useful for updates requiring review or confirmation.',
  job_update_controlled_text: 'Controlled update',
  job_update_label: 'Please choose an update process',
  job_update_no_update_text: 'No update',
  job_update_silent_subtext: "Useful for updates that don't require review or confirmation.",
  job_update_silent_text: 'Silent update',
  job_updated: 'Job updated',
  job_version_number: 'Version {version}',
  job_version: 'Job version',
  job_versions: 'Job versions',
  job_working_conditions: 'Job working condition',
  jobs_archive: 'Job archive | Jobs archive',
  jobs_archived: 'Job archived | Jobs archived',
  jobs_deleted: 'Job deleted | Jobs deleted',
  jobs_digest: 'Jobs digest',
  jobs_duplicated: 'Job duplicated. {link} to access the duplicate job.',
  jobs_moved: 'Job moved | Jobs moved',
  jobs_published: 'Job published | Jobs published',
  jobs_restored: 'Job restored | Jobs restored',
  jobs: 'Job | Jobs',
  jul: 'Jul',
  jun: 'Jun',
  key_stats: 'Key stats',
  label_behavioral_indicator: 'Behavioral indicator',
  language_archived: 'Item archived | Items archived',
  language_deleted: 'Item deleted | Items deleted',
  language_en: 'Eng',
  language_fr: 'Fre',
  language_published: 'Item published | Items published',
  language_scales: 'Language scale',
  languages_archive: "Archive: @:{'plural_languages'}",
  languages_archived: 'Item archived | Items archived',
  languages_created: 'Item created | Items created',
  languages_deleted: 'Item deleted | Items deleted',
  languages_published: 'Item published | Items published',
  languages_restored: 'Item restored | Items restored',
  languages_updated: "@.capitalize:{'singular_languages'} updated | @.capitalize:{'plural_languages'} updated",
  languages: "@:{'singular_languages'} | @:{'plural_languages'}",
  last_12_months: 'Last 12 months',
  last_30_days: 'Last 30 days',
  last_6_months: 'Last 6 months',
  last_60_days: 'Last 60 days',
  last_7_days: 'Last 7 days',
  last_90_days: 'Last 90 days',
  last_imported_at: 'Last imported at',
  last_login: 'Last login',
  last_name: 'Last name',
  last_published_version: 'Last published version.',
  last_published: 'Published',
  last_pushed_at: 'Last pushed at',
  last_reset: 'Last reset',
  last_sent: 'Last sent: {date}',
  last_signed_off_version: 'Last signed off version.',
  last_signoff_date: 'Last signoff date',
  last_signoff_header_tooltip: 'Shows last signoff on the latest version of the job.',
  last_signoff: 'Last signoff',
  last_sync_at: 'Last synchronized at',
  lateral_move: 'Lateral move',
  latest_status: 'Latest status',
  launch_dates: 'Launch date | Launch dates',
  launch: 'Launch',
  launched: 'Launched',
  layout: 'Layout',
  leadership_competencies_description: 'These are added to jobs at each job level. To manage job levels, go to {jobProperties}.',
  leadership_competencies: "Leadership @.lower:{'plural_competencies'}",
  leadership: 'Leadership',
  learn_more: 'Learn more',
  learning_resources: 'Learning resource | Learning resources',
  legal: 'Legal',
  less: 'Less',
  let_participants_know_purpose_survey: 'Let participants know the purpose of this survey.',
  let_users_export_the_org_chart: 'Let users export the org chart.',
  level_analysis_competencies: "Level analysis - @:{'plural_competencies'}",
  level_analysis_skills: "Level analysis - @:{'plural_skills'}",
  level_analysis: 'Level analysis',
  level_comparison_title: '{library_name} level comparisons',
  level_comparisons: 'Level comparisons',
  level_distribution_details: 'The bars indicate the percentage breakdown of ratings of each item in the review by level. Hover over each bar to see exact participant numbers.',
  level_distribution_help: 'This chart shows the distribution of ratings for each item in the review. It helps identify areas of relative strength and weakness in the organization.',
  level_distributions: 'Level distribution',
  level_name: 'Level',
  level_summary: 'Level summary',
  level_x_examples: '{level} examples:',
  levels_updated: 'Level updated | Levels updated',
  levels: 'Level | Levels',
  libraries_certifications: "Libraries - @:{'plural_certifications'}",
  libraries_competencies: "Libraries - @:{'plural_competencies'}",
  libraries_educations: "Libraries - @:{'plural_educations'}",
  libraries_experiences: "Libraries - @:{'plural_experiences'}",
  libraries_informations: "Libraries - @:{'plural_additional_information'}",
  libraries_languages: "Libraries - @:{'plural_languages'}",
  libraries_responsibilities: "Libraries - @:{'plural_responsibilities'}",
  libraries_skills: "Libraries - @:{'plural_skills'}",
  libraries_sub_msg: 'Define library names and availability.',
  libraries_working_conditions: "Libraries - @:{'plural_working_conditions'}",
  libraries: 'Library | Libraries',
  library_archive_certifications: "Library archive - @:{'plural_certifications'}",
  library_archive_competencies: "Library archive - @:{'plural_competencies'}",
  library_archive_educations: "Library archive - @:{'plural_educations'}",
  library_archive_experiences: "Library archive - @:{'plural_experiences'}",
  library_archive_informations: "Library archive - @:{'plural_additional_information'}",
  library_archive_languages: "Library archive - @:{'plural_languages'}",
  library_archive_responsibilities: "Library archive - @:{'plural_responsibilities'}",
  library_archive_skills: "Library archive - @:{'plural_skills'}",
  library_archive_working_conditions: "Library archive - @:{'plural_working_conditions'}",
  library_competency_questions: 'Libraries - Competency question | Libraries - Competency questions',
  library_group_certifications: "Library group - @:{'plural_certifications'}",
  library_group_competencies: "Library group - @:{'plural_competencies'}",
  library_group_educations: "Library group - @:{'plural_educations'}",
  library_group_informations: "Library group - @:{'plural_additional_information'}",
  library_group_responsibilities: "Library group - @:{'plural_responsibilities'}",
  library_group_skills: "Library group - @:{'plural_skills'}",
  library_highlighter: 'Library highlighter',
  library_items: 'Library item',
  library_requirements: '{library} requirements',
  library_topic_questions: 'Libraries - Topic question | Libraries - Topic questions',
  library_turn_off_msg: 'This library will no longer be available and data will be deleted from affected jobs.',
  library_turn_on_msg: 'To use this library in job descriptions, add it to job templates.',
  license_type_administrator: 'admin',
  license_type_interviews: 'Interviews {license_type}',
  license_type_job: "@:{'plural_jobs'} {license_type}",
  license_type_platform: 'Platform {license_type}',
  license_type_regular: 'user',
  license_type_reviews: 'Reviews user',
  license_type: 'License type',
  licenses_interviews: 'Interviews administrators',
  licenses_jobs: 'Jobs administrators',
  licenses_platform: 'Platform users',
  licenses_reviews: 'Reviews users',
  licenses: 'Licenses',
  likes: 'Like | Likes',
  link_reset_password_sent_email: 'A link to reset your password has been sent to you, provided that the email address matches your account.',
  link_texts: 'Link text',
  linked: 'Linked',
  linkedin_profile: 'LinkedIn profile',
  linkedin: 'LinkedIn',
  live_review_modal_change_job_x_text_1: 'Any submitted data will be permanently deleted.',
  live_review_modal_change_job_x_text_2: 'All participants assessing {subjectName} will receive a new invitation and task so they can assess {subjectName} on the new job.',
  live_review_modal_change_manager_x_text_1: 'Any data submitted by a manager that is removed from the review will be permanently deleted.',
  live_review_modal_change_manager_x_text_2: 'A new manager will receive an invitation and task.',
  live_survey_contents_warning: 'This survey is live. Removing content will delete any associated data from the survey results.',
  live_survey_participants_warning: 'This survey is live. Removing participants will delete any associated data from the survey results.',
  live: 'Live',
  loading_matches: 'loading matches...',
  location_added: 'Location added',
  location_deleted: 'Location deleted',
  location_table_desc: 'Locations can be used for users and jobs.',
  locations_will_be_created: '{count} location will be created | {count} locations will be created',
  locations: 'Location | Locations',
  log_in_with_sso: 'Log in with SSO',
  log_in: 'Log in',
  logged_in: 'Logged in',
  login_account_suspended_message: 'Your account has been suspended for excessive attempts. Please contact your site administrator for access.',
  login_as_user: 'Log in as user',
  login_attempt_message: 'Due to multiple failed login attempts, please wait for <span class="text-link font-semibold">{time}</span> {unit} before trying again.',
  login_error_enforce_sso_login_msg: 'Your login credentials are incorrect or you should log in using SSO. Please try again.',
  login_onetime_access_email_sent_msg_1: "We've sent a temporary login link.",
  login_onetime_access_email_sent_msg_2: 'Please check your inbox at',
  login_statement: 'By logging in, you acknowledge that you have read and understood, and agree to our {terms} and {privacy_policy}.',
  login_type_msg: 'Turn off all options to allow access via email and password without 2FA.',
  login_type_otp: 'One-time access',
  login_type_sso: 'Single Sign-on',
  login_type_upw: 'Email and password',
  login_type: 'Login type',
  logo: 'Logo',
  logout: 'Log out',
  low: 'Low',
  lowest_first: 'Lowest first',
  made_hiring_manager: 'Made hiring manager',
  made_interviewer: 'Made interviewer',
  made_recruiter: 'Made recruiter',
  main_content: 'Main content',
  make_default: 'Make default',
  make_equivalent: 'Make equivalent',
  make_longer: 'Make longer',
  make_shorter: 'Make shorter',
  make_subheading: 'Turn into subheading',
  make_this_range_recommendation_detail: 'Jobs can still be published if the number of items is outside the ideal range.',
  make_this_range_recommendation: 'Make this range a recommendation',
  make_this_range_requirement_detail: "Jobs can't be published if the number of items is outside the ideal range.",
  make_this_range_requirement: 'Make this range a requirement',
  make_this_the_target: 'Make this the target',
  manage_candidates: 'Manage candidates',
  manage_communications: 'Manage communications',
  manage_contents: 'Add content',
  manage_departments: 'Manage departments',
  manage_display: 'Manage display',
  manage_groups: 'Manage groups',
  manage_interviewers: 'Manage interviewers',
  manage_jobs_apply_updates: 'You need the content administrator permission to apply some updates.',
  manage_libraries_apply_updates: 'You need the content administrator permission to apply these updates.',
  manage_notification_recipients: 'Manage notification recipients',
  manage_participants: 'Add participants',
  manage_properties_description: 'Manage properties that show in the job information section and what shows in PDF/Word exports',
  manage_properties: 'Manage properties',
  manage_result_reviewers: 'Manage result reviewers',
  manage_review_administrators: 'Manage review administrators',
  manage_reviews: 'Manage reviews',
  manage_rounds: 'Manage rounds',
  manage_setup: 'Manage setup',
  manage_templates: 'Manage templates',
  manage_topics: 'Manage topics',
  manage_user_groups: 'Manage user groups',
  manage_your_quinto_account: 'Manage your Quinto account.',
  manage: 'Manage',
  manager_assigned: 'Manager assigned',
  manager_completion: 'Manager completion',
  manager_email: 'Manager email',
  manager_or_hr_capability_match_percentage_of_job: '{subject} meets or exceeds {percentage} of the job requirements for a {job}',
  manager_review_communicated_deadline: 'This is the deadline given to managers but they can still provide their reviews after this date.',
  manager_review_description: 'Managers assess their direct reports.',
  manager_review_feedback_visibility_description: 'Managers will be able to see feedback and / or self reflections provided by others when providing their own reviews.',
  manager_review_launch_date: 'Managers will receive a notification on this date prompting them to provide their reviews.',
  manager_review_subtitle: 'Provide review',
  manager_review: 'Manager review',
  manager_reviews: 'Manager review | Manager reviews',
  manager_scoring_calculations: 'Scores {between} indicate managers that, on average, rated employees as performing {atAboveBelow} job requirements.',
  manager_scoring_details: 'For details on how scores are calculated {seeHere}.',
  manager_scoring_help: 'This chart shows the average score submitted by each manager in the review. It helps identify managers who might be relatively lenient or severe when providing ratings.',
  manager_scoring: 'Manager scoring',
  managers: 'Manager | Managers',
  mandatory_comments: 'Mandatory comments',
  mandatory_questions: 'Mandatory questions',
  manifest_not_found_msg: 'Sorry, something is wrong with your subdomain.',
  manifest_not_found: 'Manifest not found.',
  manual_login: 'Manual login',
  manual: 'Manual',
  map_data_import_file_quinto: 'Map the data into your import file to data in Quinto',
  map_data_in_file: 'We need to map the data in this file.',
  map_data_using_positions: "Map this data if you're using positions.",
  map_data: 'Map data',
  mar: 'Mar',
  mark_all_as_read_all: 'Mark all as read',
  mark_as_done: 'Mark as done',
  mark_as_read: 'Mark as read',
  mark_as_reviewed_by: 'Marked as reviewed by {name}',
  mark_as_reviewed_confirmation_message: 'This will mark the job as reviewed and set the next review date to {date}.',
  mark_as_reviewed_confirmation_title: 'Mark as reviewed',
  mark_as_unread: 'Mark as unread',
  matches: 'Matches',
  max: 'Max.',
  may: 'May',
  mediocre: 'Mediocre',
  meet_or_exceed_percentage_of_job: 'You meet or exceed <span class="text-orange-2">{percentage}%</span> of the job requirements for a {job}',
  meet_requirements: 'Meet requirements',
  meeting_types: 'Meeting type',
  mention_instructions: "Type {'@'} to mention and notify someone.",
  mentions: 'Mention | Mentions',
  menu: 'Menu',
  messages: 'Message',
  microsoft: 'Microsoft',
  min: 'Min.',
  mobile_number: 'Mobile number',
  modal_change_job_x_text_1: 'This assessment will use content from the new job.',
  modal_change_job_x_text_2: 'This assessment will no longer automatically update if changes are made to {subjectName} user record.',
  modal_change_job_x_title: 'If you change {name} job:',
  modal_change_manager_x_text_1: 'Only the managers selected here will assess {subjectName}.',
  modal_change_manager_x_text_2: 'The participants assessing {subjectName1} will no longer automatically update if changes are made to {subjectName2} user record.',
  modal_change_manger_x_title: 'If you change {name} manager',
  modules: 'Module',
  more_than_12_months: 'More than 12 months',
  more: 'More',
  most_senior_first: 'Most senior first',
  move_additional_information: "Move @.lower:{'additional_information'}",
  move_certifications: "Move @.lower:{'certifications'}",
  move_competencies: "Move @.lower:{'competencies'}",
  move_educations: "Move @.lower:{'educations'}",
  move_experiences: "Move @.lower:{'experiences'}",
  move_forward: 'Move forward',
  move_interview_templates: 'Move interview template | Move interview templates',
  move_items_to_another_group: 'Move items to another group',
  move_jobs_message: 'When you move jobs to a new group, some data may be affected.',
  move_jobs_sub_message_1: 'Job-family competency levels will be deleted',
  move_jobs_sub_message_2: 'Job-family competencies may change',
  move_jobs_sub_message_3: 'Job-group owners may change',
  move_jobs: 'Move job | Move jobs',
  move_languages: "Move @.lower:{'languages'}",
  move_move_certifications: "Move @.lower:{'certifications'}",
  move_responsibilities: "Move @.lower:{'responsibilities'}",
  move_responsibility: "Move @.lower:{'responsibilities'}",
  move_skill: "Move @.lower:{'skills'}",
  move_to_next_rounds: 'Move to next round',
  move_to_this_round: 'Move to this round',
  move_types: 'Move type',
  move_working_conditions: "Move @.lower:{'working_conditions'}",
  move: 'Move',
  moved_forward: 'Moved forward',
  moved_laterally: 'Moved laterally',
  multi_select: 'Multi-select',
  my_jobs: 'My jobs',
  my_profile_tooltip: 'Preferences and job details',
  my_profile: 'My profile',
  my_reviews: 'My reviews',
  my_team_empty_text_1: "There's no one here yet.",
  my_team_empty_text_2: "We're hard at work.",
  my_team_empty_text_3: 'Please check back later.',
  my_team: 'My team',
  na: 'N/A',
  name_teams: "{name}'s team",
  name: 'Name',
  needs_more_requirements: "Needs more {library_type} @.lower:{'plural_requirement'}",
  needs_more: 'Needs more {library_type}',
  needs_work: 'Needs work',
  never_published: 'Never published',
  never: 'Never',
  new_department: 'Change department',
  new_level: 'New level',
  new_password: 'New password',
  new_user_status: 'New user status',
  new: 'New',
  next_30_days: 'Next 30 days',
  next_month: 'Next month',
  next_week: 'Next week',
  next: 'Next',
  no_assigned_job_tooltip: '{subjectName} does not have an assigned job.',
  no_ats_integration: 'No ATS integration',
  no_candidates_yet: 'No candidates yet',
  no_data_deleted_associated_data_not_visible: 'No data will be deleted, but associated data will not be visible on each job.',
  no_date_set: 'No date set',
  no_details_to_show: 'No details to show',
  no_due_data_provided: 'no due date has been provided',
  no_due_date_has_been_provided: 'no due date has been provided',
  no_due_date_provided: 'no due date provided',
  no_follow_ups: 'No follow-up',
  no_guidelines_specified: 'There are no guidelines specified',
  no_hiring_team_member: 'No hiring team member',
  no_incumbent_jobs: "This job doesn't currently have any incumbents. | These jobs don't currently have any incumbents.",
  no_interview_introduction: 'No interview introduction has been defined.',
  no_interview_questions: 'No interview questions',
  no_interviewers: 'No interviewers',
  no_jobs_have_been_published: 'No jobs have been published yet.',
  no_jobs_published: "The job was not published because it didn't meet consistency requirements. | None of the jobs were published because they didn't meet consistency requirements.",
  no_levels: 'No level',
  no_location_msg: 'No location has been set.',
  no_locations: 'No location',
  no_manager_tooltip: '{subjectName} does not have an assigned manager.',
  no_manager: 'No Manager',
  no_match_found: 'Sorry! No matches found.',
  no_one_yet_msg: "There's no one here yet.",
  no_pending_job_updates_message: "Everything's up to date! There aren't any pending updates.",
  no_position_defined: 'No position defined',
  no_positions: 'No position | No positions',
  no_questions_in_guide: 'There are no questions in this template',
  no_ranges: 'No range',
  no_results_yet: "There aren't any results just yet.",
  no_reviews_available_yet: 'No reviews available yet.',
  no_scorecards: 'No scorecard',
  no_search_results: 'No search results',
  no_signoffs_for_this_job: "There aren't any signoffs for this job.",
  no_trusted_devices_msg: "You don't have any trusted devices. You can mark a device as trusted when you log in.",
  no_types: 'No type',
  no_updates_were_found: 'No updates were found',
  no: 'No',
  none: 'None',
  not_added: 'Not added',
  not_answered: 'Not answered',
  not_applicable: 'Not applicable',
  not_available_with_2fa: 'Not available with 2FA',
  not_available_with_otp: 'Not available with one-time access',
  not_available_with_sso: 'Not available with SSO',
  not_completed: 'Not completed',
  not_flagged: 'Not flagged',
  not_invited: 'Not invited',
  not_moved_to_this_round: 'Not moved to this round',
  not_picked: 'Not picked',
  not_selected_by_others: 'Not selected by others',
  not_starred: 'Not starred',
  not_translated: 'Note: Not all content has been translated.',
  note_changes_are_not_retroactive: 'Note: Changes are not retroactive. They only affect jobs signed off in the future.',
  note_you_can_override_default: 'Note: You can override the default when sending requests.',
  notes: 'Note | Notes',
  nothing_to_see_here: 'Nothing to see here',
  nothing_to_show: 'Nothing to show',
  nothing: 'Nothing',
  notification_AllScorecardsSubmitted: 'All of the interviewers have submitted their scorecard for a candidate',
  notification_closed_a_job_to_feedback: '{name} closed a job to feedback',
  notification_empty_message: "You're all up-to-date",
  notification_InterviewCandidacyComment: '{name} commented on a candidate',
  notification_job_collaboration: '{job}',
  notification_job_incumbents: 'Job: {job}',
  notification_JobArchived: '{name} has archived your job',
  notification_JobComment: '{name} commented on a job',
  notification_JobCommentMention: '{name} mentioned you in a comment',
  notification_JobContributorTypeChange: '{name} changed your role',
  notification_JobDeleted: '{name} has deleted your job',
  notification_JobFeedbackProvidedToContributorInThread: '{name} replied to your feedback',
  notification_JobFeedbackProvidedToOwner: '{name} provided feedback on a job',
  notification_JobPublished: '{name} published a job',
  notification_JobPublishedToIncumbent: '{name} has published your job',
  notification_re_opened_a_job_for_feedback: '{name} re-opened a job for feedback',
  notification_recipients: 'notification recipient | notification recipients',
  notifications: 'Notifications',
  notify_incumbents: 'Notify incumbents',
  nov: 'Nov',
  nudge_interviewers: 'Nudge interviewers',
  nudge: 'Nudge',
  nudged: 'Nudged',
  oct: 'Oct',
  of: 'Of',
  off: 'Off',
  ok: 'Ok',
  okta: 'Okta',
  on_leave: 'On leave',
  on: 'On',
  onboarding: 'Onboarding',
  one_lowercase_character: 'One lowercase character',
  one_number_or_special_character: 'One number or special character',
  one_number: 'One number',
  one_question_per_page: 'One question per page (with space for notes)',
  one_special_character: 'One special character',
  one_time_access_description: 'Enable users to access Quinto with secure one-time links instead of a password.',
  one_time_access_link_already_sent: 'A temporary login link has already been sent. Please check your inbox.',
  one_time_access_link_expired: 'Sorry, that link is no longer valid. Please try again.',
  one_time_access: 'One-time access',
  one_uppercase_character: 'One uppercase character',
  only_available_for_login_type_upw: 'Only available for email and password login type',
  only_draft_will_be_published: 'Only draft items will be published.',
  only_published_will_be_archived: 'Only published items will be archived.',
  only_users_with_position_action: 'This action will only apply to users that have a position.',
  onscreen_instructions_for_managers: 'Onscreen instructions for managers',
  onscreen_instructions_for_subjects: 'Onscreen instructions for subjects',
  open_feedback_message: 'Editors and reviewers will be able to provide input.',
  open_feedback: 'Open feedback',
  open_scorecards: 'Open scorecard',
  open: 'Open',
  opened_for_input: 'Opened for input',
  opening_candidates: 'Openings - Candidates',
  openings_compare: 'Opening - Compare',
  openings: 'Opening | Openings',
  optional_comments: 'Optional comments',
  optional_questions: 'Optional questions',
  optional: 'Optional',
  options: 'Option | Options',
  or_drag_and_drop: 'or drag and drop here',
  or_login_with_email: 'Or log in with email',
  or: 'or',
  org_chart_empty_message_sub: "You don't have a position assigned to you.\n Use the search above to find others.",
  org_chart_empty_message: 'Nothing to show.',
  org_chart: 'Org chart',
  organization: 'Organization',
  other: 'Other',
  others: 'Other | Others',
  outcomes: 'Outcome',
  outstanding: 'Outstanding',
  overall_feedback_question_one: 'Do you recommend moving forward with this candidate?',
  overall_feedback_question_two: 'Is this candidate a good fit for the role?',
  overall_feedback: 'Overall feedback',
  overall: 'Overall',
  overview: 'Overview',
  owners_description: 'Owners can do everything editors can do. They can also invite collaborators, manage job settings, and publish the job.',
  owners: 'Owner | Owners',
  page_content: 'Page content',
  page_not_authorized: 'Page not authorized.',
  page_not_found: 'Page not found.',
  page_refresh: 'Please refresh the page',
  page_title: 'Page title',
  pages: 'Pages',
  parent_department: 'Parent department',
  parent_group: 'Parent group',
  participants_completion: 'Participant completion | Participants completion',
  participants: 'Participant | Participants',
  password_change_msg: 'An email has been sent to {email} if that address matches your account.',
  password_leaked: 'The password you entered has appeared in a data leak. Please choose a different password.',
  password_not_allowed_characters: 'The lesser than and greater than symbols together (<>) are not allowed.',
  password_previously_used: 'Password previously used. Please choose a different password.',
  password_reset_sent: 'Password reset sent | Password resets sent',
  password: 'Password',
  paths: 'Paths',
  pdf: 'PDF',
  peer: 'Peer',
  pending_discussion: 'Pending Discussion',
  pending_job_updates_descripton: "When you're ready, apply these changes to update any published jobs that aren't being edited.",
  pending_job_updates: 'Pending job updates',
  pending_results: 'Pending results',
  pending: 'Pending',
  people_activities: 'People activities',
  people: 'People',
  per_competency: 'Per competency',
  per_page: 'per page',
  percent_complete: 'Percent complete: {percent}%',
  performance_scores_calculations: 'Scores {between} indicate employees rated as performing {atAboveBelow} job requirements',
  performance_scores_details: 'For details on how scores are calculated {seeHere}.',
  performance_scores_help: 'This chart shows the performance score for each subject in the review. It helps identify how employees are performing relative to the requirements defined in job descriptions.',
  performance_scores: 'Performance score | Performance scores',
  performed_by: 'Performed by',
  permision_sets_table_description: 'Define permission sets to control how your organization accesses Quinto.',
  permission_set_access_org_chart_text: 'Let users view the org chart.',
  permission_set_access_org_chart_title: 'Access org chart',
  permission_set_can_not_delete_message: "This permission set can't be deleted because it is the default.",
  permission_set_group_access_org: 'Org chart',
  permission_set_group_access_reviews: 'Reviews',
  permission_set_groups_access_analytics_text: 'Let users view job analytics.',
  permission_set_groups_access_analytics_title: 'Access analytics',
  permission_set_groups_access_audit_logs_text: 'Let users access audit logs.',
  permission_set_groups_access_audit_logs_title: 'Access audit logs',
  permission_set_groups_access_interview_templates_text: 'Let users view and download interview templates.',
  permission_set_groups_access_interview_templatess_title: 'Access interview templates',
  permission_set_groups_access_jobs_text: 'Let users view published job descriptions in the Jobs module.',
  permission_set_groups_access_jobs_title: 'Access jobs',
  permission_set_groups_access_libraries_text: "Let users view the bank of published job requirements (e.g., @.lower:{'plural_competencies'} and @.lower:{'plural_responsibilities'}).",
  permission_set_groups_access_libraries_title: 'Access job-requirement libraries',
  permission_set_groups_access_reviews_text: 'Let users participate in reviews.',
  permission_set_groups_access_reviews_title: 'Access reviews',
  permission_set_groups_access_surveys_text: 'Let users participate in surveys.',
  permission_set_groups_access_surveys_title: 'Access surveys',
  permission_set_groups_account_administration: 'Site administration',
  permission_set_groups_account_administrator_text: 'Let users set your account settings and dashboard.',
  permission_set_groups_account_administrator_title: 'Manage account',
  permission_set_groups_communications_administrator_text: 'Let users edit system content, such as document introductions and the job signoff disclaimer.',
  permission_set_groups_communications_administrator_title: 'Manage communications',
  permission_set_groups_content_administration: 'Content administration',
  permission_set_groups_content_administrator_text: 'Let users manage all the content in your site, including architecture, content defaults, job properties, job templates, and scales.',
  permission_set_groups_content_administrator_title: 'Content administrator',
  permission_set_groups_delete_published_jobs_text: 'Let users delete published jobs.',
  permission_set_groups_delete_published_jobs_title: 'Delete published jobs',
  permission_set_groups_delete_published_library_items_text: 'Let users delete published library items.',
  permission_set_groups_delete_published_library_items_title: 'Delete published library items',
  permission_set_groups_development_administration: 'Development administration',
  permission_set_groups_export_jobs_text: 'Let users export jobs to Word and PDF.',
  permission_set_groups_export_jobs_title: 'Export jobs',
  permission_set_groups_export_libraries_text: 'Let users export competencies to Word and PDF.',
  permission_set_groups_export_libraries_title: 'Export competencies',
  permission_set_groups_jobs_collaboration_text: 'Let users be invited to collaborate on jobs as an editor or reviewer.',
  permission_set_groups_jobs_collaboration_title: 'Collaborate on jobs',
  permission_set_groups_manage_interview_guides_text: 'Let users create and manage interview openings.',
  permission_set_groups_manage_interview_guides_title: 'Manage interviews',
  permission_set_groups_manage_interview_questions_text: 'Let users create and manage the bank of interview questions.',
  permission_set_groups_manage_interview_questions_title: 'Manage interview questions',
  permission_set_groups_manage_job_validations_text: 'Let users send jobs to other users for their input and feedback. (Grants access to see job validations in content activities)',
  permission_set_groups_manage_job_validations_title: 'Manage job validations',
  permission_set_groups_manage_jobs_text: 'Let users create and manage job descriptions.',
  permission_set_groups_manage_jobs_title: 'Manage jobs',
  permission_set_groups_manage_libraries_text: "Let users create and manage the bank of job requirements (e.g., @.lower:{'plural_competencies'} and @.lower:{'plural_responsibilities'}).",
  permission_set_groups_manage_libraries_title: 'Manage job-requirement libraries',
  permission_set_groups_manage_locations_text: 'Let users manage user and job locations.',
  permission_set_groups_manage_locations_title: 'Manage locations',
  permission_set_groups_manage_my_account_text: 'Let users manage their own account settings.',
  permission_set_groups_manage_my_account_title: 'Manage my account',
  permission_set_groups_manage_people_text: 'Let users access the My Team page to see data related to their direct and indirect reports.',
  permission_set_groups_manage_people_title: 'People manager',
  permission_set_groups_manage_reviews_text: 'Let users create and manage reviews.',
  permission_set_groups_manage_reviews_title: 'Manage reviews',
  permission_set_groups_manage_signoffs_text: 'Let users manage job signoffs.',
  permission_set_groups_manage_signoffs_title: 'Manage signoffs',
  permission_set_groups_manage_surveys_text: 'Let users create and manage surveys.',
  permission_set_groups_manage_surveys_title: 'Manage surveys',
  permission_set_groups_organizational_development: 'Organizational development',
  permission_set_groups_own_jobs_text: 'Let users collaborate on jobs as an owner, editor, or reviewer.',
  permission_set_groups_own_jobs_title: 'Own jobs',
  permission_set_groups_permissions_administrator_text: 'Let users add or edit the permission sets available in your site.',
  permission_set_groups_permissions_administrator_title: 'Permissions administrator',
  permission_set_groups_personal_development: 'Personal development',
  permission_set_groups_personify_text: 'Let users log in as any other user (for support purposes).',
  permission_set_groups_personify_title: 'Log in as other users',
  permission_set_groups_push_job_post_text: 'Let users send job posts to a selected ATS via API.',
  permission_set_groups_push_job_post_title: 'Push job posts',
  permission_set_groups_team_development: 'Team development',
  permission_set_groups_users_administration: 'Users administration',
  permission_set_groups_users_administrator_text: 'Let users add or edit other users, positions, and departments as well as anonymize deleted users.',
  permission_set_groups_users_administrator_title: 'Users administrator',
  permission_set_groups_view_help_text: 'Let users see the help widget.',
  permission_set_groups_view_help_title: 'View help',
  permission_set_updated_by: 'Updated by {name}',
  permission_sets_assigned: 'Permission sets assigned',
  permission_sets_created: 'Permission set created',
  permission_sets_deleted: 'Permission set deleted',
  permission_sets_unassigned: 'Permission sets removed',
  permission_sets_updated: 'Permission set updated',
  permission_sets: 'Permission set | Permission sets',
  personal_account: 'Personal account',
  personal_weblink: 'Personal weblink',
  personal: 'Personal',
  personified: 'Personified',
  phone_number: 'Phone number',
  phone: 'Phone',
  pick_from_here: 'Pick from here',
  pipeline: 'Pipeline',
  placeholders: 'Placeholder | Placeholders',
  please_select_one: 'Please select at least one option',
  please_use_csv_or_xlsx: 'Please use .csv or .xlsx',
  please_use_future_dates: 'Please use future dates',
  plural_additional_information: 'Additional information',
  plural_certifications: 'Certifications',
  plural_competencies: 'Competencies',
  plural_educations: 'Education',
  plural_experiences: 'Experience',
  plural_interview_questions: 'Interview questions',
  plural_jobs: 'Jobs',
  plural_languages: 'Languages',
  plural_learning_resources: 'Learning resources',
  plural_requirement: 'Requirements',
  plural_responsibilities: 'Responsibilities',
  plural_skills: 'Skills',
  plural_working_conditions: 'Working conditions',
  plural: 'Plural',
  png_jpg_svg_up_to_10mb: 'PNG, JPG, SVG up to 10MB',
  png_jpg_svg_up_to_5mb: 'PNG, JPG, SVG up to 5MB',
  position_description: 'Entries below show the positions history for {name}.',
  position_details: 'Position details',
  position_exception: 'Position exception',
  position_history: 'Position history',
  position_id: 'Position ID',
  position_updates_effective_date_msg: 'Position updates occur immediately. Effective dates are used in user history and notifications.',
  position_without_jobs: 'Positions without jobs',
  positions_deleted: 'Position deleted | Positions deleted',
  positions_will_be_created: '{count} position will be created | {count} positions will be created',
  positions: 'Position | Positions',
  post_template_can_not_delete: "This template can't be deleted because it is in use.",
  post_template_can_not_turned_off: "This template can't be turned off because it is in use.",
  preferences: 'Preferences',
  preview: 'Preview',
  previous_published_version: 'Previous published version',
  previous_published: 'Previous published',
  previous: 'Previous',
  privacy_policy: 'Privacy Policy',
  procedure: 'Procedure',
  process_job_updates_standby_message: "Standby...we're processing job updates.",
  product_name: 'Product name',
  profile_email_notifications: 'Profile - Email notifications',
  profile_integrations: 'Profile integrations',
  profile_job_description: 'Profile - Job description',
  profile_job_signoffs: 'Profile - Job signoffs',
  profile_password_reset: "We've sent an email to {email} with a link to reset your password.",
  profile_picture: 'Profile Picture',
  profile_preferences: 'Profile - Preferences',
  profile_settings: 'Profile - Settings',
  profile_signoffs_description: 'View your job signoff history.',
  profile: 'Profile',
  progress_updated: 'Progress updated',
  progress: 'Progress',
  promoted: 'Promoted',
  properties: 'Property | Properties',
  property_use_warning: 'To use this property on job descriptions, go to the Information section in a job template and turn it on.',
  provide_a_process_for_notifying_incumbents: 'Provides a process for notifying incumbents.',
  provide_feedback: 'Provide feedback',
  providers: 'Provider',
  publish_additional_information_msg: 'This item will be published. | These items will be published.',
  publish_additional_information: "Publish @.lower:{'additional_information'}?",
  publish_certifications_msg: 'This item will be published. | These items will be published.',
  publish_certifications: "Publish @.lower:{'certifications'}?",
  publish_competencies_msg: 'This item will be published. | These items will be published.',
  publish_competencies: "Publish @.lower:{'competencies'}?",
  publish_competency: "Publish @.lower:{'competencies'}?",
  publish_educations_msg: 'This item will be published. | These items will be published.',
  publish_educations: "Publish @.lower:{'educations'}?",
  publish_experiences_msg: 'This item will be published. | These items will be published.',
  publish_experiences: "Publish @.lower:{'experiences'}?",
  publish_jobs_msg: 'This job will be published. | These jobs will be published.',
  publish_jobs: 'Publish job | Publish jobs',
  publish_languages_msg: 'This item will be published. | These items will be published.',
  publish_languages: "Publish @.lower:{'languages'}?",
  publish_responsibilities_msg: 'This item will be published. | These items will be published.',
  publish_responsibilities: "Publish @.lower:{'responsibilities'}?",
  publish_responsibility_msg: 'This item will be published. | These items will be published.',
  publish_responsibility: "Publish @.lower:{'responsibilities'}?",
  publish_skill_msg: 'This item will be published. | These items will be published.',
  publish_skill: "Publish @.lower:{'skills'}?",
  publish_working_conditions_msg: 'This item will be published. | These items will be published.',
  publish_working_conditions: "Publish @.lower:{'working_conditions'}?",
  publish: 'Publish',
  published_by_name: 'Published by {name}',
  published_date: 'Published: {date}',
  published_interview_guide: 'Published interview template',
  published_job_rate_tooltip: 'The percentage of jobs that are currently published.',
  published_job_rate: 'Published rate',
  published_job_thanks_msg: 'Thanks for your time and input!',
  published_job: 'Published job',
  published_jobs_x: 'Published jobs: {count}',
  published_plus_draft: 'Published + Draft',
  published_with_date: 'Published: {date}',
  published_with_draft_copy: 'Published with draft',
  published: 'Published',
  question_not_translated: 'This question has not been translated.',
  question_not_used_on_job: 'This question has not been used for this job.',
  question_number: 'Question {number} ',
  question_types: 'Question type',
  questionnaire_results: 'Questionnaire results',
  questionnaire_submitted_banner_2: "This is a read-only view of {name}'s assessment.",
  questionnaire_submitted_banner: "Your assessment has been submitted and can't be updated.",
  questionnaires: 'Questionnaire | Questionnaires',
  questions_added_from_template: 'Questions added from {template} template',
  questions_are_x: 'Questions are {x}',
  questions_content: 'Questions/Content',
  questions: 'Question | Questions',
  quick_start: 'Quick start',
  quinto_admin_home: 'Quinto - Admin home',
  quinto_data: 'Quinto data',
  quinto_home: 'Quinto - Home',
  quinto_login: 'Quinto - Login',
  quinto_version: 'Quinto version',
  ranges: 'Range',
  rating_factors: 'Rating factors',
  rating_help: 'Rating help',
  rating_na_option_excluded: 'N/A option excluded',
  rating_na_option_included: 'N/A option included',
  rating_scale_info: 'Rating scale info',
  ratings_by: 'Ratings by',
  ratings_distribution: 'Ratings distribution',
  ratings_scale: 'Rating scale | Ratings scale',
  ratings_submitted: 'Your ratings have been submitted',
  ratings_visibility_description: 'Allow interviewers to see all ratings after submitting their scorecard.',
  ratings_visibility: 'Ratings visibility',
  ratings_x_an_na_option: 'Ratings {x} an N/A option. {learn_more_link}',
  ratings: 'Rating | Ratings',
  re_send: 'Re-send',
  reacted: 'Reacted',
  ready_to_acknowledge: 'Ready to acknowledge',
  ready_to_share: 'Ready to share',
  reason_for_granting_signoff: 'Reason for granting signoff',
  reason: 'Reason',
  reassigned_ownership_access: 'Reassigned ownership access',
  recent_updates: 'Recent updates',
  recently_viewed: 'Recently viewed',
  recipients: 'Recipients',
  recommend: 'Recommend',
  recruiters: 'Recruiter',
  redo: 'Redo Ctrl + Shift + Z',
  reference_contents: 'Reference content',
  refresh_data: 'Refresh data',
  refresh_latest_version: 'Refresh to get the latest version.',
  refresh_table: 'Refresh table',
  regular_option_description: 'Includes permissions for limited Quinto access.',
  regular_users: 'Regular user | Regular users',
  regular: 'Regular',
  reinvited: 'Reinvited',
  reminded: 'Reminded',
  reminder_sent: 'Reminder sent',
  reminders_sent: 'Reminders sent',
  remove_all_permission_sets: 'Remove all currently assigned permission sets',
  remove_candidates_confirmation_message: "{name} will be removed from this round and any relevant interview data will be permanently deleted. If an interview has been scheduled, we'll  let the interviewers know it has been cancelled.",
  remove_candidates_confirmation_title: 'Remove candidate?',
  remove_candidates: 'Remove candidate',
  remove_collaborator_description: "The collaborator will no longer be able to access this job (unless they have manage jobs permissions). We'll keep their contributions.",
  remove_collaborator: 'Remove collaborator',
  remove_competencies: "Remove @.lower:{'competencies'}?",
  remove_contents_confirmation_message: "Any associated data will be permanently deleted. You can't undo this action.",
  remove_contents: 'Remove content?',
  remove_core_competencies_confirmation_message: 'It will be removed from every job in your site.',
  remove_department: 'Remove department',
  remove_direct_reports: 'Remove direct report',
  remove_employee_dialog_msg: 'This employee will no longer be linked to the job. They will remain in their current position.',
  remove_employee: 'Remove employee?',
  remove_flags: 'Remove flag',
  remove_highlight: 'Remove highlighting',
  remove_incumbent: 'Remove incumbent',
  remove_interviewers_confirmation: '{name} will be removed from this interview. Any ratings they have submitted for this interview will be permanently deleted.',
  remove_interviewers: 'Remove interviewer?',
  remove_item_to_library: 'Remove {number} {library_type}',
  remove_job_family_competencies_confirmation_message: 'It will be removed from all jobs in this group.',
  remove_job: 'Remove job',
  remove_jobs_position: 'This job will be removed from the position.',
  remove_jobs: 'Remove job',
  remove_leadership_competencies_confirmation_message: 'It will be removed from all jobs at this level.',
  remove_logo_confirmation: "You can't undo this action. The logo will be permanently deleted.",
  remove_logo: 'Remove logo?',
  remove_manager: 'Remove manager',
  remove_min_max_item_to_library: 'Remove {min} to {max} {library_type}',
  remove_opening_interviewers_confirmation: '{name} will be removed from the hiring team and any interviews they were added to. Any ratings they have submitted will be permanently deleted',
  remove_owner_confirmation: 'This user will no longer be an owner of the job group.',
  remove_owner: 'Remove owner?',
  remove_participant_dialog_message: "You can't undo this action. Removing {participantName} from this assessment will delete any data they have submitted.",
  remove_participant: 'Remove participant',
  remove_participants_confirmation_message: "Any data provided by the participant will be permanently deleted. You can't undo this action.",
  remove_participants: 'Remove participant?',
  remove_photo: 'Remove photo',
  remove_position_msg: 'This position will be removed from the history.',
  remove_positions: 'Remove position',
  remove_rounds: 'Remove round',
  remove_stars: 'Remove star',
  remove_types_message: 'Remove all other currently assigned job types.',
  remove: 'Remove',
  removed_all_permission_sets: 'Permission set removed',
  removed_from_guides: 'Removed from template',
  removed_jobs: 'Removed job',
  removed_owners: 'Removed owners',
  removed_ownership_access: 'Removed ownership access',
  removed_reactions: 'Removed reaction',
  removed_tags: 'Removed tag',
  removed: 'Removed',
  rename_communications: 'Rename communication | Rename communications',
  rename_groups: 'Rename group',
  rename_libraries: 'Rename library',
  rename_library_refresh: 'To see the updated library name, please refresh your browser after saving.',
  rename_name: 'Rename {name}',
  rename_permission_set: 'Rename permission set',
  rename_position: 'Edit position',
  rename_property_msg: 'This property will be updated in all job templates and jobs.',
  rename_property: 'Rename property',
  rename_surveys: 'Rename survey',
  rename: 'Rename',
  reopen_openings: 'Reopen opening',
  reopen_participant_survey_confirmation_message: "Allow this participant to update their responses. This will remove their responses and comments from the results until they complete the survey again. We'll email the participant to let them know they can access the survey again.",
  reopen_participant_survey: 'Reopen survey for this participant',
  reopen_scorecards: 'Reopen scorecard',
  reopen_survey_confirmation_message: "Participants who have not yet completed the survey will regain access. We'll email anyone who hasn't yet provided input to let them know.",
  reopen_survey: 'Reopen survey',
  reopen: 'Reopen',
  reopened_scorecard: 'Reopened scorecard',
  reopened_survey_for_participant: 'Reopened survey for participant',
  reopened: 'Reopened',
  reordered: 'Reordered',
  replace_with_this_job: 'Replace with this job',
  reply: 'Reply',
  reports_to: 'Reports to',
  request_additional_review_message: 'Are you sure you want to request additional review?',
  request_additional_review: 'Request additional review',
  request_cancelled_by: 'Request cancelled by {name}',
  request_cancelled: 'Request cancelled',
  request_signoff_message: 'A signoff request will be sent to {name}. | A signoff request will be sent to selected employees.',
  request_signoff_title: 'Request signoff?',
  request_signoffs_on_version: 'Request signoff on version {version}',
  request_signoffs: 'Request signoff',
  request: 'Request',
  requested_by: 'Requested by {name}',
  requested_description: 'A signoff request has been sent to the employee.',
  requested: 'Requested',
  require_comments: 'Require comments',
  require_participants_to_comment: 'Require participants to comment when a specific option is selected.',
  require: 'Require',
  required_three_plus_responsibility: 'Requires 3+ responsibilities',
  required: 'Required',
  requirements_gaps: 'Requirements gaps',
  requirements: "@:{'singular_requirement'} | @:{'plural_requirement'}",
  reschedule: 'Reschedule',
  resend_invitations: 'Resend invitation | Resend invitations',
  resend_invite: 'Resend invitation',
  resend_invites: 'Resend invite',
  resend_to: 'Resend to',
  resend: 'Resend',
  reset_integration_description: 'Are you sure about to reset this integration.',
  reset_integration: 'Reset integration',
  reset_password_msg: "Please enter your login email and we'll send you a link to reset your password.",
  reset_password: 'Reset password',
  reset: 'Reset',
  resigned: 'Resigned',
  resources: 'Resources',
  response_options: 'Response options',
  response_rate: 'Response rate',
  responses: 'Responses',
  responsibilities_archived: 'Item archived | Items archived',
  responsibilities_created: 'Item created | Items created',
  responsibilities_deleted: 'Item deleted | Items deleted',
  responsibilities_edited: 'Item edited | Items edited',
  responsibilities_guideline_description: 'Responsibilities focus on the most critical and important aspects of the job - they are not intended to describe every aspect of the job.<p class="mt-2"> There should be 8 - 12 responsibilities in total.<p>',
  responsibilities_moved: 'Item moved | Items moved',
  responsibilities_published: 'Item published | Items published',
  responsibilities_restored: 'Item restored | Items restored',
  responsibilities_updated: "@.capitalize:{'singular_responsibilities'} updated | @.capitalize:{'plural_responsibilities'} updated",
  responsibilities: "@:{'singular_responsibilities'} | @:{'plural_responsibilities'}",
  responsibility_archive: "Archive: @:{'plural_responsibilities'}",
  responsibility_archived: 'Item archived | Items archived',
  responsibility_created: 'Item created | Items created',
  responsibility_deleted: 'Item deleted | Items deleted',
  responsibility_groups: "Groups: @.capitalize:{'plural_responsibilities'}",
  responsibility_moved: 'Item moved | Items moved',
  responsibility_published: 'Item published | Items published',
  responsibility_restored: 'Item restored | Items restored',
  responsibility_updated: 'Item updated | Items updated',
  responsibility: "@:{'singular_responsibilities'} | @:{'plural_responsibilities'}",
  restore_additional_information_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_additional_information: 'Item restored | Items restored',
  restore_competencies_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_competencies: "Restore @.lower:{'competencies'}?",
  restore_educations_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_educations: "Restore @.lower:{'educations'}?",
  restore_experiences_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_experiences: "Restore @.lower:{'experiences'}?",
  restore_jobs_message: "There's a job with the same name in your site. If you restore this job, we'll add a number to the end of it.",
  restore_jobs: 'Restore job?',
  restore_languages_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_languages: "Restore @.lower:{'languages'}?",
  restore_library_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_responsibilities: "Restore @.lower:{'responsibilities'}?",
  restore_responsibility_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_responsibility: "Restore @.lower:{'responsibilities'}?",
  restore_skill_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_skill: "Restore @.lower:{'skills'}?",
  restore_version_description: 'Use Version {number} of this job to create a new draft.',
  restore_version: 'Restore version',
  restore_working_conditions_msg: "There's an identical item in this library. If you restore this item, we'll add a number to the end of it.",
  restore_working_conditions: "Restore @.lower:{'working_conditions'}?",
  restore: 'Restore',
  restored: 'Restored',
  results_not_available_yet: 'Results not available yet. Please check back later.',
  results: 'Results',
  resume: 'Resume',
  resumes: 'Resume',
  retired: 'Retired',
  return_users_table: 'Return to users table',
  reuse_previous_mapping: 'Reuse previous mapping',
  review_acknowledgement_email_for_employees: 'Review acknowledgement email for employees',
  review_acknowledgements: 'Review acknowledgements',
  review_administrators: 'Review administrators',
  review_and_edit: 'Review and edit',
  review_assessments_cancel_msg: 'Review assessments are canceled',
  review_bulk_share_results_msg: 'Eligible assessments have been shared.',
  review_by: 'Review by',
  review_cancel_dialog_message: 'If you cancel this review, invitations will not be sent to participants. You can still reschedule the review later.',
  review_cancel_dialog_title: 'Cancel review',
  review_close_dialog_message: 'If you close this review any outstanding assessments will be cancelled and participants will not be able to provide further assessment data. Results sharing and employee acknowledgements are not affected.',
  review_close_dialog_title: 'Close review',
  review_communications_automated_reminders_note: 'Leave blank to not send automated reminders.',
  review_communications_automated_reminders_start_before_deadline_days_input: 'Automatically send participants daily reminders starting {input} days before each deadline.',
  review_communications: 'Review communications',
  review_contains_error_message: 'This review contains errors that require your attention.',
  review_cycle_banner_message_1: 'This job has been scheduled for review by {date}.',
  review_cycle_banner_message_2: " If it doesn't need any changes you can <span class=\"text-link cursor-pointer\">mark the job as reviewed</span>.",
  review_cycle_banner_message_3: 'To update the job or invite others to provide input, you can <span class="text-link cursor-pointer">edit the job</span>.',
  review_cycle_notice_explanations_1: 'Notifications are sent to job-group owners and users with permission to manage jobs.',
  review_cycle_notice_explanations_2: 'Applicable jobs in the jobs table can be filtered by selecting the status Upcoming review.',
  review_cycle_notice_explanations: 'When the notice period starts:',
  review_cycle_notice_input: 'The notice period starts {input} days before the review due date.',
  review_cycle_notice_message: 'The notice period starts <span class="text-link cursor-pointer font-semibold">{days} days</span> before the review due date.',
  review_cycle_scheduled_input: 'Reviews of published jobs are scheduled for every {input} days.',
  review_cycle_scheduled_message: 'Reviews of published jobs are scheduled for every <span class="text-link cursor-pointer font-semibold">{days} days</span>.',
  review_cycles: 'Review cycle | Review cycles',
  review_due_date: 'Review due date',
  review_duplicated: 'Review duplicated',
  review_error_delay_message: 'Errors can take up to 15 minutes to resolve.',
  review_managers: 'review manager | review managers',
  review_messagebar_check_setup_complete: 'Last checked on {datetime}.',
  review_messagebar_check_setup_in_process: 'Check in progress...',
  review_messagebar_complete_setup_in_process: 'Setup in progress...',
  review_messagebar_complete_setup: 'This review is scheduled to launch on {date}.',
  review_messagebar_live_close_on_date: 'This review was closed on {date}.',
  review_messagebar_went_live_on_date: 'This review went live on {date}.',
  review_new_subject_added_mas: 'Subjects added. Note: It may take up to 15 minutes for new participants to appear.',
  review_previous_mapping: 'Review previous mapping',
  review_questionnaire_indepth_rating_instruction_manager: "Rate {subjectName}'s effectiveness at: {bi}",
  review_questionnaire_indepth_rating_instruction_subject: 'Rate your effectiveness at: {bi}',
  review_questionnaire_snapshot_rating_instruction_manager: "Rate {subjectName}'s effectiveness at: {competencyOrSkill}",
  review_questionnaire_snapshot_rating_instruction_subject: 'Rate your effectiveness at: {competencyOrSkill}',
  review_reopen_dialog_message: 'If you reopen this review, participants are not contacted unless you manually reopen individual assessments.',
  review_reopen_dialog_title: 'Reopen review',
  review_requested: 'Review requested',
  review_result_header_title_text_for_manager: "{subject}'s capabilities compared to the requirements for a {job}",
  review_result_header_title_text_for_subject: 'Your capabilities compared to the requirements for a {job}',
  review_results_visibility: 'Results visibility',
  review_reviewers: 'result reviewer | result reviewers',
  review_share_results_modal_note: 'Please note: {subjectFirstName} has not submitted their assessment yet.',
  review_share_results_msg: 'Review results have been shared.',
  review_sharing_email_for_managers: 'Review sharing email for managers',
  review_types_automated_subtitle: 'Assess employees based on rules (e.g., number of days since hire date). Useful to support processes such as employee onboarding.',
  review_types_automated_title: 'Automated',
  review_types_delegated_subtitle: 'Allow designated users to control aspects of the review process (e.g., content or timing). Useful for development-focused reviews.',
  review_types_delegated_title: 'Delegated',
  review_types_scheduled_subtitle: 'Assess employees according to HR-mandated schedules. Useful for performance- or development-focused reviews.',
  review_types_scheduled_title: 'Scheduled',
  review_your_rating_for_candidate: 'Review your ratings for {candidate}.',
  review_your_rating: 'Review your ratings.',
  review: 'Review',
  reviewed: 'Reviewed',
  reviewers_description: 'Reviewers have read-only access. They can provide comments and feedback, but cannot edit the job.',
  reviewers: 'Reviewer | Reviewers',
  reviews_description: 'Confirm the key details in your review.',
  reviews_invitations_description: '{review_and_edit_action} participant emails and messages. Automatically send daily reminders starting {number_of_days_action} before each deadline.',
  reviews_reminders_description: 'Automatically send reminders to participants <span class="text-link cursor-pointer">{days} days</span> before each deadline.',
  reviews_setup_details_completion_notifications_text: 'Define which users should receive a daily email with completion stats.',
  reviews_setup_details_description_text: 'Only visible to review administrators.',
  reviews_setup_details_review_administrators_text: 'Define which users can manage the review settings, schedule, content, and participants.',
  reviews_setup_details_review_results_visibility_text: 'Define which users can view results from all of the assessments in this review.',
  reviews_setup_details_title_text: 'General information about the review, including administration and access rights.',
  reviews_setup_participants_title_text: "The employees you want to assess in this review. You only need to add subjects (the people being assessed). We'll use your reporting relationships to automatically identify any required additional participants.",
  reviews_setup_questions_content_type_sub_title: 'Include competencies and / or skills.',
  reviews_setup_questions_current_job_text: 'Assess employees against the requirements of their current job.',
  reviews_setup_questions_in_depth_text: 'For competencies only. Uses multiple questions per competency. Participants rate the subject on each behavioral statement from the proficiency level defined on the job.',
  reviews_setup_questions_snapshots_text: "Uses one question per competency or skill. Participants rate the subject's overall level in each competency or skill.",
  reviews_setup_questions_specific_job_architecture_options_text: 'Choose which competency architecture layers to include.',
  reviews_setup_questions_specific_job_question_types_text: 'Choose snapshot or in-depth questions.',
  reviews_setup_questions_specific_job_select_targets_text: 'The target is used to calculate when subjects have met the required competency level specified on the job.',
  reviews_setup_questions_specific_job_text: 'Assess employee against the requirements of a specific job.',
  reviews_setup_questions_specific_questions_text: 'Select specific competencies or skills to assess employees.',
  reviews_setup_questions_title_text: 'The questions you want to include in the review. Questions can be based on job requirements or on a direct selection of competencies and skills.',
  reviews_setup_schedule_title_text: 'The schedule for your review. Each viewpoint can be scheduled independently using the time zone for your site: {site_time_zone}. Please note that invitations may take a while to process depending on the number of participants in your review.',
  reviews_setup_viewpoints_manager_reviews_text: "Managers assess their direct reports' performance and suggest areas for future growth.",
  reviews_setup_viewpoints_self_reflection_text: 'Employees assess their own performance and capabilities.',
  reviews_setup_viewpoints_title_text: 'The employee groups you want to include in the review. To minimize bias and provide more opportunities for insights, we recommend including multiple viewpoints in a review. {learn_more}',
  reviews: 'Review | Reviews',
  revoke_device_access_message: 'Remove this device from your trusted devices.',
  revoke_device_access: 'Revoke device access',
  revoke: 'Revoke',
  roles: 'Role | Roles',
  round_details: 'Round details',
  rounds: 'Rounds',
  rows: 'Rows',
  salary_ranges: 'Salary range',
  salary: 'Salary',
  save_and_exit: 'Save and exit',
  save_as_templates: 'Save as template',
  save_comment_modal_message: 'Do you want to save this comment?',
  save_comment_modal_title: 'Save comment?',
  save: 'Save',
  saved: 'Saved',
  saving: 'Saving',
  scale_deleted_impact: 'This level has been deleted. {jobs_count} jobs have been updated.',
  scale_deleted: 'This level has been deleted.',
  scale_disabled_impact: 'This scale has been turned off. {jobs_count} jobs have been updated.',
  scale_disabled: 'This scale has been turned off.',
  scale_table_desc: 'Scales can be used in jobs, libraries, and interview templates.',
  scales_updated: 'Scales updated',
  scales: 'Scale | Scales',
  schedule_description: 'Define the survey timing.',
  schedule_survey: 'Schedule survey',
  schedule: 'Schedule',
  scheduled_for_review_by: 'Scheduled for review by {date}',
  scheduled_review: 'Scheduled review',
  scheduled: 'Scheduled',
  score_candidates: 'Score candidate',
  scorecard_comments: 'Scorecard comments',
  scorecard_reopened: 'Scorecard reopened',
  scorecard_submitted_banner: "Your scorecard has been submitted and can't be edited.",
  scorecard_submitted_count: '{submitted} of {total} scorecards submitted',
  scorecards: 'Scorecard',
  scoring_authority: 'Scoring authority',
  search_employee: 'Search employee',
  search_employees: 'Search employees',
  search_for_a_job_to_compare_to: 'Search for a job to compare to',
  search_for_a_position_or_user: 'Search for a position or user',
  search_for_competencies: "Search for @.lower:{'competencies'}",
  search_name_or_email: 'Search name or email',
  search_name_or_id: 'Search name or ID',
  search_or_enter_title: 'Search or enter title',
  search_or_type: "Search <span class=\"text-grey-3\">or type '#'</span>",
  search_subjects_or_jobs: 'Search subjects or jobs',
  search_subjects: 'Search subjects',
  search_user_or_position: 'Search for positions or assigned users',
  search_users: 'Search users',
  search: 'Search',
  seats: 'Seats',
  sections: 'Section | Sections',
  see_examples: 'See example | See examples',
  see_full_import_details: 'To see full import details',
  see_here: 'see here',
  see_less: 'See less',
  see_more: 'See more',
  select_a_level: 'Select a level',
  select_a_version_to_compare: 'Select a version to compare',
  select_all_additional_information_items: 'Select all',
  select_all_certifications_items: 'Select all',
  select_all_certifications: 'Select all',
  select_all_competencies_items: 'Select all',
  select_all_competencies: 'Select all',
  select_all_educations_items: 'Select all',
  select_all_educations: 'Select all',
  select_all_experiences_items: 'Select all',
  select_all_jobs: 'Select all',
  select_all_language_items: 'Select all',
  select_all_languages_items: 'Select all',
  select_all_positions: 'Select all',
  select_all_questions: 'Select all',
  select_all_records: 'Select all',
  select_all_responsibilities: 'Select all',
  select_all_responsibility_items: 'Select all',
  select_all_skill_items: 'Select all',
  select_all_skill: 'Select all',
  select_all_skills_items: 'Select all',
  select_all_skills: 'Select all',
  select_all_surveys: 'Select all',
  select_all_users: 'Select all',
  select_all_working_conditions_items: 'Select all',
  select_all: 'Select all',
  select_competencies: "Select @.lower:{'plural_competencies'}",
  select_end_date: 'Select end date',
  select_groups: 'Select group',
  select_job_post_template: 'Select job post template',
  select_job: 'Select job',
  select_levels: 'Select level',
  select_libraries: 'Select libraries',
  select_new_parent: 'Select new parent',
  select_permission_sets: 'Select permission sets',
  select_scales: 'Select scale',
  select_scoring_authority: 'Select scoring authority:',
  select_skill: "Select @.lower:{'plural_skills'}",
  select_skills: "Select @.lower:{'plural_skills'}",
  select_start_date: 'Select start date',
  select_targets: 'Select target',
  select_topics: 'Select topics',
  select_types: 'Select types',
  select_versions: 'Select version',
  select: 'Select',
  selected_by: 'Selected by:',
  selected_content: 'Selected content',
  selected: 'Selected | Selected',
  self_reflection_communicated_deadline: 'This is the deadline given on feedback requests but feedback can still be provided after this date.',
  self_reflection_description: 'Employees assess their own performance and capabilities',
  self_reflection_launch_date: 'Employees will receive a notification and email on this date prompting them to provide their self reflections.',
  self_reflection_share_with_manager_description: "Share self reflections with employees' direct managers once they've been submitted.",
  self_reflection_subtitle: 'Provide self reflection',
  self_reflections: 'Self reflection | Self reflections',
  send_daily_reminder_new_responses: 'Send a daily email showing new survey responses.',
  send_email_when_done_message: "We'll send an email to {email} when it's done.",
  send_email_when_done: "We'll send you an email when it's done.",
  send_invitations: 'Send invitation | Send invitations',
  send_invite: 'Send invite',
  send_invites_confirmation_message: 'Send meeting invites to interviewers. Please note that Quinto does not send invites to candidates.',
  send_invites_interviewers_tooltip: "We'll only invite interviewers who have not yet been invited. Please note that Quinto does not send invites to candidates.",
  send_invites: 'Send invite | Send invites',
  send_job_post: 'Send job post',
  send_password_reset_email_message: 'This user will receive an email with a link to reset their password.',
  send_password_reset_email_title: 'Send password reset email',
  send_password_reset_now: 'Send a <span class="text-link cursor-pointer">password reset</span> now.',
  send_password_reset: 'Send password reset',
  send_reminders: 'Send reminder | Send reminders',
  send_signoff_reminders_message: 'Send a signoff reminder to {name} | Send a signoff reminder to employees.',
  send_signoff_reminders: 'Send signoff reminder',
  send_to_ats: 'Send to {ats}',
  send_to: 'Send to',
  send: 'Send',
  sent_by: 'Sent by {name}',
  sent_password_reset: 'Sent password reset',
  sent: 'Sent',
  sep: 'Sep',
  set_a_due_date: 'Set a due date',
  set_level: 'Set level',
  set_to_done: 'Set to done',
  set_up_interviews: 'Set up interview',
  settings_description: 'Modify the settings for this survey.',
  settings: 'Setting | Settings',
  setup: 'Setup',
  share_again: 'Share again',
  share_results_with_subject: 'Share these results with {subjectName}.',
  share_results: 'Share results',
  share_with_x: 'Share with {name}',
  shared_feedback: 'Shared feedback',
  shared_with_x: 'Shared with {name}',
  sharing_results_option_1: 'Results will not be shared with subjects.',
  sharing_results_option_2: 'Allow subjects to see results automatically as soon as responses are submitted.',
  sharing_results_option_3: 'Administrators are responsible for sharing results.',
  sharing_results_option_4_sub_1: 'Allow managers to share results as soon as they submit feedback.',
  sharing_results_option_4_sub_2: 'Require managers to share according to a schedule.',
  sharing_results_option_4: 'Require managers to share and discuss results with their direct reports.',
  sharing_results_subtitle: 'Results are shared with employees.',
  sharing_results: 'Sharing results',
  show_all_details: 'Show all details',
  show_architecture_description: 'Show an architecture label (e.g., core or job-family).',
  show_architecture: 'Show architecture',
  show_assigned_levels: 'Show assigned levels',
  show_changes: 'Show changes',
  show_columns: 'Show columns',
  show_core_items_description: "Let participants see core @.lower:{'plural_competencies'}.",
  show_details: 'Show details',
  show_leadership_items_description: "Let participants see leadership @.lower:{'plural_competencies'}.",
  show_less_comments: 'Show less comments',
  show_less: 'Show less',
  show_library_items: 'Highlight library items',
  show_more_comments: 'Show more comments',
  show_more: 'Show more',
  show_subheadings: 'Show subheadings',
  show_toolbar: 'Show toolbar',
  show_track_changes: 'Show track changes',
  show_untranslated_items: 'Show untranslated items',
  show: 'Show',
  showing: 'Showing',
  side_content: 'Side content',
  signature_date_description: 'Add a signature and date field on job exports.',
  signature_date: 'Signature and date',
  signed_off_by: 'Signed off by {name}',
  signed_off_date: 'Signed off: {date}',
  signed_off: 'Signed off',
  signoff_are_due_after_each_request_is_sent_input: 'Signoffs are due {input} days after each request is sent.',
  signoff_are_due_after_each_request_is_sent: 'Signoffs are due <span class="text-link cursor-pointer font-semibold">{days} days</span> after each request is sent.',
  signoff_auto_reminders_input_text: 'Auto reminders are sent daily, starting {input} days before the due date and continuing until signoff is completed.',
  signoff_auto_reminders_panel_text: 'Automatically send daily reminders, starting <span class="text-link cursor-pointer font-semibold"> {days} days </span> before the due date.',
  signoff_canceled_message: 'Your signoff request has been cancelled.',
  signoff_complete_message: 'Thank you for completing your signoff. You can <span id="download" class="text-link cursor-pointer">download</span> a copy now for your records or download it later from your <span id="profile" class="text-link cursor-pointer">profile</span>.',
  signoff_complete: 'Signoff complete',
  signoff_confirmation_message: 'To confirm your identity, please enter the six-digit confirmation code that has been emailed to you.',
  signoff_confirmation: 'Signoff confirmation',
  signoff_date: 'Signoff date',
  signoff_deleted_message: 'This job has been deleted and your signoff request has been cancelled.',
  signoff_due_date: 'Signoff due date',
  signoff_due: 'Signoff due',
  signoff_expire_after_days_input: 'Signoffs expire after {input} days.',
  signoff_expire_after_days: 'Signoffs expire after <span class="text-link cursor-pointer font-semibold">{days} days</span>.',
  signoff_expired: 'Signoff expired',
  signoff_expiry_date: 'Signoff expiry date',
  signoff_granted_by: 'Signoff granted by {name}',
  signoff_granted: 'Signoff granted',
  signoff_history: 'Signoff history',
  signoff_request_message: 'Signoff request message',
  signoff_statement_description: 'The statement employees acknowledge when signing off on their job descriptions.',
  signoff_statement: 'Signoff statement',
  signoff_updated_message: 'Your job has been updated. Please check your email for a new signoff request.',
  signoff: 'Signoff',
  signoffs_will_appear_here: 'Signoffs will appear here.',
  signoffs: 'Signoffs',
  single_select: 'Single-select',
  single_sign_on_desc: 'Allow users to access Quinto using SSO.',
  single_sign_on: 'Single sign-on',
  singular_additional_information: 'Additional information',
  singular_certifications: 'Certification',
  singular_competencies: 'Competency',
  singular_educations: 'Education',
  singular_experiences: 'Experience',
  singular_interview_questions: 'Interview question',
  singular_jobs: 'Job',
  singular_languages: 'Language',
  singular_learning_resources: 'Learning resource',
  singular_requirement: 'Requirement',
  singular_responsibilities: 'Responsibility',
  singular_skills: 'Skill',
  singular_working_conditions: 'Working condition',
  singular: 'Singular',
  site_admin_email: 'Site administrator email',
  site_logo: 'Site logo',
  site_update_message: "We've updated Quinto.",
  skill_archive: "Archive: @:{'plural_skills'}",
  skill_archived: 'Item archived | Items archived',
  skill_created: 'Item created | Items created',
  skill_deleted: 'Item deleted | Items deleted',
  skill_edited: 'Item edited | Items edited',
  skill_groups: "Groups: @.capitalize:{'plural_skills'}",
  skill_levels: "@.capitalize:{'singular_skills'} levels",
  skill_moved: 'Item moved | Items moved',
  skill_published: 'Item published | Items published',
  skill_restored: 'Item restored | Items restored',
  skill_scales: 'Skill scale',
  skill_updated: 'Item updated | Items updated',
  skill: "@:{'singular_skills'} | @:{'plural_skills'}",
  skills_guideline_description: 'Skills are developed capabilities needed to perform a task or job.<p class="mt-2"> There should be 8 - 16 skills in total.<p/>',
  skills_updated: "@.capitalize:{'singular_skills'} updated | @.capitalize:{'plural_skills'} updated",
  skills: "@:{'singular_skills'} | @:{'plural_skills'}",
  snapshots: 'Snapshot',
  some_interviewers_not_invited: 'Some interviewers have not been invited.',
  some_jobs_published: "The job was not published because it didn't meet consistency requirements. | Some jobs were not published because they didn't meet consistency requirements.",
  sorry_page_not_available: "Sorry, this page isn't available.",
  sort_by: 'Sort by',
  specific_jobs: 'Specific job',
  specific_questions: 'Specific questions',
  sso_auth_url: 'Authorization URL',
  sso_client_id: 'Client ID',
  sso_keys_url: 'Keys URL',
  sso_provider: 'Provider',
  sso: 'SSO',
  starred_only_visible_to_you: 'Starred. Only visible to you.',
  starred: 'Starred',
  start_date: 'Start date',
  start_from_scratch: 'Start from scratch',
  start_with_ai_generated_label: 'Start with AI-generated content?',
  start_with_ai_generated_sublabel: 'AI will suggest behavioral indicators for the competency. This is a Quinto Labs feature.',
  start_with_templates: 'Start with a template',
  start_with_warehouse_content: 'Start with warehouse content?',
  start: 'Start',
  started_tracking_changes: 'Started tracking changes',
  statement: 'Statement',
  status_error: '{status} error',
  statuses: 'Status | Statuses',
  stay_tuned: 'Stay tuned.',
  stop_level_on_job_description: 'Stop using this level on job descriptions',
  stopped_personifying: 'Stopped personifying',
  stopped_tracking_changes: 'Stopped tracking changes',
  strength_checks: 'Strength checks',
  strengths: 'Strengths',
  subheadings: 'Subheading | Subheadings',
  subject_capability_match_percentage_of_job: 'You meet or exceed {percentage} of the job requirements for a {job}',
  subject: 'Subject',
  subjects: 'Subject | Subjects',
  submit_rating: 'Submit ratings now',
  submit_your_input: 'Submit your input',
  submit: 'Submit',
  submitted_scorecard: 'Submitted scorecard',
  submitted: 'Submitted',
  suggested_alternative: 'Suggested alternative',
  suggested_summary: 'Suggested summary',
  suggestions: 'Suggestion | Suggestions',
  summary_guideline_description: 'The job summary is an informative overview of a job position. It briefly describes the essential responsibilities associated with the role.<p class="mt-2"> There should be 20-60 words in the job summary.</p>',
  summary: 'Summary',
  survey_comment_error_message: 'Please comment on the items you have added to the {name} column.',
  survey_communications: 'Survey communications',
  survey_contents_added: 'Survey content added',
  survey_contents_not_translated: 'This survey contains content that has not been translated.',
  survey_contents_removed: 'Survey content removed',
  survey_contents: 'Survey content',
  survey_has_additional_informations: "This survey has {count} @.lower:{'additional_information'}.",
  survey_has_certifications: "This survey has {count} @.lower:{'certifications'}.",
  survey_has_competencies: "This survey has {count} @.lower:{'competencies'}.",
  survey_has_educations: "This survey has {count} @.lower:{'educations'}.",
  survey_has_experiences: "This survey has {count} @.lower:{'experiences'}.",
  survey_has_languages: "This survey has {count} @.lower:{'languages'}.",
  survey_has_participants: 'This survey has {count} participants.',
  survey_has_responsibilities: "This survey has {count} @.lower:{'responsibilities'}.",
  survey_has_skills: "This survey has {count} @.lower:{'skills'}.",
  survey_has_working_conditions: "This survey has {count} @.lower:{'working_conditions'}.",
  survey_invitation_resent: 'Survey invitation resent',
  survey_invitation_sent: 'Survey invitation sent',
  survey_is_live_and_due: 'This survey is live and due {date}.',
  survey_is_live: 'This survey is live.',
  survey_is_scheduled_on: 'This survey is scheduled to go live on {date} at {time}.',
  survey_max_selection_error_message: "You've already added the maximum number of items to that column.",
  survey_min_selection_error_message: 'Please add more items to the {name} column.',
  survey_not_available: "Sorry, you can't access this survey. {link}",
  survey_not_translated: 'The survey name or some of the survey content has not been translated.',
  survey_participant_responses: 'Survey participant responses',
  survey_participants_added: 'Survey participant added',
  survey_participants_removed: 'Survey participant removed',
  survey_participants: 'Survey participants',
  survey_recipients_added: 'Survey recipient added',
  survey_recipients_removed: 'Survey recipient removed',
  survey_response_options: 'Survey response options',
  survey_responses: 'Survey response',
  survey_results: 'Survey results',
  survey_was_closed_on: 'This survey was closed on {date} by {name}.',
  surveys: 'Survey | Surveys',
  suspended_msg: 'This user has been suspended for too many login attempts.',
  suspended: 'Suspended',
  synchronize_now: 'Synchronize now',
  synchronize: 'Synchronize',
  system: 'System',
  tab_ats: 'ATS',
  tab_hris: 'HRIS',
  tab_jobs: 'Jobs',
  tab_libraries: 'Libraries',
  tags_tooltip_info: 'Click to see more tags',
  tags_use_count: 'Used {count} time | Used {count} times',
  tags: 'Tags',
  take_me_there: 'Take me there!',
  target_user_groups: 'Target user groups',
  target: 'Target',
  task_interview_candidate: 'Candidate: {candidate}',
  task_opening_recruiter: 'Recruiter: {assigner}',
  tasks: 'Tasks',
  tbd: 'TBD',
  team: 'Team',
  template_additional_informations_message: "Turn on @.lower:{'plural_additional_information'} for jobs using this template.",
  template_can_not_delete: "This template can't be deleted because it has been used.",
  template_can_not_turned_off: "This template can't be turned off because it is the default.",
  template_can_not_turned_on: "This template can't be turned on because it isn't associated with any post template.",
  template_certifications_message: "Turn on @.lower:{'plural_certifications'} for jobs using this template.",
  template_competencies_description: "Turn on  @.lower:{'plural_competencies'} for jobs using this template.",
  template_content_description: 'Define the order and content sections of your job descriptions.',
  template_educations_message: "Turn on @.lower:{'plural_educations'} for jobs using this template.",
  template_enable_consistency_scores_description: 'Consistency scores help to achieve more consistent job descriptions by defining ideal content ranges.',
  template_enable_consistency_scores: 'Turn on Consistency scores for jobs using this template.',
  template_enable_feedback_description: 'Feedback allows job collaborators to provide high-level job description feedback.',
  template_enable_feedback: 'Turn on Feedback for jobs using this template.',
  template_enable_job_signoffs: 'Turn on Job signoffs for jobs using this template.',
  template_enable_properties: 'Turn on Information for jobs using this template',
  template_enable_review_cycles: 'Turn on Review cycles for jobs using this template.',
  template_experiences_message: "Turn on @.lower:{'plural_experiences'} for jobs using this template.",
  template_groups: 'Template groups',
  template_job_post_description: 'Define the order and content sections of your job posts. Empty sections will not appear in posts.',
  template_languages_message: "Turn on @.lower:{'plural_languages'} for jobs using this template.",
  template_legal_description: 'Choose the legal sections to include in PDF/Word exports.',
  template_logo_description: 'Upload a logo to display in PDF/Word exports.',
  template_responsibilities_message: "Turn on @.lower:{'plural_responsibilities'} for jobs using this template.",
  template_settings_description: 'Define settings for this template.',
  template_skills_message: "Turn on @.lower:{'plural_skills'} for jobs using this template.",
  template_statuses: 'Template status',
  template_summary_message: 'Turn on Summary for jobs using this template.',
  template_title: 'Template title',
  template_working_conditions_message: "Turn on @.lower:{'plural_working_conditions'} for jobs using this template.",
  template: 'Template | Templates',
  templates_duplicated: 'Template duplicated | Templates duplicated',
  templates_table_msg: 'Templates define the layout and properties of a job. They apply to all jobs and job posts.',
  templates: 'Templates',
  terms: 'Terms',
  textbox: 'Alphanumeric',
  thanks_for_providing_input: 'Thanks for providing your input. We really appreciate it!',
  this_month: 'This month',
  this_week: 'This week',
  this_year: 'This year',
  time_zone: 'Time zone',
  timeline: 'Timeline',
  times: 'Time',
  timezone_africa_abidjan: 'Africa/Abidjan',
  timezone_africa_accra: 'Africa/Accra',
  timezone_africa_addis_ababa: 'Africa/Addis Ababa',
  timezone_africa_algiers: 'Africa/Algiers',
  timezone_africa_asmara: 'Africa/Asmara',
  timezone_africa_bamako: 'Africa/Bamako',
  timezone_africa_bangui: 'Africa/Bangui',
  timezone_africa_banjul: 'Africa/Banjul',
  timezone_africa_bissau: 'Africa/Bissau',
  timezone_africa_blantyre: 'Africa/Blantyre',
  timezone_africa_brazzaville: 'Africa/Brazzaville',
  timezone_africa_bujumbura: 'Africa/Bujumbura',
  timezone_africa_cairo: 'Africa/Cairo',
  timezone_africa_casablanca: 'Africa/Casablanca',
  timezone_africa_ceuta: 'Africa/Ceuta',
  timezone_africa_conakry: 'Africa/Conakry',
  timezone_africa_dakar: 'Africa/Dakar',
  timezone_africa_dar_es_salaam: 'Africa/Dar es Salaam',
  timezone_africa_djibouti: 'Africa/Djibouti',
  timezone_africa_douala: 'Africa/Douala',
  timezone_africa_el_aaiun: 'Africa/El Aaiun',
  timezone_africa_freetown: 'Africa/Freetown',
  timezone_africa_gaborone: 'Africa/Gaborone',
  timezone_africa_harare: 'Africa/Harare',
  timezone_africa_johannesburg: 'Africa/Johannesburg',
  timezone_africa_juba: 'Africa/Juba',
  timezone_africa_kampala: 'Africa/Kampala',
  timezone_africa_khartoum: 'Africa/Khartoum',
  timezone_africa_kigali: 'Africa/Kigali',
  timezone_africa_kinshasa: 'Africa/Kinshasa',
  timezone_africa_lagos: 'Africa/Lagos',
  timezone_africa_libreville: 'Africa/Libreville',
  timezone_africa_lome: 'Africa/Lome',
  timezone_africa_luanda: 'Africa/Luanda',
  timezone_africa_lubumbashi: 'Africa/Lubumbashi',
  timezone_africa_lusaka: 'Africa/Lusaka',
  timezone_africa_malabo: 'Africa/Malabo',
  timezone_africa_maputo: 'Africa/Maputo',
  timezone_africa_maseru: 'Africa/Maseru',
  timezone_africa_mbabane: 'Africa/Mbabane',
  timezone_africa_mogadishu: 'Africa/Mogadishu',
  timezone_africa_monrovia: 'Africa/Monrovia',
  timezone_africa_nairobi: 'Africa/Nairobi',
  timezone_africa_ndjamena: 'Africa/Ndjamena',
  timezone_africa_niamey: 'Africa/Niamey',
  timezone_africa_nouakchott: 'Africa/Nouakchott',
  timezone_africa_ouagadougou: 'Africa/Ouagadougou',
  timezone_africa_porto_novo: 'Africa/Porto-Novo',
  timezone_africa_sao_tome: 'Africa/Sao Tome',
  timezone_africa_tripoli: 'Africa/Tripoli',
  timezone_africa_tunis: 'Africa/Tunis',
  timezone_africa_windhoek: 'Africa/Windhoek',
  timezone_america_adak: 'America/Adak',
  timezone_america_anchorage: 'America/Anchorage',
  timezone_america_anguilla: 'America/Anguilla',
  timezone_america_antigua: 'America/Antigua',
  timezone_america_araguaina: 'America/Araguaina',
  timezone_america_argentina_buenos_aires: 'America/Argentina/Buenos Aires',
  timezone_america_argentina_catamarca: 'America/Argentina/Catamarca',
  timezone_america_argentina_cordoba: 'America/Argentina/Cordoba',
  timezone_america_argentina_jujuy: 'America/Argentina/Jujuy',
  timezone_america_argentina_la_rioja: 'America/Argentina/La Rioja',
  timezone_america_argentina_mendoza: 'America/Argentina/Mendoza',
  timezone_america_argentina_rio_gallegos: 'America/Argentina/Rio Gallegos',
  timezone_america_argentina_salta: 'America/Argentina/Salta',
  timezone_america_argentina_san_juan: 'America/Argentina/San Juan',
  timezone_america_argentina_san_luis: 'America/Argentina/San Luis',
  timezone_america_argentina_tucuman: 'America/Argentina/Tucuman',
  timezone_america_argentina_ushuaia: 'America/Argentina/Ushuaia',
  timezone_america_aruba: 'America/Aruba',
  timezone_america_asuncion: 'America/Asuncion',
  timezone_america_atikokan: 'America/Atikokan',
  timezone_america_bahia_banderas: 'America/Bahia Banderas',
  timezone_america_bahia: 'America/Bahia',
  timezone_america_barbados: 'America/Barbados',
  timezone_america_belem: 'America/Belem',
  timezone_america_belize: 'America/Belize',
  timezone_america_blanc_sablon: 'America/Blanc-Sablon',
  timezone_america_boa_vista: 'America/Boa Vista',
  timezone_america_bogota: 'America/Bogota',
  timezone_america_boise: 'America/Boise',
  timezone_america_cambridge_bay: 'America/Cambridge Bay',
  timezone_america_campo_grande: 'America/Campo Grande',
  timezone_america_cancun: 'America/Cancun',
  timezone_america_caracas: 'America/Caracas',
  timezone_america_cayenne: 'America/Cayenne',
  timezone_america_cayman: 'America/Cayman',
  timezone_america_chicago: 'America/Chicago',
  timezone_america_chihuahua: 'America/Chihuahua',
  timezone_america_costa_rica: 'America/Costa Rica',
  timezone_america_creston: 'America/Creston',
  timezone_america_cuiaba: 'America/Cuiaba',
  timezone_america_curacao: 'America/Curacao',
  timezone_america_danmarkshavn: 'America/Danmarkshavn',
  timezone_america_dawson_creek: 'America/Dawson Creek',
  timezone_america_dawson: 'America/Dawson',
  timezone_america_denver: 'America/Denver',
  timezone_america_detroit: 'America/Detroit',
  timezone_america_dominica: 'America/Dominica',
  timezone_america_edmonton: 'America/Edmonton',
  timezone_america_eirunepe: 'America/Eirunepe',
  timezone_america_el_salvador: 'America/El Salvador',
  timezone_america_fort_nelson: 'America/Fort Nelson',
  timezone_america_fortaleza: 'America/Fortaleza',
  timezone_america_glace_bay: 'America/Glace Bay',
  timezone_america_goose_bay: 'America/Goose Bay',
  timezone_america_grand_turk: 'America/Grand Turk',
  timezone_america_grenada: 'America/Grenada',
  timezone_america_guadeloupe: 'America/Guadeloupe',
  timezone_america_guatemala: 'America/Guatemala',
  timezone_america_guayaquil: 'America/Guayaquil',
  timezone_america_guyana: 'America/Guyana',
  timezone_america_halifax: 'America/Halifax',
  timezone_america_havana: 'America/Havana',
  timezone_america_hermosillo: 'America/Hermosillo',
  timezone_america_indiana_indianapolis: 'America/Indiana/Indianapolis',
  timezone_america_indiana_knox: 'America/Indiana/Knox',
  timezone_america_indiana_marengo: 'America/Indiana/Marengo',
  timezone_america_indiana_petersburg: 'America/Indiana/Petersburg',
  timezone_america_indiana_tell_city: 'America/Indiana/Tell City',
  timezone_america_indiana_vevay: 'America/Indiana/Vevay',
  timezone_america_indiana_vincennes: 'America/Indiana/Vincennes',
  timezone_america_indiana_winamac: 'America/Indiana/Winamac',
  timezone_america_inuvik: 'America/Inuvik',
  timezone_america_iqaluit: 'America/Iqaluit',
  timezone_america_jamaica: 'America/Jamaica',
  timezone_america_juneau: 'America/Juneau',
  timezone_america_kentucky_louisville: 'America/Kentucky/Louisville',
  timezone_america_kentucky_monticello: 'America/Kentucky/Monticello',
  timezone_america_kralendijk: 'America/Kralendijk',
  timezone_america_la_paz: 'America/La Paz',
  timezone_america_lima: 'America/Lima',
  timezone_america_los_angeles: 'America/Los Angeles',
  timezone_america_lower_princes: 'America/Lower Princes',
  timezone_america_maceio: 'America/Maceio',
  timezone_america_managua: 'America/Managua',
  timezone_america_manaus: 'America/Manaus',
  timezone_america_marigot: 'America/Marigot',
  timezone_america_martinique: 'America/Martinique',
  timezone_america_matamoros: 'America/Matamoros',
  timezone_america_mazatlan: 'America/Mazatlan',
  timezone_america_menominee: 'America/Menominee',
  timezone_america_merida: 'America/Merida',
  timezone_america_metlakatla: 'America/Metlakatla',
  timezone_america_mexico_city: 'America/Mexico City',
  timezone_america_miquelon: 'America/Miquelon',
  timezone_america_moncton: 'America/Moncton',
  timezone_america_monterrey: 'America/Monterrey',
  timezone_america_montevideo: 'America/Montevideo',
  timezone_america_montserrat: 'America/Montserrat',
  timezone_america_nassau: 'America/Nassau',
  timezone_america_new_york: 'America/New York',
  timezone_america_nipigon: 'America/Nipigon',
  timezone_america_nome: 'America/Nome',
  timezone_america_noronha: 'America/Noronha',
  timezone_america_north_dakota_beulah: 'America/North Dakota/Beulah',
  timezone_america_north_dakota_center: 'America/North Dakota/Center',
  timezone_america_north_dakota_new_salem: 'America/North Dakota/New Salem',
  timezone_america_nuuk: 'America/Nuuk',
  timezone_america_ojinaga: 'America/Ojinaga',
  timezone_america_panama: 'America/Panama',
  timezone_america_pangnirtung: 'America/Pangnirtung',
  timezone_america_paramaribo: 'America/Paramaribo',
  timezone_america_phoenix: 'America/Phoenix',
  timezone_america_port_au_prince: 'America/Port-au-Prince',
  timezone_america_port_of_spain: 'America/Port of Spain',
  timezone_america_porto_velho: 'America/Porto Velho',
  timezone_america_puerto_rico: 'America/Puerto Rico',
  timezone_america_punta_arenas: 'America/Punta Arenas',
  timezone_america_rainy_river: 'America/Rainy River',
  timezone_america_rankin_inlet: 'America/Rankin Inlet',
  timezone_america_recife: 'America/Recife',
  timezone_america_regina: 'America/Regina',
  timezone_america_resolute: 'America/Resolute',
  timezone_america_rio_branco: 'America/Rio Branco',
  timezone_america_santarem: 'America/Santarem',
  timezone_america_santiago: 'America/Santiago',
  timezone_america_santo_domingo: 'America/Santo Domingo',
  timezone_america_sao_paulo: 'America/Sao Paulo',
  timezone_america_scoresbysund: 'America/Scoresbysund',
  timezone_america_sitka: 'America/Sitka',
  timezone_america_st_barthelemy: 'America/St Barthelemy',
  timezone_america_st_johns: 'America/St Johns',
  timezone_america_st_kitts: 'America/St Kitts',
  timezone_america_st_lucia: 'America/St Lucia',
  timezone_america_st_thomas: 'America/St Thomas',
  timezone_america_st_vincent: 'America/St Vincent',
  timezone_america_swift_current: 'America/Swift Current',
  timezone_america_tegucigalpa: 'America/Tegucigalpa',
  timezone_america_thule: 'America/Thule',
  timezone_america_thunder_bay: 'America/Thunder Bay',
  timezone_america_tijuana: 'America/Tijuana',
  timezone_america_toronto: 'America/Toronto',
  timezone_america_tortola: 'America/Tortola',
  timezone_america_vancouver: 'America/Vancouver',
  timezone_america_whitehorse: 'America/Whitehorse',
  timezone_america_winnipeg: 'America/Winnipeg',
  timezone_america_yakutat: 'America/Yakutat',
  timezone_america_yellowknife: 'America/Yellowknife',
  timezone_antarctica_casey: 'Antarctica/Casey',
  timezone_antarctica_davis: 'Antarctica/Davis',
  timezone_antarctica_dumontdurville: 'Antarctica/DumontDUrville',
  timezone_antarctica_macquarie: 'Antarctica/Macquarie',
  timezone_antarctica_mawson: 'Antarctica/Mawson',
  timezone_antarctica_mcmurdo: 'Antarctica/McMurdo',
  timezone_antarctica_palmer: 'Antarctica/Palmer',
  timezone_antarctica_rothera: 'Antarctica/Rothera',
  timezone_antarctica_syowa: 'Antarctica/Syowa',
  timezone_antarctica_troll: 'Antarctica/Troll',
  timezone_antarctica_vostok: 'Antarctica/Vostok',
  timezone_arctic_longyearbyen: 'Arctic/Longyearbyen',
  timezone_asia_aden: 'Asia/Aden',
  timezone_asia_almaty: 'Asia/Almaty',
  timezone_asia_amman: 'Asia/Amman',
  timezone_asia_anadyr: 'Asia/Anadyr',
  timezone_asia_aqtau: 'Asia/Aqtau',
  timezone_asia_aqtobe: 'Asia/Aqtobe',
  timezone_asia_ashgabat: 'Asia/Ashgabat',
  timezone_asia_atyrau: 'Asia/Atyrau',
  timezone_asia_baghdad: 'Asia/Baghdad',
  timezone_asia_bahrain: 'Asia/Bahrain',
  timezone_asia_baku: 'Asia/Baku',
  timezone_asia_bangkok: 'Asia/Bangkok',
  timezone_asia_barnaul: 'Asia/Barnaul',
  timezone_asia_beirut: 'Asia/Beirut',
  timezone_asia_bishkek: 'Asia/Bishkek',
  timezone_asia_brunei: 'Asia/Brunei',
  timezone_asia_chita: 'Asia/Chita',
  timezone_asia_choibalsan: 'Asia/Choibalsan',
  timezone_asia_colombo: 'Asia/Colombo',
  timezone_asia_damascus: 'Asia/Damascus',
  timezone_asia_dhaka: 'Asia/Dhaka',
  timezone_asia_dili: 'Asia/Dili',
  timezone_asia_dubai: 'Asia/Dubai',
  timezone_asia_dushanbe: 'Asia/Dushanbe',
  timezone_asia_famagusta: 'Asia/Famagusta',
  timezone_asia_gaza: 'Asia/Gaza',
  timezone_asia_hebron: 'Asia/Hebron',
  timezone_asia_ho_chi_minh: 'Asia/Ho Chi Minh',
  timezone_asia_hong_kong: 'Asia/Hong Kong',
  timezone_asia_hovd: 'Asia/Hovd',
  timezone_asia_irkutsk: 'Asia/Irkutsk',
  timezone_asia_jakarta: 'Asia/Jakarta',
  timezone_asia_jayapura: 'Asia/Jayapura',
  timezone_asia_jerusalem: 'Asia/Jerusalem',
  timezone_asia_kabul: 'Asia/Kabul',
  timezone_asia_kamchatka: 'Asia/Kamchatka',
  timezone_asia_karachi: 'Asia/Karachi',
  timezone_asia_kathmandu: 'Asia/Kathmandu',
  timezone_asia_khandyga: 'Asia/Khandyga',
  timezone_asia_kolkata: 'Asia/Kolkata',
  timezone_asia_krasnoyarsk: 'Asia/Krasnoyarsk',
  timezone_asia_kuala_lumpur: 'Asia/Kuala Lumpur',
  timezone_asia_kuching: 'Asia/Kuching',
  timezone_asia_kuwait: 'Asia/Kuwait',
  timezone_asia_macau: 'Asia/Macau',
  timezone_asia_magadan: 'Asia/Magadan',
  timezone_asia_makassar: 'Asia/Makassar',
  timezone_asia_manila: 'Asia/Manila',
  timezone_asia_muscat: 'Asia/Muscat',
  timezone_asia_nicosia: 'Asia/Nicosia',
  timezone_asia_novokuznetsk: 'Asia/Novokuznetsk',
  timezone_asia_novosibirsk: 'Asia/Novosibirsk',
  timezone_asia_omsk: 'Asia/Omsk',
  timezone_asia_oral: 'Asia/Oral',
  timezone_asia_phnom_penh: 'Asia/Phnom Penh',
  timezone_asia_pontianak: 'Asia/Pontianak',
  timezone_asia_pyongyang: 'Asia/Pyongyang',
  timezone_asia_qatar: 'Asia/Qatar',
  timezone_asia_qostanay: 'Asia/Qostanay',
  timezone_asia_qyzylorda: 'Asia/Qyzylorda',
  timezone_asia_riyadh: 'Asia/Riyadh',
  timezone_asia_sakhalin: 'Asia/Sakhalin',
  timezone_asia_samarkand: 'Asia/Samarkand',
  timezone_asia_seoul: 'Asia/Seoul',
  timezone_asia_shanghai: 'Asia/Shanghai',
  timezone_asia_singapore: 'Asia/Singapore',
  timezone_asia_srednekolymsk: 'Asia/Srednekolymsk',
  timezone_asia_taipei: 'Asia/Taipei',
  timezone_asia_tashkent: 'Asia/Tashkent',
  timezone_asia_tbilisi: 'Asia/Tbilisi',
  timezone_asia_tehran: 'Asia/Tehran',
  timezone_asia_thimphu: 'Asia/Thimphu',
  timezone_asia_tokyo: 'Asia/Tokyo',
  timezone_asia_tomsk: 'Asia/Tomsk',
  timezone_asia_ulaanbaatar: 'Asia/Ulaanbaatar',
  timezone_asia_urumqi: 'Asia/Urumqi',
  timezone_asia_ust_nera: 'Asia/Ust-Nera',
  timezone_asia_vientiane: 'Asia/Vientiane',
  timezone_asia_vladivostok: 'Asia/Vladivostok',
  timezone_asia_yakutsk: 'Asia/Yakutsk',
  timezone_asia_yangon: 'Asia/Yangon',
  timezone_asia_yekaterinburg: 'Asia/Yekaterinburg',
  timezone_asia_yerevan: 'Asia/Yerevan',
  timezone_atlantic_azores: 'Atlantic/Azores',
  timezone_atlantic_bermuda: 'Atlantic/Bermuda',
  timezone_atlantic_canary: 'Atlantic/Canary',
  timezone_atlantic_cape_verde: 'Atlantic/Cape Verde',
  timezone_atlantic_faroe: 'Atlantic/Faroe',
  timezone_atlantic_madeira: 'Atlantic/Madeira',
  timezone_atlantic_reykjavik: 'Atlantic/Reykjavik',
  timezone_atlantic_south_georgia: 'Atlantic/South Georgia',
  timezone_atlantic_st_helena: 'Atlantic/St Helena',
  timezone_atlantic_stanley: 'Atlantic/Stanley',
  timezone_australia_adelaide: 'Australia/Adelaide',
  timezone_australia_brisbane: 'Australia/Brisbane',
  timezone_australia_broken_hill: 'Australia/Broken Hill',
  timezone_australia_darwin: 'Australia/Darwin',
  timezone_australia_eucla: 'Australia/Eucla',
  timezone_australia_hobart: 'Australia/Hobart',
  timezone_australia_lindeman: 'Australia/Lindeman',
  timezone_australia_lord_howe: 'Australia/Lord Howe',
  timezone_australia_melbourne: 'Australia/Melbourne',
  timezone_australia_perth: 'Australia/Perth',
  timezone_australia_sydney: 'Australia/Sydney',
  timezone_chile_easterisland: 'Chile/EasterIsland: (GMT-06:00) Easter Island',
  timezone_etc_gmt_10: 'Etc/GMT+10: (GMT-10:00) Hawaii',
  timezone_etc_gmt_11: 'Etc/GMT-11: (GMT+11:00) Solomon Is., New Caledonia',
  timezone_etc_gmt_12: 'Etc/GMT-12: (GMT+12:00) Fiji, Kamchatka, Marshall Is.',
  timezone_etc_gmt_8: 'Etc/GMT+8: (GMT-08:00) Pitcairn Islands',
  timezone_europe_amsterdam: 'Europe/Amsterdam',
  timezone_europe_andorra: 'Europe/Andorra',
  timezone_europe_astrakhan: 'Europe/Astrakhan',
  timezone_europe_athens: 'Europe/Athens',
  timezone_europe_belgrade: 'Europe/Belgrade',
  timezone_europe_berlin: 'Europe/Berlin',
  timezone_europe_bratislava: 'Europe/Bratislava',
  timezone_europe_brussels: 'Europe/Brussels',
  timezone_europe_bucharest: 'Europe/Bucharest',
  timezone_europe_budapest: 'Europe/Budapest',
  timezone_europe_busingen: 'Europe/Busingen',
  timezone_europe_chisinau: 'Europe/Chisinau',
  timezone_europe_copenhagen: 'Europe/Copenhagen',
  timezone_europe_dublin: 'Europe/Dublin',
  timezone_europe_gibraltar: 'Europe/Gibraltar',
  timezone_europe_guernsey: 'Europe/Guernsey',
  timezone_europe_helsinki: 'Europe/Helsinki',
  timezone_europe_isle_of_man: 'Europe/Isle of Man',
  timezone_europe_istanbul: 'Europe/Istanbul',
  timezone_europe_jersey: 'Europe/Jersey',
  timezone_europe_kaliningrad: 'Europe/Kaliningrad',
  timezone_europe_kirov: 'Europe/Kirov',
  timezone_europe_kyiv: 'Europe/Kyiv',
  timezone_europe_lisbon: 'Europe/Lisbon',
  timezone_europe_ljubljana: 'Europe/Ljubljana',
  timezone_europe_london: 'Europe/London',
  timezone_europe_luxembourg: 'Europe/Luxembourg',
  timezone_europe_madrid: 'Europe/Madrid',
  timezone_europe_malta: 'Europe/Malta',
  timezone_europe_mariehamn: 'Europe/Mariehamn',
  timezone_europe_minsk: 'Europe/Minsk',
  timezone_europe_monaco: 'Europe/Monaco',
  timezone_europe_moscow: 'Europe/Moscow',
  timezone_europe_oslo: 'Europe/Oslo',
  timezone_europe_paris: 'Europe/Paris',
  timezone_europe_podgorica: 'Europe/Podgorica',
  timezone_europe_prague: 'Europe/Prague',
  timezone_europe_riga: 'Europe/Riga',
  timezone_europe_rome: 'Europe/Rome',
  timezone_europe_samara: 'Europe/Samara',
  timezone_europe_san_marino: 'Europe/San Marino',
  timezone_europe_sarajevo: 'Europe/Sarajevo',
  timezone_europe_saratov: 'Europe/Saratov',
  timezone_europe_simferopol: 'Europe/Simferopol',
  timezone_europe_skopje: 'Europe/Skopje',
  timezone_europe_sofia: 'Europe/Sofia',
  timezone_europe_stockholm: 'Europe/Stockholm',
  timezone_europe_tallinn: 'Europe/Tallinn',
  timezone_europe_tirane: 'Europe/Tirane',
  timezone_europe_ulyanovsk: 'Europe/Ulyanovsk',
  timezone_europe_uzhgorod: 'Europe/Uzhgorod',
  timezone_europe_vaduz: 'Europe/Vaduz',
  timezone_europe_vatican: 'Europe/Vatican',
  timezone_europe_vienna: 'Europe/Vienna',
  timezone_europe_vilnius: 'Europe/Vilnius',
  timezone_europe_volgograd: 'Europe/Volgograd',
  timezone_europe_warsaw: 'Europe/Warsaw',
  timezone_europe_zagreb: 'Europe/Zagreb',
  timezone_europe_zaporozhye: 'Europe/Zaporozhye',
  timezone_europe_zurich: 'Europe/Zurich',
  timezone_indian_antananarivo: 'Indian/Antananarivo',
  timezone_indian_chagos: 'Indian/Chagos',
  timezone_indian_christmas: 'Indian/Christmas',
  timezone_indian_cocos: 'Indian/Cocos',
  timezone_indian_comoro: 'Indian/Comoro',
  timezone_indian_kerguelen: 'Indian/Kerguelen',
  timezone_indian_mahe: 'Indian/Mahe',
  timezone_indian_maldives: 'Indian/Maldives',
  timezone_indian_mauritius: 'Indian/Mauritius',
  timezone_indian_mayotte: 'Indian/Mayotte',
  timezone_indian_reunion: 'Indian/Reunion',
  timezone_pacific_apia: 'Pacific/Apia',
  timezone_pacific_auckland: 'Pacific/Auckland',
  timezone_pacific_bougainville: 'Pacific/Bougainville',
  timezone_pacific_chatham: 'Pacific/Chatham',
  timezone_pacific_chuuk: 'Pacific/Chuuk',
  timezone_pacific_easter: 'Pacific/Easter',
  timezone_pacific_efate: 'Pacific/Efate',
  timezone_pacific_fakaofo: 'Pacific/Fakaofo',
  timezone_pacific_fiji: 'Pacific/Fiji',
  timezone_pacific_funafuti: 'Pacific/Funafuti',
  timezone_pacific_galapagos: 'Pacific/Galapagos',
  timezone_pacific_gambier: 'Pacific/Gambier',
  timezone_pacific_guadalcanal: 'Pacific/Guadalcanal',
  timezone_pacific_guam: 'Pacific/Guam',
  timezone_pacific_honolulu: 'Pacific/Honolulu',
  timezone_pacific_kanton: 'Pacific/Kanton',
  timezone_pacific_kiritimati: 'Pacific/Kiritimati',
  timezone_pacific_kosrae: 'Pacific/Kosrae',
  timezone_pacific_kwajalein: 'Pacific/Kwajalein',
  timezone_pacific_majuro: 'Pacific/Majuro',
  timezone_pacific_marquesas: 'Pacific/Marquesas',
  timezone_pacific_midway: 'Pacific/Midway',
  timezone_pacific_nauru: 'Pacific/Nauru',
  timezone_pacific_niue: 'Pacific/Niue',
  timezone_pacific_norfolk: 'Pacific/Norfolk',
  timezone_pacific_noumea: 'Pacific/Noumea',
  timezone_pacific_pago_pago: 'Pacific/Pago Pago',
  timezone_pacific_palau: 'Pacific/Palau',
  timezone_pacific_pitcairn: 'Pacific/Pitcairn',
  timezone_pacific_pohnpei: 'Pacific/Pohnpei',
  timezone_pacific_port_moresby: 'Pacific/Port Moresby',
  timezone_pacific_rarotonga: 'Pacific/Rarotonga',
  timezone_pacific_saipan: 'Pacific/Saipan',
  timezone_pacific_tahiti: 'Pacific/Tahiti',
  timezone_pacific_tarawa: 'Pacific/Tarawa',
  timezone_pacific_tongatapu: 'Pacific/Tongatapu',
  timezone_pacific_wake: 'Pacific/Wake',
  timezone_pacific_wallis: 'Pacific/Wallis',
  timezone_utc: 'UTC',
  timezones: 'Time zone',
  tips: 'Tips',
  title_change_only: 'Title change only',
  title_not_translated: 'The title has not been translated.',
  titles: 'Title | Titles',
  to_complete_by: 'To complete by',
  to_do_description: 'The employee has not been sent a signoff request for the latest version of their job.',
  to_do: 'To do',
  to: 'to',
  today: 'Today',
  todo: 'To do',
  too_many_requirements: "Too many {library_type} @.lower:{'plural_requirement'}",
  too_many: 'Too many {library_type}',
  top_competencies_tooltip: 'The most frequently used competencies on published jobs.',
  top_competencies: 'Top competencies',
  top_skills_tooltip: 'The most frequently used skills on published jobs.',
  top_skills: 'Top skills',
  topic_question_updated: 'Topic question updated',
  topic_questions_deleted: 'Topic question deleted | Topic questions deleted',
  topic_questions: 'Topic question | Topic questions',
  topics_tooltip_info: 'Click to see additional topics',
  topics: 'Topic | Topics',
  track_changes_description: 'Keep track of changes made to this job. Show / hide changes only affects your view. All changes are automatically accepted when the job is published.',
  track_changes: 'Track changes',
  track_completion_of_this_job: 'Track completion',
  track_progress_description: 'Track completion of draft jobs.',
  track_progress: 'Track progress',
  tracking: 'Tracking',
  translate_in_library: 'Translation can be done in the library.',
  translated_name_required: 'Please add text in all available languages.',
  translation_highlighter: 'Translation highlighter',
  translation_requires_name: 'To save this document, the title needs to be entered in all available languages. Click Actions and select Rename to add any missing translations.',
  trust_this_device: 'Trust this device for 30 days',
  trusted_devices_description: "These are devices where you won't be asked for a 2FA code to log in for 30 days. You can review the list and revoke access to devices you don't recognize.",
  trusted_devices: 'Trusted device | Trusted devices',
  try_again: 'Try again',
  turn_into_subheading: 'Turn into subheading',
  turn_off_consistency: 'Turn off consistency?',
  turn_off_delete_data: 'Turn off and delete data',
  turn_off_expiry_date_message: "Turning off the expiry date will delete any existing expiry dates for signoffs that haven't yet expired. You cannot undo this action.",
  turn_off_expiry_date_title: 'Turn off expiry date?',
  turn_off_feedback_message: 'Feedback will be turned off for jobs using this template.',
  turn_off_feedback: 'Turn off feedback?',
  turn_off_hide_data: 'Turn off and hide data',
  turn_off_job_property_sublabel: 'This property will no longer be available. Existing content and data will not be affected.',
  turn_off_job_property: 'Turn off this property',
  turn_off_library_and_delete_data_sublabel: 'This library will no longer be available. Data will be deleted from affected draft and published jobs. If installed, development data may also be affected. Learn more.',
  turn_off_library_and_delete_data: 'Turn off library and delete data',
  turn_off_library_sublabel: 'This library will no longer be available. Existing content and data wil not be affected.',
  turn_off_library: 'Turn off library',
  turn_off_name: 'Turn off {name}?',
  turn_off_properties_confirmation_message: "This property will be turned off. There aren't any published jobs that include this property.",
  turn_off_properties_with_jobs_confirmation_message: 'This property will be turned off. Property data will be permanently deleted from {count_published_jobs}.',
  turn_off_scale_2: 'Turn off scale and delete data',
  turn_off_scale_sublabel_2: 'Data will be deleted from draft and published jobs. If installed, development data my also be affected. Learn more.',
  turn_off_scale_sublabel: 'This scale will no longer be available. Existing content and data will not be affected.',
  turn_off_scale: 'Turn off this scale',
  turn_off_scales_confirmation_message: "This scale will be turned off. There aren't any published jobs that include this scale.",
  turn_off_scales_with_jobs_confirmation_message: 'This scale will be turned off. Scale data will be permanently deleted from {count_published_jobs}.',
  turn_off_sections_confirmation_message: "This section will be turned off. There aren't any published jobs with content in this section.",
  turn_off_sections_with_jobs_confirmation_message: 'This section will be turned off. Data will be permanently deleted from {count_published_jobs}.',
  turn_off_signoffs_message: 'Turning off signoffs will cancel any requested signoffs and delete any existing expiry dates. You cannot undo this action.',
  turn_off_signoffs_title: 'Turn off signoffs?',
  turn_off_sso_setting_description: 'Prevent users from accessing Quinto using SSO.',
  turn_off_sso_setting: 'Turn off single sign-on',
  turn_off_subheadings_confirmation_message: "This subheading will be turned off. There aren't any published jobs that include this subheading.",
  turn_off_subheadings_with_jobs_confirmation_message: 'This subheading will be turned off and permanently deleted from {count_published_jobs}.',
  turn_off_template_message: 'The template will be unavailable for future use, but will remain associated to existing job descriptions.',
  turn_off_template: 'Turn off template?',
  turn_off_two_fa_setting_description: 'Turning off two-factor authenication significantly reduces account security.',
  turn_off_two_fa_setting_profile_description: 'Turning off two-factor authenication significantly reduces the security of your account.',
  turn_off_two_fa_setting: 'Turn off two-factor authentication?',
  turn_off: 'Turn off',
  turn_on_disclaimer: 'Turn on Disclaimer?',
  turn_on_legal_section_message: '{section} will be added to all PDF / Word exports for jobs using this template.',
  turn_on_template_section_message: '{section} will be turned on for jobs using this template.',
  turn_on_template_section: 'Turn on {section}?',
  turn_on_tracking: 'Turn on tracking',
  turn_on: 'Turn on',
  two_fa_setting_turn_on: "You've turned on two-factor authentication. The next time you log in, we'll send an authentication code to {email}.",
  two_factor_authentication_description: 'Keep your account extra secure with a second login step.',
  two_factor_authentication_mandatory: 'The site administrator has made 2FA mandatory for all users.',
  two_factor_authentication_title: 'Two-factor authentication',
  two_factor_authentication_verification: 'Require users to enter a verification code when logging in.',
  two_factor_authentication: 'Two-factor authentication',
  type_or_paste: 'Type or paste content here',
  type_to_find: 'Type to find',
  type_x: 'Type: {type}',
  types_updated: 'Type updated | Types updated',
  types: 'Type | Types',
  unassign_department: 'Unassign department',
  unassign_position_msg: 'The employee will no longer be linked to this position and the position will become vacant.',
  unassign_position_text: 'Useful for employee departures.\n Choose this option to remove the link between the employee and their position.',
  unassign_position: 'Unassign position?',
  unassign_positions: 'Unassign position',
  unassign_questions: 'Unassign question',
  unassign: 'Unassign',
  unauthorized_msg: 'Sorry, you do not have the permissions to access this page.',
  undo: 'Undo Ctrl + Z',
  unit_minutes: 'minutes',
  unit_seconds: 'seconds',
  unsaved_changes_banner: 'To save your last edits, {clickHere}',
  unused_questions: 'Unused questions',
  upcoming_review: 'Upcoming review',
  update_added: 'Update added to site',
  update_additional_information_msg: 'This item will be updated on {count_published_jobs}.',
  update_additional_information: "Edit @.lower:{'additional_information'}",
  update_architecture_display: 'Update architecture display',
  update_architecture_message_with_jobs: 'The architecture will be updated on {count} published jobs.',
  update_architecture_message: 'The architecture will be updated.',
  update_certifications_msg: 'This item will be updated on {count_published_jobs}.',
  update_certifications: "Edit @.lower:{'certifications'}",
  update_competency_published_msg: "Updates will take effect when it's published.",
  update_education_msg: 'This item will be updated on {count_published_jobs}.',
  update_educations: "Edit @.lower:{'educations'}",
  update_experiences_msg: 'This item will be updated on {count_published_jobs}.',
  update_experiences: "Edit @.lower:{'experiences'}",
  update_jobs: 'Update job',
  update_languages_msg: 'This item will be updated on {count_published_jobs}.',
  update_languages: "Edit @.lower:{'languages'}",
  update_position_title_text: "Useful for position updates that do not affect reporting relationships.\n Choose this option to update the employee's current title.",
  update_position_title: 'Update position title',
  update_property_level_message: 'This level will be updated on {count_published_jobs}.',
  update_property_option_message: 'This option will be updated on {count_published_jobs}.',
  update_responsibilities_msg: 'This item will be updated on {count_published_jobs}.',
  update_responsibility_msg: 'This item will be updated on {count_published_jobs}.',
  update_responsibility: "Edit @.lower:{'responsibilities'}",
  update_scale_level_message: 'This level will be updated on {count_published_jobs}.',
  update_schedule: 'Update schedule',
  update_skill_msg: 'This item will be updated on {count_published_jobs}.',
  update_skill: "Edit @.lower:{'skills'}",
  update_status_emails_subtext: 'Comma separated email addresses.',
  update_status_emails: 'Update status emails',
  update_subheadings_message: 'This subheading will be updated on {count_published_jobs}.',
  update_template_section_message: 'This section will be updated on {count_published_jobs}.',
  update_working_conditions_msg: 'This item will be updated on {count_published_jobs}.',
  update_working_conditions: "Edit @.lower:{'working_conditions'}",
  update_your_profile_picture: 'Update your profile picture',
  update: 'Update | Updates',
  updated_by_name: 'Updated by {name}',
  updated_by_system: 'Updated by System',
  updated_description: 'The job has been republished since the last signoff. A new signoff may be required.',
  updated_due_date: 'Updated due date',
  updated_email_notification_settings: 'Updated email notification settings',
  updated_invitation: 'Updated invitation',
  updated_section_status: 'Updated section status',
  updated_status: 'Updated status',
  updated: 'Updated',
  updating_system: 'Updating system',
  updating_your_jobs: 'Updating your jobs...',
  upload_a_file: 'Upload a file',
  upload_a_new_file: 'Upload a new file',
  upload_a_new_photo: 'Upload a new photo',
  upload_a_photo: 'Upload a photo',
  url_invalid: "URL invalid. Need to start with 'http://' or 'https://'",
  url: 'URL',
  usage: 'Usage',
  use_content_finder: 'Use content finder',
  use_due_date_description: 'Use due date to help prioritize job updates.',
  use_due_date: 'Track due date',
  use_time_zone_specified: "We'll use the time zone specified in account settings.",
  used: 'Used',
  useful_links_description: 'Provide useful links for quick access.',
  useful_links_widget: 'Useful links widget',
  useful_links: 'Useful links',
  user_deactivated: 'User deactivated',
  user_defaults_sub: 'Set defaults for all new users added to your account.',
  user_defaults: 'User defaults',
  user_deleted: 'User deleted',
  user_details: 'User details',
  user_exception: 'User exceptions',
  user_groups: 'User groups',
  user_management: 'User management',
  user_permission_sets: 'Users - Permission sets',
  user_positions: 'User position | User positions',
  user: 'User',
  users_activated: 'User activated | Users activated',
  users_can_hide_welcome_message: 'Users can hide the welcome message',
  users_cannot_hide_welcome_message: 'Users cannot hide the welcome message',
  users_deactivated: 'User deactivated | Users deactivated',
  users_deleted: 'User deleted | Users deleted',
  users_will_be_added: '{count} user will be successfully added | {count} users will be successfully added',
  users_will_be_updated: '{count} user will be updated | {count} users will be updated',
  users_will_not_imported: '{count} user will not be added | {count} users will not be added',
  users_will_partially_imported: '{count} user will be partially added | {count} users will be partially added',
  users_with_the_review_administrator_permission: 'Users with the manage reviews permission',
  users_with_the_review_result_visibility_administrator_permission: 'User with the review administrator permission',
  users_without_positions: 'Users without positions',
  users: 'User | Users',
  vacant_positions: 'Vacant positions',
  vacant: 'Vacant',
  verification_code_error_msg: "You've entered an incorrect or expired code. Please try again or try logging in again in a few minutes.",
  verification_code_msg: 'Please enter your six-digit verification code.',
  version_number: 'Version {number}',
  versions: 'Version | Versions',
  view_all_ratings: 'View all ratings',
  view_all: 'View all',
  view_archives: 'View archive',
  view_assessments: 'View assessments',
  view_changes: 'View changes made to this job.',
  view_comment: 'View comment',
  view_comments: 'View comment | View comments',
  view_competency_details: 'View competency details',
  view_competency: "View @.lower:{'competencies'}",
  view_details: 'View details',
  view_draft_version: 'View draft version',
  view_edit_tags: 'View / Edit tags',
  view_error_counts: 'View {count} error | View {count} errors',
  view_feedback: 'View feedback',
  view_in_org_chart: 'View in org chart',
  view_inherited_owners: 'View inherited owners.',
  view_instructions: 'View instructions',
  view_job_description: 'View job description',
  view_job_family_competencies: "View job-family @.lower:{'plural_competencies'}.",
  view_more: 'View more',
  view_org_chart: 'View org chart',
  view_published_version: 'View published version',
  view_questionnaire: 'View questionnaire',
  view_rating_scale_details: 'View rating scale details',
  view_read_only_version: 'View read-only version',
  view_reference_competencies: "View reference @.lower:{'plural_competencies'}",
  view_results: 'View results',
  view_reviews: 'View reviews',
  view_scorecards: 'View scorecard',
  view_settings: 'View settings',
  view_survey_details: 'View survey details',
  view_team: "View {name}'s team",
  view_timeline: 'View timeline',
  view_your_assessment: 'View your assessment',
  view_your_scorecard: 'View your scorecard',
  view: 'View',
  viewed: 'Viewed',
  viewing_error_counts: 'Viewing {count} error | Viewing {count} errors',
  viewing_now: 'Viewing now',
  viewpoint_comparison_agree_percentage: '{person1} and {person2} agree <span class="text-orange-2">{percentage}%</span> of the time.',
  viewpoint_comparison: 'Viewpoint comparison',
  viewpoints: 'Viewpoints',
  visibility_description: 'Allow users to hide the welcome message.',
  visibility_sharing_options: 'Visibility and  sharing options',
  visibility: 'Visibility',
  visible_columns: 'Visible columns',
  waiting_for_job_title: 'Waiting for a job title…',
  warehouse_add_certifications: 'Add certification? | Add certifications?',
  warehouse_add_competencies: 'Add competency? | Add competencies?',
  warehouse_add_education: 'Add education? | Add educations?',
  warehouse_add_educations: 'Add education? | Add educations?',
  warehouse_add_jobs: 'Add job? | Add jobs?',
  warehouse_add_msg_certifications: "This item will be added to your library. Any items that are already in your site won't be added again. | These items will be added to your library. Any items that are already in your site won't be added again.",
  warehouse_add_msg_competencies: "This item will be added to your library. Existing items on your site will only be added again if they're tagged as Updated in the warehouse. | These items will be added to your library. Existing items on your site will only be added again if they're tagged as Updated in the warehouse.",
  warehouse_add_msg_education: "This item will be added to your library. Any items that are already in your site won't be added again. | These items will be added to your library. Any items that are already in your site won't be added again.",
  warehouse_add_msg_educations: "This item will be added to your library. Any items that are already in your site won't be added again. | These items will be added to your library. Any items that are already in your site won't be added again.",
  warehouse_add_msg_jobs: 'This job will be added to your site. | These jobs will be added to your site.',
  warehouse_add_msg_questions: "This question will be added to your library. Any questions that are already in your site won't be added again. | These questions will be added to your library. Any questions that are already in your site won't be added again.",
  warehouse_add_msg_responsibilities: "This item will be added to your library. Any items that are already in your site won't be added again. | These items will be added to your library. Any items that are already in your site won't be added again.",
  warehouse_add_msg_skill: "This item will be added to your library. Any items that are already in your site won't be added again. | These items will be added to your library. Any items that are already in your site won't be added again.",
  warehouse_add_questions: 'Add question? | Add questions?',
  warehouse_add_responsibilities: 'Add responsibility? | Add responsibilities',
  warehouse_add_skill: 'Add skill? | Add skills?',
  warehouse_adding_items: 'Adding your {resource}...',
  warehouse_additional_information: 'Additional information | Additional information',
  warehouse_certifications: 'Certification | Certifications',
  warehouse_competencies: 'Competency | Competencies',
  warehouse_educations: 'Education | Education',
  warehouse_experiences: 'Experience | Experience',
  warehouse_interview_questions: 'Warehouse: Interview questions',
  warehouse_job_code: 'Job code',
  warehouse_job_group: 'Group',
  warehouse_job_level: 'Job levels',
  warehouse_job_type: 'Job type',
  warehouse_jobs: 'Warehouse: Job | Warehouse: Jobs',
  warehouse_languages: 'Language | Languages',
  warehouse_last_published: 'Published',
  warehouse_libraries_certifications: 'Warehouse: Libraries - Certifications',
  warehouse_libraries_competencies: 'Warehouse: Libraries - Competencies',
  warehouse_libraries_educations: 'Warehouse: Libraries - Education',
  warehouse_libraries_responsibilities: 'Warehouse: Libraries - Responsibilities',
  warehouse_libraries_skills: 'Warehouse: Libraries - Skills',
  warehouse_locations: 'Locations',
  warehouse_reimport_competency_duplicate: 'Create a new competency',
  warehouse_reimport_competency_overwrite: 'Overwrite the existing version',
  warehouse_reimport_competency: 'This competency has already been added to your site. What would you like to do?',
  warehouse_reports_to: 'Reports to',
  warehouse_responsibilities: 'Responsibility | Responsibilities',
  warehouse_salary: 'Salary',
  warehouse_settings_msg: "Define what to include when importing @.lower:{'plural_competencies'} from the warehouse.",
  warehouse_settings: 'Warehouse settings',
  warehouse_skills: 'Skill | Skills',
  warehouse_working_conditions: 'Working condition | Working conditions',
  warehouse: 'Warehouse',
  we_have_check_x_record_for_updates: "We've checked {subjectFirstName}'s user record for updates.",
  website: 'Website',
  welcome_message_widgets: 'Welcome widget',
  welcome_messages_description: 'Define a welcome message for software users.',
  welcome_messages: 'Welcome message',
  welcome_page: 'Welcome page',
  what_to_look_for: 'What to look for',
  whats_new: "What's new",
  why: 'Why?',
  word: 'Word',
  workflows: 'Workflows',
  working_conditions_archive: "Archive: @:{'plural_working_conditions'}",
  working_conditions_archived: 'Item archived | Items archived',
  working_conditions_created: 'Item created | Items created',
  working_conditions_deleted: 'Item deleted | Items deleted',
  working_conditions_published: 'Item published | Items published',
  working_conditions_restored: 'Item restored | Items restored',
  working_conditions_updated: "@.capitalize:{'singular_working_conditions'} updated | @.capitalize:{'plural_working_conditions'} updated",
  working_conditions: "@:{'singular_working_conditions'} | @:{'plural_working_conditions'}",
  x_interview: '{name} interview',
  x_possessive: "{name}'s",
  x_ratings: "{name}'s ratings",
  x_reviews: "{name}'s reviews",
  xlsx_or_csv_up_to_5_mb: 'XLSX or CSV up to 5 MB',
  yes_import_file: 'Yes, import my file',
  yes: 'Yes',
  yesterday: 'Yesterday',
  you_are_all_caught_up: "You're all caught up!",
  you_are_all_upto_date: "You're all up-to-date",
  you_are_logged_in_as: 'You are logged in as {name}.',
  you_have_been_logged_out_due_to_inactivity: 'You have been logged out due to inactivity.',
  you_have_no_job_description_yet: "You don't have a job description yet.",
  you_have_tasks_to_do: 'You have {count} task to do | You have {count} tasks to do',
  you_will_automatically_be_logged_out_30_min: 'For your security, you are about to be logged out due to inactivity.',
  your_file_data: 'Your file data',
  your_profile: 'Your profile',
  your_self_reflection: 'Your self reflection'
};
