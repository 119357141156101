import i18n from '@/i18n';
import store from '@/store';
// Constants
import permissions from '@/constants/permissions';

// Composables
import useOptions from '@/composables/useOptions';
import usePermissions from '@/composables/usePermissions';
const { allOptions } = useOptions();
const { modules, hasModules, features, hasFeatures } = usePermissions();

const t = i18n.global.t;

export default {
  CUSTOM: {
    key: 'Custom',
    component: 'FilterCustom',
    localeKey: ['custom']
  },
  COMPETENCIES_BY_GROUPS: {
    key: 'Competency',
    component: 'FilterCompetenciesByGroups',
    localeKey: ['competencies', 2],
    field: 'competency_id'
  },
  WAREHOUSE_COMPETENCIES_BY_GROUPS: {
    key: 'WarehouseCompetency',
    component: 'FilterCompetenciesByGroups',
    localeKey: ['competencies', 2],
    field: 'competency_id'
  },
  // LICENSES: {
  //   key: 'Licenses',
  //   component: 'FilterCheckboxes',
  //   localeKey: ['licenses', 2],
  //   field: 'license_types',
  //   getOptions: () => [
  //     allOptions.value.NONE,
  //     allOptions.value.REGULAR,
  //     allOptions.value.ADMINISTRATOR,
  //     ...(store?.getters['client/available_modules']?.includes('GROW') ? [allOptions.value.GROW_ACCESS] : [])
  //   ]
  // },
  NAME: {
    key: 'Name',
    component: 'FilterText',
    localeKey: ['name']
  },
  TEXT_NAME: {
    key: 'Name',
    component: 'FilterText',
    localeKey: ['name'],
    fields: ['texts.name']
  },
  SPLIT_TEXT_NAME: {
    key: 'Name',
    component: 'FilterText',
    localeKey: ['name'],
    fields: ['texts.name'],
    ignoreSplit: true
  },
  DATE: {
    key: 'Date',
    component: 'FilterDate',
    localeKey: ['date']
  },
  DATE_RANGE: {
    key: 'DateRange',
    component: 'FilterDateRange',
    localeKey: ['date']
  },
  FORWARD_DATE: {
    key: 'ForwardDate',
    component: 'FilterForwardDate',
    localeKey: ['date']
  },
  ADDED: {
    key: 'CreatedDate',
    component: 'FilterDate',
    localeKey: ['added'],
    field: 'created_at'
  },
  UPDATED: {
    key: 'UpdatedDate',
    component: 'FilterDate',
    localeKey: ['updated'],
    field: 'updated_at'
  },
  DEPARTMENTS: {
    key: 'Departments',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['departments', 2],
    field: 'department_id',
    resourceOption: 'Department'
  },
  RESPONSBILITY_GROUPS: {
    key: 'ResponsibilityGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['responsibility_groups', 2],
    resourceOption: 'ResponsibilityGroup',
    field: 'responsibility_group_id'
  },
  COMPETENCY_GROUPS: {
    key: 'CompetencyGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['competency_groups', 2],
    resourceOption: 'CompetencyGroup',
    field: 'competency_group_id'
  },
  SKILL_GROUPS: {
    key: 'SkillGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['skill_groups', 2],
    resourceOption: 'SkillGroup',
    field: 'skill_group_id'
  },
  EDUCATION_GROUPS: {
    key: 'EducationGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['education_groups', 2],
    resourceOption: 'EducationGroup',
    field: 'education_group_id'
  },
  ADDITIONAL_INFORMATION_GROUPS: {
    key: 'AdditionalInformationGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['additional_information_groups', 2],
    resourceOption: 'AdditionalInformationGroup',
    field: 'additional_information_group_id'
  },
  CERTIFICATION_GROUPS: {
    key: 'CertificationGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['certification_groups', 2],
    resourceOption: 'CertificationGroup',
    field: 'certification_group_id'
  },
  STATUSES: {
    key: 'Statuses',
    component: 'FilterCheckboxes',
    localeKey: ['statuses', 2],
    field: 'status'
  },
  LIBRARY_STATUS: {
    key: 'LibraryStatuses',
    component: 'FilterCheckboxes',
    localeKey: ['statuses', 2],
    field: 'status',
    getOptions: () => [
      allOptions.value.DRAFT,
      allOptions.value.PUBLISHED
    ].map(x => ({ ...x, status: x?.value })),
    permissions: [permissions.MANAGE_LIBRARIES]
  },
  CONTENT_STATUS: {
    key: 'ContentStatus',
    component: 'FilterCheckboxes',
    localeKey: ['statuses', 2],
    field: 'status',
    getOptions: () => [
      allOptions.value.DRAFT,
      allOptions.value.PUBLISHED
    ].map(x => ({ ...x, status: x?.value }))
  },
  CHECKBOX_STATUSES: {
    key: 'Statuses',
    component: 'FilterCheckboxStatuses',
    localeKey: ['statuses', 2],
    field: 'status'
  },
  PERMISSION_SETS: {
    key: 'PermissionSets',
    component: 'FilterCheckboxes',
    localeKey: ['permission_sets', 2],
    field: 'permission_set_ids',
    resource: 'PermissionSet'
  },
  LOCATIONS: {
    key: 'Locations',
    component: 'FilterCheckboxes',
    localeKey: ['locations', 2],
    field: 'location_id',
    resource: 'Location'
  },
  GUIDE_GROUPS: {
    key: 'GuideGroups',
    component: 'FilterCheckboxes',
    localeKey: ['groups', 2],
    resource: 'InterviewGuideGroup',
    field: 'interview_guide_group_id'
  },
  USER_EXCEPTION: {
    key: 'UserException',
    component: 'FilterCheckboxes',
    localeKey: ['exceptions', 2],
    field: 'exceptions',
    getOptions: () => [
      {
        text: t('users_without_positions'),
        value: 'users_without_positions'
      }
    ]
  },
  POSITION_EXCEPTION: {
    key: 'PositionException',
    component: 'FilterCheckboxes',
    localeKey: ['exceptions', 2],
    field: 'exceptions',
    getOptions: () => [
      {
        text: t('vacant_positions', 2),
        value: 'vacant_positions'
      },
      {
        text: t('position_without_jobs', 2),
        value: 'positions_without_job'
      }
    ]
  },
  TAGS: {
    key: 'Tags',
    component: 'FilterTags',
    localeKey: ['tags']
  },
  JOB_GROUPS: {
    key: 'JobGroups',
    component: 'FilterSearchDynamicOptions',
    localeKey: ['job_groups', 2],
    resourceOption: 'JobGroup',
    field: 'job_group_id'
  },
  WAREHOUSE_STATUS: {
    key: 'WarehouseStatus',
    component: 'FilterCheckboxes',
    localeKey: ['statuses', 2],
    field: 'special_status',
    getOptions: () => [
      {
        text: t('added_to_site'),
        value: 'Added',
        icon: 'done',
        color: 'text-green-2',
        size: 'text-[18px]',
        weight: 'font-black'
      },
      {
        text: t('not_added'),
        value: 'Not Added',
        icon: 'horizontal_rule',
        size: 'text-[14px]',
        weight: 'font-black',
        class: 'mt-0.5 ml-1'
      }
    ]
  },
  WAREHOUSE_FLAT_ITEMS_RECENT_UPDATES: {
    key: 'WarehouseRecentUpdates',
    component: 'FilterCheckboxes',
    localeKey: ['recent_updates', 2],
    field: 'recent_updates',
    getOptions: () => [
      {
        text: t('new'),
        value: 'New',
        status: 'New'
      }
      // {
      //   text: t('retired'),
      //   value: 'Retired',
      //   status: 'Retired'
      // }
    ]
  },
  WAREHOUSE_DOCUMENTS_RECENT_UPDATES: {
    key: 'WarehouseRecentUpdates',
    component: 'FilterCheckboxes',
    localeKey: ['recent_updates', 2],
    field: 'recent_updates',
    getOptions: () => [
      {
        text: t('new'),
        value: 'New',
        status: 'New'
      },
      {
        text: t('updated'),
        value: 'Updated',
        status: 'Updated'
      }
      // {
      //   text: t('retired'),
      //   value: 'Retired',
      //   status: 'Retired'
      // }
    ]
  },
  WAREHOUSE_COMPETENCY_GROUPS: {
    key: 'WarehouseCompetencyGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['competency_groups', 2],
    type: 'COMPETENCIES'
  },
  WAREHOUSE_RESPONSBILITY_GROUPS: {
    key: 'WarehouseResponsibilityGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['responsibility_groups', 2],
    type: 'RESPONSIBILITIES'
  },
  WAREHOUSE_SKILL_GROUPS: {
    key: 'WarehouseSkillGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['skill_groups', 2],
    type: 'SKILLS'
  },
  WAREHOUSE_CERTIFICATION_GROUPS: {
    key: 'WarehouseCertificationGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['certification_groups', 2],
    type: 'CERTIFICATIONS'
  },
  WAREHOUSE_EDUCATION_GROUPS: {
    key: 'WarehouseEducationGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['education_groups', 2],
    type: 'EDUCATIONS'
  },
  WAREHOUSE_JOB_GROUPS: {
    key: 'WarehouseJobGroups',
    component: 'FilterWarehouseGroups',
    localeKey: ['job_groups', 2],
    type: 'JOBS'
  },
  // Interview Filters
  TOPICS: {
    key: 'Topics',
    component: 'FilterSelect',
    localeKey: ['topics', 2],
    resourceOption: 'InterviewQuestionTopic',
    multiple: true,
    field: 'interview_question_topic_id'
  },
  TASK_STATUS: {
    key: 'TaskStatuses',
    component: 'FilterCheckboxes',
    localeKey: ['statuses', 2],
    field: 'status',
    getOptions: () => [
      {
        text: t('todo', 2),
        value: 'Open'
      }, {
        text: t('done', 2),
        value: 'Completed'
      }, {
        text: t('cancelled', 2),
        value: 'Cancelled'
      }
    ]
  },
  TASK_TYPES: {
    key: 'TaskTypes',
    component: 'FilterCheckboxes',
    localeKey: ['types', 2],
    field: 'type',
    display: () => {
      const hasMultipleModuleTaskTypes = store?.getters['client/available_modules'].filter(x => [modules.INTERVIEWS, modules.JOBS, modules.SURVEYS, modules.REVIEWS].includes(x)).length > 1;
      const hasMultipleJobTaskTypes = store?.getters['client/available_modules'].includes(modules.JOBS) && hasFeatures(features.COMPLIANCE_SIGNOFFS);

      return hasMultipleModuleTaskTypes || hasMultipleJobTaskTypes;
    },
    getOptions: () => [
      {
        text: t('interviews', 2),
        value: 'INTERVIEW,OPENING',
        visible: hasModules(modules.INTERVIEWS)
      }, {
        text: t('job_descriptions', 2),
        value: 'JOB_INPUT',
        visible: hasModules(modules.JOBS)
      }, {
        text: t('job_signoffs', 2),
        value: 'JOB_SIGNOFF',
        visible: hasModules(modules.JOBS) && hasFeatures(features.COMPLIANCE_SIGNOFFS)
      }, {
        text: t('surveys', 2),
        value: 'TAKE_SURVEY',
        visible: hasModules(modules.SURVEYS)
      }, {
        text: t('reviews', 2),
        value: 'CONDUCT_ASSESSMENT_SUBJECT,CONDUCT_ASSESSMENT_MANAGER,ASSESSMENT_ACKNOWLEDGEMENT,SCHEDULE_ASSESSMENT_DISCUSSION',
        visible: hasModules(modules.REVIEWS)
      }
    ].filter(x => x.visible)?.sort((a, b) => a.text.localeCompare(b.text))
  },
  ASSESSMENTS_STATUSES: {
    key: 'AssessmentsStatuses',
    component: 'FilterMultiselectDropdown',
    localeKey: ['statuses', 2],
    field: 'status',
    getOptions: () => [
      {
        text: t('pending'),
        value: 'Pending'
      },
      {
        text: t('scheduled'),
        value: 'Scheduled'
      },
      {
        text: t('launched'),
        value: 'Launched'
      },
      {
        text: t('in_progress'),
        value: 'In Progress'
      },
      {
        text: t('ready_to_share'),
        value: 'Ready To Share'
      },
      {
        text: t('ready_to_acknowledge'),
        value: 'Ready To Acknowledge'
      },
      {
        text: t('complete'),
        value: 'Completed'
      },
      {
        text: t('incomplete'),
        value: 'Incomplete'
      },
      {
        text: t('cancelled'),
        value: 'Cancelled'
      }
    ]
  },
  ASSESSMENTS_DEPARTMENTS: {
    key: 'AssessmentsDepartments',
    component: 'FilterMultiselectDropdown',
    localeKey: ['departments', 2],
    field: 'department_id',
    resourceOption: 'Department'
  },
  ASSESSMENTS_USERS: {
    key: 'AssessmentUsers',
    component: 'FilterText',
    localeKey: '',
    fields: ['subject_name'],
    ignoreSplit: true
  },
  ASSESSMENTS_CONTENT: {
    key: 'AssessmentContent',
    component: 'FilterSelect',
    localeKey: ['content'],
    field: 'content'
  },
  PARTICIPANTS_DEPARTMENTS: {
    key: 'ParticipantsDepartments',
    component: 'FilterMultiselectDropdown',
    localeKey: ['departments', 2],
    field: 'department_id',
    resourceOption: 'Department'
  },
  PARTICIPANTS_ROLES: {
    key: 'ParticipantsRoles',
    component: 'FilterMultiselectDropdown',
    localeKey: ['roles', 2],
    field: 'participant_type',
    getOptions: () => [
      {
        text: t('managers'),
        value: 'Manager'
      },
      {
        text: t('subjects'),
        value: 'Subject'
      }
      // {
      //   text: t('peer'),
      //   value: 'Peer'
      // },
      // {
      //   text: t('direct_reports'),
      //   value: 'Direct Reports'
      // },
      // {
      //   text: t('other'),
      //   value: 'Other'
      // }
    ]
  },
  PARTICIPANTS_STATUSES: {
    key: 'ParticipantsStatuses',
    component: 'FilterMultiselectDropdown',
    localeKey: ['statuses', 2],
    field: 'status',
    getOptions: () => [
      {
        text: t('pending'),
        value: 'Pending'
      },
      {
        text: t('scheduled'),
        value: 'Scheduled'
      },
      {
        text: t('launched'),
        value: 'Dispatched'
      },
      {
        text: t('in_progress'),
        value: 'In Progress'
      },
      {
        text: t('submitted'),
        value: 'Submitted'
      },
      {
        text: t('ready_to_share'),
        value: 'Ready To Share'
      },
      {
        text: t('ready_to_acknowledge'),
        value: 'Ready To Acknowledge'
      },
      {
        text: t('complete'),
        value: 'Completed'
      },
      {
        text: t('incomplete'),
        value: 'Incomplete'
      },
      {
        text: t('cancelled'),
        value: 'Cancelled'
      }
    ]
  }
};
